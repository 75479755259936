import React from "react";
import { useTranslation } from "react-i18next";
import ModalClosePaymentRequestReceipt from "../../components/Modal/ModalClosePaymentRequestReceipt";


const CloseRequestReceiptPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <ModalClosePaymentRequestReceipt
      open={true}
      handleClose={() => {}}
      showCloseIcon={false}
    />
  );
};

export default CloseRequestReceiptPage;
