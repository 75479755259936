import { Box } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React from "react";
import { InputAOT } from "./InputAOT";

const DatePickerAOT = ({
  label,
  width,
  val,
  handleChange,
  sxa,
  minDate,
  disabled = false,
  required,
  size,
}) => (
  <Box
    sx={{
      width: width,
      borderRadius: "10px",
      border: "2px solid #3D9AEE",
      overflow: "hidden",
      ...sxa,
    }}
  >
    <DesktopDatePicker
      label={label}
      disabled={disabled}
      inputFormat="DD/MM/YYYY"
      value={val}
      onChange={handleChange}
      minDate={minDate}
      renderInput={(params) => (
        <InputAOT
          size={size}
          {...params}
          variant="filled"
          required={required}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: React.cloneElement(params.InputProps.endAdornment, {
              style: disabled ? { backgroundColor: "#D1D1D1" } : {},
            }),
            ...(disabled && {
              style: {
                backgroundColor: "#D1D1D1",
                color: "rgba(0, 0, 0, 0.66)",
              },
            }),
          }}
          sx={{
            svg: {
              color: disabled ? "rgba(0, 0, 0, 0.66)" : "#245AB1",
            },
            button: {
              borderRadius: "none",
              backgroundColor: "transparent",
              "&:hover": { backgroundColor: "transparent" },
              ...(disabled && { backgroundColor: "#D1D1D1" }),
            },
            input: {
              width: "100%",
              ...(disabled && { backgroundColor: "#D1D1D1" }),
            },
            width: "100%",
          }}
        />
      )}
    />
  </Box>
);

export default DatePickerAOT;
