import { Box } from "@mui/material";
import React from "react";
import { InputAOT } from "./InputAOT";

const InputTextFieldAOT = ({
  label,
  width,
  val,
  handleChange,
  disabled,
  sxa,
  required,
  type,
  sxb,
  name,
  error,
  placeholder,
  size,
  handleBlur,
  fullWidth = false,
  handleFocus,
  maxLength
}) => {
  const boxStyles = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    width: fullWidth ? "100%" : width,
    borderRadius: "10px",
    border: `2px solid ${error ? "red" : "#3D9AEE"}`,
    overflow: "hidden",
    ...sxa,
  };

  return (
    <Box sx={boxStyles}>
      <InputAOT
        sx={{ flex: "1 1 auto" }}
        autoComplete="off"
        size={size}
        variant="filled"
        disabled={disabled}
        required={required}
        name={name}
        placeholder={placeholder}
        value={val}
        label={label}
        type={type}
        fullWidth={fullWidth}
        onChange={handleChange}
        onBlur={handleBlur}
        InputProps={{ disableUnderline: true, ...sxb }}
        onFocus={handleFocus}
        inputProps={{
          maxLength: maxLength,
        }}
      />
    </Box>
  );
};

export default InputTextFieldAOT;
