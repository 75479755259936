import { createSlice } from "@reduxjs/toolkit";

const AIRPORT_AVAILABLE = "1"; //? = '1' active , '0' inactive

const initialState = {
  airportList: [],
  pmlsList: [],
  pmlsId: 1,
  availableAirports: {
    suvarnabhumi: false,
    donmuang: false,
  },
};

export const airportSlice = createSlice({
  name: "airport",
  initialState,
  reducers: {
    setAirportList: (state, action) => {
      state.airportList = action.payload;
    },
    setPmlsList: (state, action) => {
      state.pmlsList = action.payload;
    },
    setPmlsId: (state, action) => {
      state.pmlsId = Number(action.payload);
    },
    setAvailableAirport: (state, action) => {
      const { payload } = action;
      state.availableAirports = {
        suvarnabhumi: payload.suvarnabhumi === AIRPORT_AVAILABLE ? true : false,
        donmuang: payload.donmuang === AIRPORT_AVAILABLE ? true : false,
      };
    },
  },
});
export const {
  setAirportList,
  setPmlsList,
  setPmlsId,
  setAvailableAirport,
} = airportSlice.actions;
export default airportSlice.reducer;
