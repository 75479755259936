import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { themeApp } from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { setAvailableAirport, setPmlsId } from "./features/airportSlice";
import {
  logout,
  memberInfo,
  myprofile,
  setCurrentPmlsMemberData,
} from "./features/authSlice";
import { AIRPORT_ID } from "./constants";
import ChangeVehiclePage from "./pages/Auth/ChangeVehicle";
import AllNews from "./pages/AllNews";
import Checking from "./pages/Auth/Checking";
import ContentDetail from "./pages/ContentDetail";
import PaymentHistoryPage from "./pages/Auth/PaymentHistory";
import ContentCampaign from "./pages/ContentCampaign";
import ContentInformation from "./pages/ContentInformation";
import ContentAOTNews from "./pages/ContentAOTNews";
import Email from "./pages/Email";
import QRTicketFeeParkingPage from "./pages/QRTicketFeeParkingPage";
import HomeAOT from "./pages/HomeAOT";
import FindParking from "./pages/FindParking";
import CalculatorParking from "./pages/CalculatorParking";
import FeeParking from "./pages/FeeParking";
import FindMyCarPage from "./pages/FindMyCarPage";
import ReservedParking from "./pages/ReservedParking";

import LoginUser from "./pages/Auth/LoginUser";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import RegisterUser from "./pages/Auth/RegisterUser";
import RegisterParking from "./pages/Auth/RegisterParking";

import MyProfile from "./components/MyProfile/MyProfile";
import Renew from "./pages/Renew";
import History from "./components/History/History";
import PaymentDetail from "./components/Payment/PaymentDetail";
import PaymentTaxInvoice from "./components/Payment/PaymentTaxInvoice";
import MyProfileChangPassword from "./components/MyProfile/MyProfileChangPassword";

// import PrepaidLayout from "./layouts/PrepaidLayout";
// import LoginParking from "./pages/Auth/LoginParking";
// import ChangePassword from "./pages/Auth/ChangePassword";
// import Payment from "./components/Payment/Payment";
// import DetailCard from "./components/Prepaid/DetailCard";
// import HistoryPrepaid from "./components/Prepaid/HistoryPrepaid";
// import CancelCard from "./components/Prepaid/CancelCard";
// import TopUpCard from "./components/Prepaid/TopUpCard";
// import CardPrepaid from "./components/Prepaid/CardPrepaid";

import Notified from "./components/Notified/Notified";
import RequestReceiptPage from "./pages/RequestReceiptPage";

import Cookies from "js-cookie";
import CookiePolicyPopup from "./components/CookiePolicyPopup";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import SettingNotification from "./pages/Auth/SettingNotification";

import { ToastContainer } from "react-toastify";
import PinMyCarPage from "./pages/PinMyCarPage";
import RenewVisitor from "./components/RenewVisitor/RenewVisitor";
import { fetchMasterData } from "./services/fetchMasterData";
import MyTicketPage from "./pages/Auth/MyTicketPage";
import MyTicketDetail from "./components/MyTicket/MyTicketDetail";
import { useTranslation } from "react-i18next";
import { getLangIdFormPMLSlang, isAllAirportsAvailable } from "./utils";
import ParkingDensityWebView from "./pages/ParkingDensityWebView";

import CloseFeeParking from "./pages/close/CloseFeeParking.jsx";
import CloseQRTicketFeeParkingPage from "./pages/close/CloseQRTicketFeeParkingPage.jsx";
import CloseRequestReceiptPage from "./pages/close/CloseRequestReceiptPage.jsx";

import "dayjs/locale/th";
import "dayjs/locale/en";
import dayjs from "dayjs";
import {
  LocalStorageKey,
  setItemLocalStorage,
} from "./utils/localStorageUtil.js";


const HomeLayout = React.lazy(() => import("./layouts/HomeLayout"));
const LoginLayout = React.lazy(() => import("./layouts/LoginLayout"));
const ProtectedLayout = React.lazy(() => import("./layouts/ProtectedLayout"));
const MemberLayout = React.lazy(() => import("./layouts/MemberLayout"));

//? when payment close
export const DISABLED_PAGE = {
  FEE_PARKING: false, // show image banner
  QR_TICKET_FEE_PARKING: false, // show image banner
  REQUEST_RECEIPT: false, // show modal
  LOGIN: true, //https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/2074/
  REGISTER: true, // https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/2074/
  MEMBER_PROFILE: true, // https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/2074/
  REGISTER_MEMBER_PARKING: true, // https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/2074/
  MEMBER_INBOX: true, // https://dev.azure.com/mmats/AOT-CARPARK/_workitems/edit/2074/
};

function App() {
  const { i18n } = useTranslation();
  const {
    user,
    currentPmls,
    isLogin,
    isMemberParking,
    profile,
    isError,
    message,
  } = useSelector((state) => state.auth);
  const { pmlsId, availableAirports } = useSelector((state) => state.airport);
  const dispatch = useDispatch();
  const location = useLocation();
  const [showCookiePopup, setShowCookiePopup] = useState(false);

  useEffect(() => {
    setItemLocalStorage(LocalStorageKey.PMLSID, pmlsId);
  }, [pmlsId]);

  useEffect(() => {
    const setDayjsLocaleByi18nLanguage = () => {
      const language = i18n.language;

      switch (language) {
        case "en":
          dayjs.locale("en");
          break;
        case "th":
          dayjs.locale("th");
          break;
        default:
          dayjs.locale("en");
          break;
      }
    };

    setDayjsLocaleByi18nLanguage();
  }, [i18n.language]);

  const handleCloseCookiePopup = () => {
    setShowCookiePopup(false);
  };

  useEffect(() => {
    const cookiePolicyHandler = () => {
      const cookieAccepted = Cookies.get("cookieAccepted");
      const isPolicyPath =
        location.pathname === "/pages/policy" ||
        location.pathname === "/home/policy";
      if (!cookieAccepted && !isPolicyPath) {
        setShowCookiePopup(true);
      }
    };
    const redirectAirportHandler = () => {
      if (location.pathname.includes("bnk")) {
        dispatch(setPmlsId(AIRPORT_ID.SUVARNABHUMI));
      } else if (location.pathname.includes("dmk")) {
        dispatch(setPmlsId(AIRPORT_ID.DONMUANG));
      }
    };

    //? if all airport is active can use redirect to airport by pathname feature.
    if (isAllAirportsAvailable(availableAirports)) {
      redirectAirportHandler();
    }
    cookiePolicyHandler();
  }, [location.pathname, dispatch, availableAirports]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const setPmlsIdByQueryParamsHandler = () => {
      const pmlsid = searchParams.get("pmlsid");
      if (pmlsid) {
        dispatch(setPmlsId(pmlsid));
      }
    };
    //? if all airport is active can use params to airport feature.
    if (isAllAirportsAvailable(availableAirports)) {
      setPmlsIdByQueryParamsHandler();
    }
  }, [location, dispatch, availableAirports]);

  useEffect(() => {
    if (currentPmls && pmlsId) {
      dispatch(
        memberInfo({ pmlsid: pmlsId, memberid: currentPmls?.pmlsmemberid })
      );
    }
  }, [currentPmls, pmlsId]);

  useEffect(() => {
    if (profile) {
      if (pmlsId === AIRPORT_ID.SUVARNABHUMI) {
        dispatch(setCurrentPmlsMemberData(profile?.airport_1));
      } else if (pmlsId === AIRPORT_ID.DONMUANG) {
        dispatch(setCurrentPmlsMemberData(profile?.airport_2));
      }
    }
  }, [pmlsId, profile]);

  //fetch all master data
  useEffect(() => {
    const pathnameNotFetchMasterData = ["/parking-density", "/ticket"];
    if (pathnameNotFetchMasterData.includes(location.pathname)) {
      return;
    }
    dispatch(fetchMasterData(getLangIdFormPMLSlang(i18n?.language)));
  }, [i18n?.language, location.pathname]);

  useEffect(() => {
    const availableAirports = {
      suvarnabhumi: process.env.REACT_APP_AIRPORT_SUVARNABHUMI_AVAILABLE,
      donmuang: process.env.REACT_APP_AIRPORT_DONMUANG_AVAILABLE,
    };
    dispatch(setAvailableAirport(availableAirports));
  }, [dispatch]);

  //? dispatch myprofie if auth
  useEffect(() => {
    if (isError) {
      if (message === "Unauthorized") {
        dispatch(logout());
      }
    }
    if (user) {
      dispatch(myprofile({ username: user.username }));
    }
  }, [dispatch, isError, message, user]);

  return (
    <ThemeProvider theme={themeApp}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <Container maxWidth={false} disableGutters>
          <Routes>
            <Route
              path="/"
              element={
                isLogin ? (
                  <Navigate to="/pages" replace={true} />
                ) : (
                  <Navigate to="/home" replace={true} />
                )
              }
            />
            <Route
              path="requestreceipt"
              element={
                DISABLED_PAGE.REQUEST_RECEIPT ? (
                  <CloseRequestReceiptPage />
                ) : (
                  <RequestReceiptPage />
                )
              }
            />

            <Route
              path="ticket"
              element={
                DISABLED_PAGE.QR_TICKET_FEE_PARKING ? (
                  <CloseQRTicketFeeParkingPage />
                ) : (
                  <QRTicketFeeParkingPage />
                )
              }
            />

            <Route
              path="/parking-density"
              element={<ParkingDensityWebView />}
            />
            <Route path="/home" element={<HomeLayout />}>
              <Route index element={<HomeAOT />} />
              <Route path="policy" element={<CookiePolicyPage />} />
              <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="findparking" element={<FindParking />} />
              <Route
                path="feeparking"
                element={
                  DISABLED_PAGE.FEE_PARKING ? (
                    <CloseFeeParking />
                  ) : (
                    <FeeParking />
                  )
                }
              />
              <Route path="calparking" element={<CalculatorParking />} />
              {/* <Route path="findmycar" element={<FindMyCarPage />} /> */}
              <Route path="pinmycar" element={<PinMyCarPage />} />
              <Route path="reservedparking" element={<ReservedParking />} />
              <Route path="renew" element={<Renew />} />
              <Route path="allnews" element={<AllNews />} />
              <Route path="content">
                <Route path="" element={<AllNews />} />
                <Route path=":id" element={<ContentDetail />} />
                <Route path="campaign" element={<ContentCampaign />} />
                <Route path="information" element={<ContentInformation />} />
                <Route path="aot-news" element={<ContentAOTNews />} />
              </Route>
            </Route>

            {!DISABLED_PAGE.LOGIN ||
            !DISABLED_PAGE.REGISTER ||
            !DISABLED_PAGE.REGISTER_MEMBER_PARKING ? (
              <Route path="/auth" element={<LoginLayout />}>
                <Route path="user/login" element={<LoginUser />} />
                <Route path="user/forgot" element={<ForgotPassword />} />
                <Route path="user/resetpassword" element={<ResetPassword />} />
                <Route path="user/register" element={<RegisterUser />} />
                <Route path="parking/register" element={<RegisterParking />} />
                {/* <Route path="parking/login" element={<LoginParking />} /> */}
                {/* ? /auth/user/resetpassword */}
                {/* <Route path="changepassword" element={<ChangePassword />} />  */}
              </Route>
            ) : null}

            <Route path="/pages" element={<ProtectedLayout />}>
              <Route index element={<HomeAOT />} />
              <Route path="policy" element={<CookiePolicyPage />} />
              <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="findparking" element={<FindParking />} />
              <Route
                path="feeparking"
                element={
                  DISABLED_PAGE.FEE_PARKING ? (
                    <CloseFeeParking />
                  ) : (
                    <FeeParking />
                  )
                }
              />
              <Route path="calparking" element={<CalculatorParking />} />
              <Route path="findmycar" element={<FindMyCarPage />} />
              <Route path="pinmycar" element={<PinMyCarPage />} />
              <Route path="reservedparking" element={<ReservedParking />} />
              <Route path="renew" element={<Renew />} />
              <Route path="change-vehicle" element={<ChangeVehiclePage />} />
              <Route path="content">
                <Route path="" element={<AllNews />} />
                <Route path=":id" element={<ContentDetail />} />
                <Route path="campaign" element={<ContentCampaign />} />
                <Route path="information" element={<ContentInformation />} />
                <Route path="aot-news" element={<ContentAOTNews />} />
              </Route>
              {!DISABLED_PAGE.MEMBER_INBOX && (
                <Route path="inbox" element={<Notified />} />
              )}
            </Route>

            {!DISABLED_PAGE.MEMBER_PROFILE && (
              <Route
                path="/members"
                element={
                  user ? (
                    <MemberLayout />
                  ) : (
                    <Navigate to="/auth/login" replace />
                  )
                }
              >
                <Route path="myprofile" element={<MyProfile />} />
                <Route
                  path="myprofile/changpassword/"
                  element={<MyProfileChangPassword />}
                />
                <Route path="renew" element={<Renew />} />
                <Route path="history" element={<History />} />
                <Route path="payment" element={<PaymentHistoryPage />} />
                <Route path="payment/detail/" element={<PaymentDetail />} />
                <Route
                  path="payment/taxinvoice/"
                  element={<PaymentTaxInvoice />}
                />
                <Route path="checking" element={<Checking />} />
                <Route
                  path="setting-notification"
                  element={<SettingNotification />}
                />
                <Route path="myticket">
                  <Route path="" element={<MyTicketPage />} />
                  <Route path=":id" element={<MyTicketDetail />} />
                </Route>
              </Route>
            )}

            {/* hide ก่อน */}
            {/* <Route
              path="/prepaid"
              element={
                <PrepaidLayout
                  user={user}
                  airport={airport}
                  setAirport={setAirport}
                />
              }
            >
              <Route path="cardprepaid" element={<CardPrepaid />} />
              <Route path="cardprepaid/detail" element={<DetailCard />} />
              <Route path="cardprepaid/history" element={<HistoryPrepaid />} />
              <Route path="cardprepaid/cancel" element={<CancelCard />} />
              <Route path="cardprepaid/topupcard" element={<TopUpCard />} />
            </Route> */}

            <Route path="email" element={<Email />} />
            <Route path="/renewbycard" element={<RenewVisitor />} />
            <Route path="/:cardId" element={<RedirectToRenewByCard />} />
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Routes>
          <CookiePolicyPopup
            open={showCookiePopup}
            onClose={handleCloseCookiePopup}
          />
        </Container>
      </LocalizationProvider>
      <ToastContainer />
    </ThemeProvider>
  );
}

const RedirectToRenewByCard = () => {
  const navigate = useNavigate();
  const cardId = window.location.pathname.substring(1);

  useEffect(() => {
    if (cardId.startsWith("12") || cardId.startsWith("14")) {
      navigate(
        `/renewbycard?pmlsid=${
          cardId.startsWith("12") ? "1" : "2"
        }&cardid=${cardId}`
      );
    } else {
      navigate("/");
    }
  }, []);

  return null;
};
export default App;
