import instance from "../config/apiAxios";

// PMCS_TB_0355 : Get entry exit global config from pmls
const getEntryExitGlobalConfigFormPMLS = async (params) => {
  const response = await instance.get(
    "/subsystem/pmls/sys/entryexit/global/conf",
    { params }
  );
  return response.data;
}; 

const ConfigService = {
  getEntryExitGlobalConfigFormPMLS,
};
export default ConfigService;
