import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputTextFieldAOT from "../../components/Controls/InputTextFieldAOT";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { decodePublicKey } from "../../utils/encrypt";
import { useDispatch } from "react-redux";
import { login, reset } from "../../features/authSlice";
import { useTranslation } from "react-i18next";
import LoginService from "../../services/login-service";
import { LANG } from "../../constants";
import { toast } from "react-toastify";
import ButtonAction from "../../components/Base/ButtonAction";
import AuthUserLayout from "../../components/Layouts/AuthUserLayout";
import BoxMessErr from "../../components/Base/BoxMessErr";
import forge from "node-forge";

const LoginText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 24px/54px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  paddingBottom: "1rem",
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 20px/34px Noto Sans Thai",
  },
}));

const ForgotText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 16px/24px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#3D9AEE",
  padding: "1rem 0rem 1rem 0rem",
  alignSelf: "flex-end",
  cursor: "pointer",
}));

const UserText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/40px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  padding: "1rem 0rem 0rem 0rem",
  cursor: "pointer",
  textAlign: "center",
}));

const validationSchema = Yup.object({
  username: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState(false);

  const formik = useFormik({
    initialValues: {
      lang: LANG.TH,
      username: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        const resPub = await LoginService.getPublicKey(values.username);
        if (resPub) {
          const pbkey = decodePublicKey(resPub);
          const publicKey = forge.pki.publicKeyFromPem(pbkey);
          const encryptedBytes = publicKey.encrypt(values.password, "RSA-OAEP");
          const encrypt = forge.util.encode64(encryptedBytes);
          const payloadLogin = {
            username: values.username,
            password: encrypt,
          };
          dispatch(reset());
          dispatch(login(payloadLogin));
          navigate("/pages");
        } else {
          toast.error("Invalid username or password!", {
            position: "top-center",
            autoClose: 1500,
          });
        }
      } catch (err) {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 1500,
        });
      }
    },
    validationSchema,
  });

  const handleTogglePassword = () => setVisibility((visibility) => !visibility);

  return (
    <AuthUserLayout contentCenter={true}>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className="w-full"
      >
        <LoginText>{t("Log in to Parking Membership System")}</LoginText>
        <Stack className="w-full" spacing={3}>
          <div>
            <InputTextFieldAOT
              label={t("Email")}
              name="username"
              width="100%"
              val={formik.values.username}
              handleChange={formik.handleChange}
            />
            <BoxMessErr
              message={
                formik.errors.username && formik.touched
                  ? formik.errors.username
                  : null
              }
            />
          </div>
          <div>
            <InputTextFieldAOT
              label={t("Password")}
              name="password"
              type={visibility ? "text" : "password"}
              width="100%"
              val={formik.values.password}
              handleChange={formik.handleChange}
              sxb={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                    >
                      {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <BoxMessErr
              message={
                formik.errors.password && formik.touched
                  ? formik.errors.password
                  : null
              }
            />
          </div>
        </Stack>
        <ForgotText
          onClick={() => {
            navigate("/auth/user/resetpassword");
          }}
          sx={{
            textAlign: "right",
          }}
        >
          {t("Forget Password")}
        </ForgotText>
        <ButtonAction type="submit" disabled={!formik.isValid}>
          {t("Log in")}
        </ButtonAction>
        <UserText
          onClick={() => {
            navigate({ pathname: "/auth/user/register" });
          }}
        >
          {t("Create an Account")}
        </UserText>
      </form>
    </AuthUserLayout>
  );
};

export default LoginUser;
