const AIRPORT_ID = {
  SUVARNABHUMI: 1,
  DONMUANG: 2,
};

const PMLS_ID_TO_AIRPORT_TEXT = {
  1: {
    th: "ท่าอากาศยานสุวรรณภูมิ",
    en: "Suvarnabhumi Airport",
  },
  2: {
    th: "ท่าอากาศยานดอนเมือง",
    en: "Don Mueang International Airport",
  },
};

const LANG = {
  TH: "th",
  EN: "en",
};

const LANG_ID = {
  TH: 2,
  EN: 1,
};

const RESPONSE_CODE = {
  SUCCESS: "2100000",
};

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const DATE_FORMAT_01 = "DD/MM/YYYY";
const DATE_FORMAT_02 = "DD/MM/YYYY HH:mm:ss";

const PUBLIC_WEB_TOKEN = process.env.REACT_APP_TOKEN_PUBLIC;

const CONTENT_TYPE_CODE = {
  NEWS: "CT001",
  PROMO: "CT002",
  INFO: "CT003",
};

const VISIBILITY_TYPE = {
  PUBLIC: "V001",
  MEMBER: "V002",
};

const MEMBER_STATUS = {
  REGISTER: "Register",
  ACTIVE: "Active",
};

const REGEX = {
  EMAIL: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
  LICENSE_PLATE: /^[a-zA-Zก-ฮ-0-9]+$/,
  PHONE:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  PASSWORD: /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/,
  NAME: /^[A-Za-zก-๙]+$/,
};

const FOOTER_CONTACT_US = {
  FACEBOOK: "https://www.facebook.com/AOTofficial",
  LINE: "https://line.me/R/ti/p/@889xttyg?from=page",
  INSTAGRAM: "https://www.instagram.com/aot_official_th",
  TWITTER: "https://twitter.com/AOTthailand",
};

const FACING_MODE = {
  ENVIRONMENT: "environment",
  USER: "user",
};

const PROCESS_TYPE_CODE = {
  RENEW: "P02",
};

const RENEW_STATE_ID = {
  RENEW: "R01",
  WAIT_PAYMENT: "R02",
  SUCCESS_RENEW: "R03",
  CANCEL_RENEW: "R04",
};

export {
  AIRPORT_ID,
  LANG,
  RESPONSE_CODE,
  PUBLIC_WEB_TOKEN,
  DATE_FORMAT,
  DATE_FORMAT_01,
  DATE_FORMAT_02,
  CONTENT_TYPE_CODE,
  VISIBILITY_TYPE,
  MEMBER_STATUS,
  PMLS_ID_TO_AIRPORT_TEXT,
  LANG_ID,
  REGEX,
  FOOTER_CONTACT_US,
  FACING_MODE,
  PROCESS_TYPE_CODE,
  RENEW_STATE_ID,
};
