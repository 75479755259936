import instance from "../config/apiAxios";

const BASE_PATH = "/subsystem/pmls/reservation";

const ReservationService = {
  checkAvailable: (body) => instance.post(BASE_PATH + "/checkavailable", body),
  booking: (body) => instance.post(BASE_PATH + "/booking", body),
  paymentQRRequest: (body) => instance.post(BASE_PATH + "/qrrequest", body), //PMCS_TB_0219 : Reservation qrrequest from pmls
  paymentQRQuery: (body) => instance.post(BASE_PATH + "/qrquery", body), //PMCS_TB_0219 : Reservation qr query from pmls
  paymentQRConfirm: (body) => instance.post(BASE_PATH + "/qrcomfirm", body), //PMCS_TB_0219 : Reservation qr query from pmls
};

export default ReservationService;
