import html2canvas from "html2canvas";
import { useRef } from "react";

const useScreenShot = () => {
  const frameRef = useRef(null);

  const handleScreenshot = () => {
    const frameNode = frameRef.current;

    if (frameNode) {
      html2canvas(frameNode).then((canvas) => {
        const image = canvas.toDataURL(); // The screenshot is in base64 format

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.download = "screenshot.png"; // Set the filename for the downloaded image
        link.href = image;
        link.click();

        // Alternatively, you can use the following code to open the image in a new tab instead of downloading it
        // window.open(image);
      });
    }
  };
  return {
    frameRef,
    handleScreenshot,
  };
};

export default useScreenShot;
