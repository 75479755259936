import { useTranslation } from "react-i18next";
import { formattedPriceWithTextCurrency } from "../../utils/format";
import TextWithIcon from "../Base/TextWithIcon";
import { TitleWithValue } from "../Base/TitleWithValue";
import BoxSemiCircle from "../BoxSemiCircle";

const PaymentDetailBoxDefault = ({
  titleText,
  titleIcon,
  airportText,
  showAirport,
  methodText,
  footerExtra,
  details,
  amount,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full border border-[#B3BBC2] border-t-[15px] border-t-primaryBlue_500 relative">
        <div className="p-10 flex flex-col gap-y-6">
          <TextWithIcon
            gap={20}
            icon={<img src={titleIcon} className="w-10" alt="icon-title"/>}
            text={
              <p className="text-lg font-bold text-primaryBlue_600">
                {titleText}
              </p>
            }
          />
          {showAirport && airportText !== "" && (
            <TextWithIcon
              gap={24}
              icon={
                <img src={"/images/feeparking/ico_plane.svg"} alt="icon-plane" className="w-8" />
              }
              text={<p className="text-base font-medium ">{airportText}</p>}
            />
          )}
          {details?.length > 0 &&
            details?.map((item, index) => {
              return (
                <div key={index}>
                  <TitleWithValue
                    key={index}
                    title={item?.title}
                    value={item?.value}
                  />
                  {item?.isShowAlert && item?.valueAlert
                    ? item.valueAlert
                    : null}
                </div>
              );
            })}
          <hr className="w-full" />
          <TitleWithValue title={t("Payment Method")} value={t(methodText)} />
          <hr className="w-full" />
          <TitleWithValue
            title={t("Amount")}
            value={formattedPriceWithTextCurrency(amount)}
          />
          <div className="mt-12">
            <TitleWithValue
              title={t("Total Amount")}
              value={formattedPriceWithTextCurrency(amount)}
              titleStyles="font-bold text-xl"
              valueStyles="text-red-500 font-bold text-xl"
            />
          </div>
        </div>
        <BoxSemiCircle />
      </div>
      {footerExtra && footerExtra}
    </>
  );
};

export default PaymentDetailBoxDefault;
