import React from "react";
import { useTranslation } from "react-i18next";
import ErrorResultWithText from "../Result/ErrorResultWithText";

const NotFoundTicket = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full">
      <div className="w-full">
        <ErrorResultWithText
          text={
            <p className="text-blue-800 text-xl md:text-2xl font-bold">
              {t(
                "Ticket Not Found"
              )}
            </p>
          }
        />
      </div>
    </div>
  );
};

export default NotFoundTicket;
