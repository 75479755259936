import React from "react";

const PageContent = ({ icon, title, breadcrumb, children }) => {
  return (
    <div className="space-y-4 w-full mx-auto container p-10 lg:p-20">
      <div className="flex justify-between items-start">
        <div>
          <div className="inline-flex items-center mb-4">
            <div className="inline-flex gap-4 items-center">
              {icon && icon}
              {title && (
                <p className="text-xl md:text-2xl text-[#2F2E2F] font-semibold">
                  {title}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="hidden lg:block">{breadcrumb && breadcrumb}</div>
      </div>
      {children}
    </div>
  );
};

export default PageContent;
