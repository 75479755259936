import React from "react";
import { Box, Typography, styled } from "@mui/material";

const BoxOuter = styled(Box)(({ theme }) => ({
  boxShadow: "0px 3px 6px #7c7c7c55",
  border: "1px solid #7c7c7c55",
  borderRadius: "12px",
  width: "100%",
  display: "flex",
  paddingInline: "50px",
  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {
    paddingInline: "40px",
  },
  [theme.breakpoints.down("lg")]: {
    paddingInline: "20px",
  },
}));

const BoxHeader = styled(Box)(({ theme }) => ({
  paddingTop: "1.5rem",
  paddingBottom: "0.5rem",
  // paddingLeft: "1rem",
  display: "flex",
  gap: 18,
  alignItem: "center",
  borderBottom: "1px solid #33333330",
  [theme.breakpoints.down("lg")]: {},
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 26px/60px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 20px/60px Noto Sans Thai",
  },
}));

const BoxContent = ({
  headerTitle,
  headerIcon,
  children,
  boxBorderRadius = 3,
  boxShadow = "0px 3px 6px #7c7c7c55",
}) => {
  return (
    <BoxOuter
      sx={{
        borderRadius: boxBorderRadius,
        boxShadow: boxShadow,
      }}
    >
      {headerTitle && (
        <BoxHeader>
          {headerIcon && headerIcon}
          <HeaderText>{headerTitle}</HeaderText>
        </BoxHeader>
      )}
      <div className="py-8">{children}</div>
    </BoxOuter>
  );
};

export default BoxContent;
