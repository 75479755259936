import instance from "../config/apiAxios";

const ticketSearch = async (body) => {
  const response = await instance.post(
    "/subsystem/pmls/gate/ticket/searchmatch",
    body
  );
  return response.data;
};

const GateService = {
  ticketSearch,
};

export default GateService;
