import { Box, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  formattedPriceOnlyDigit,
  formattedPriceWithTextCurrency,
} from "../../utils/format";

const BoxReceipt = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "70%",
  border: "1px solid #B3BBC2",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
}));

const BoxGrandTotal = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "1rem",
  paddingTop: "1rem",
  borderBottom: "1px solid #B3BBC2",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

const BoxTransfer = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "1rem",
  paddingTop: "1rem",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

const BoxTransferFee = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "1rem",
  borderBottom: "1px solid #B3BBC2",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

const BoxQRTitle = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  backgroundColor: "#113566",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "1rem",
  borderBottom: "1px solid #B3BBC2",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

const QRTitleText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px/60px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  [theme.breakpoints.down("xl")]: {
    font: "normal normal 600 18px/30px Noto Sans Thai",
  },
}));

const AOTText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/40px Noto Sans Thai",
  letterSpacing: "0px",
  [theme.breakpoints.down("xl")]: {
    font: "normal normal normal 18px/40px Noto Sans Thai",
  },
}));

const BoxHint = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "70%",
  flexDirection: "column",
  paddingBottom: "3rem",
  alignItems: "flex-start",
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
}));

const HintTitleText = styled(Typography)(({ theme }) => ({
  font: "normal normal bold 24px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  paddingBottom: "1rem",
  paddingTop: "2rem",
  [theme.breakpoints.down("xl")]: {},
}));

const HintDescText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 16px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  paddingBottom: "0.3rem",
  [theme.breakpoints.down("xl")]: {},
}));

const ReservedParkingWait = ({ paymentData }) => {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(dayjs());

  useEffect(() => {
    const isExpired = currentTime.isAfter(paymentData?.bankexpiredt);

    if (isExpired) {
      alert("ท่านไม่ได้ชำระเงินในเวลาที่กำหนด กรุณาทำรายการใหม่");
      window.location.reload();
    }
  }, [paymentData?.bankexpiredt, currentTime]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <img
          src="/images/feeparking/img_wait_payment.svg"
          alt=""
          className="w-[200px] h-auto"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          flexDirection: "column",
          py: "1rem",
        }}
      >
        <Typography
          sx={{
            font: "normal normal bold 36px/54px Noto Sans Thai",
            letterSpacing: "0px",
            color: "#EF8A49",
          }}
        >
          {t("Waiting for payment")}
        </Typography>
        <Typography>{currentTime.format("DD-MM-YYYY HH:mm:ss")}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <BoxReceipt>
          <BoxGrandTotal>
            <Typography
              sx={{
                font: "normal normal 600 18px/29px Noto Sans Thai",
                letterSpacing: "0px",
                color: "#2F2E2F",
              }}
            >
              {t("Payment Amount")}
            </Typography>
            <Typography
              sx={{
                font: "normal normal 600 18px/29px Noto Sans Thai",
                letterSpacing: "0px",
                color: "#2F2E2F",
              }}
            >
              {formattedPriceWithTextCurrency(paymentData?.amount)}
            </Typography>
          </BoxGrandTotal>
          {/* <BoxTransfer>
            <Typography
              sx={{
                font: "normal normal normal 18px/30px Noto Sans Thai",
                letterSpacing: "0px",
                color: "#2F2E2F",
              }}
            >
              {t("Transfer Amount")}
            </Typography>
            <Typography
              sx={{
                font: "normal normal normal 18px/30px Noto Sans Thai",
                letterSpacing: "0px",
                color: "#2F2E2F",
              }}
            >
              {formattedPriceOnlyDigit(paymentData?.amount, 2)}
            </Typography>
          </BoxTransfer> */}
          {/* <BoxTransferFee>
            <Typography
              sx={{
                font: "normal normal normal 18px/30px Noto Sans Thai",
                letterSpacing: "0px",
                color: "#2F2E2F",
              }}
            >
              {t("Transfer Fee")}
            </Typography>
            <Typography
              sx={{
                font: "normal normal normal 18px/30px Noto Sans Thai",
                letterSpacing: "0px",
                color: "#2F2E2F",
              }}
            >
              0
            </Typography>
          </BoxTransferFee> */}
          {/* <BoxGrandTotal>
            <Typography
              sx={{
                font: "normal normal normal 18px/30px Noto Sans Thai",
                letterSpacing: "0px",
                color: "#2F2E2F",
              }}
            >
              {t("Total")}
            </Typography>
            <Typography
              sx={{
                font: "normal normal normal 18px/30px Noto Sans Thai",
                letterSpacing: "0px",
                color: "#2F2E2F",
              }}
            >
              {formattedPriceWithTextCurrency(paymentData?.amount)}
            </Typography>
          </BoxGrandTotal> */}
          <BoxQRTitle>
            <Box
              component="img"
              src="/images/feeparking/tqp.png"
              alt=""
              sx={{ height: "48px" }}
            />
            <QRTitleText>{t("Scan the QR Code to pay")}</QRTitleText>
          </BoxQRTitle>
          <Box
            id="qrcodediv"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box>
                <Box
                  component="img"
                  src={`data:image/png;base64,${paymentData?.qrbase64}`}
                  alt=""
                  sx={{ width: "250px", height: "250px" }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "310px",
                }}
              >
                <Box
                  sx={{
                    maxWidth: "200px",
                    marginLeft: "8px",
                    fontSize: "14px",
                  }}
                >
                  {paymentData?.merchantname}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  <Box
                    sx={{
                      marginRight: "8px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {Number(paymentData?.amount).toLocaleString()}
                  </Box>
                  <Box sx={{ marginRight: "8px", fontSize: "14px" }}>Baht</Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Typography>{t("Please pay before")}</Typography>
              <Typography sx={{ color: "red", marginLeft: "5px" }}>
                {paymentData?.bankexpiredt}
              </Typography>
            </Box>
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "90%",
              marginTop: "1rem",
              marginBottom: "2rem",
            }}
          >
            <Box
              component="img"
              src="/images/feeparking/ico_aot.svg"
              alt=""
              sx={{ marginRight: "2rem" }}
            />
            <Box>
              <AOTText sx={{ fontWeight: 600 }}>ชื่อบัญชี</AOTText>
              <AOTText>บริษัท ท่าอากาศยานไทย จำกัด(มหาชน)</AOTText>
              <AOTText>ธนาคารกสิกรไทย</AOTText>
              <AOTText>หมายเลขบัญชี 456 0602 768</AOTText>
            </Box>
          </Box> */}
        </BoxReceipt>
        <BoxHint>
          <HintTitleText>{t("the instructions")}</HintTitleText>
          <HintDescText>1. {t("stepone")}</HintDescText>
          <HintDescText>2. {t("steptwo")}</HintDescText>
          <HintDescText>3. {t("stepthree")}</HintDescText>
          <HintDescText>4. {t("stepfour")}</HintDescText>
          <HintDescText>5. {t("stepfive")}</HintDescText>
          <HintDescText>6. {t("stepsix")}</HintDescText>
        </BoxHint>
      </Box>
    </Box>
  );
};

export default ReservedParkingWait;
