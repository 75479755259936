import instance from "../config/apiAxios";

const BASE_URL = "/mapp/payment";

const createParkingFeePaymentQR = async (body) => {
  const response = await instance.post(
    BASE_URL + "/secure/qr/parkingfee/request",
    body
  );
  return response.data;
};

//PMCS_TB_0152 : Mapp pmls create receipt for parking fee of mapp pos
const createReceiptParkingFee = async (body) => {
  const response = await instance.post(
    BASE_URL + "/online/parkingfee/request",
    body
  );
  return response.data;
};

// renew
const createMemberPaymentQR = async (body) => {
  const response = await instance.post(BASE_URL + "/qr/member/request", body);
  return response.data;
};

const queryQRStatus = async (body) => {
  const response = await instance.post(BASE_URL + "/qr/query", body);
  return response.data;
};

//PMCS_TB_0213 : MApp create secure member payment qr code at pmls
const mmapMemberCreateSecurePaymentQrCode = async (body) => {
  const response = await instance.post(
    BASE_URL + "/secure/qr/member/request",
    body
  );
  return response.data;
};

//PMCS_TB_0159 : Mapp pmls create receipt for renew member of mapp pos
const createReceiptForRenewMember = async (body) => {
  const response = await instance.post(
    BASE_URL + "/memberorder/online/renew/request",
    body
  );
  return response.data;
};

//PMCS_TB_0351 : Mapp create receipt for renew member by card id of mapp pos
const createReceiptForRenewMemberByCard = async (body) => {
  const response = await instance.post(
    BASE_URL + "/online/renewbycard/request",
    body
  );
  return response.data;
};

//PMCS_TB_0129 : Mapp pmls get payment receipt
const getPaymentReceipt = async (params) => {
  const response = await instance.get(BASE_URL + "/receipt", { params });
  return response.data;
};

//PMCS_TB_0322 : Create receipt process at pmls
const createReceiptProcessAtPmls = async (body) => {
  const response = await instance.post(
    "/subsystem/pmls/receipt/process/create",
    body
  );
  return response.data;
};

const updateMemberProcess = async (body) => {
  const response = await instance.post(
    "/subsystem/pmls/payment/memberprocess/update",
    body
  );
  return response.data;
};

const parkingFeeCheckPendingPayment = async (body) => {
  const response = await instance.post(
    BASE_URL + "/online/parkingfee/checkpending",
    body
  );
  return response?.data;
};

//จากเดิมที่เรียก /mapp/payment/qr/query ให้ย้ายมาใช้เส้นนี้แทน
const parkingFeeCheckResultPayment = async (body) => {
  const response = await instance.post(
    BASE_URL + "/online/parkingfee/checkpaymentresult",
    body
  );
  return response?.data;
};

const parkingFeeCheckCancelTransaction = async (body) => {
  const response = await instance.post(
    BASE_URL + "/online/parkingfee/canceltransaction",
    body
  );
  return response?.data;
};

const PaymentService = {
  createParkingFeePaymentQR,
  createMemberPaymentQR,
  queryQRStatus,
  mmapMemberCreateSecurePaymentQrCode,
  createReceiptForRenewMember,
  createReceiptParkingFee,
  getPaymentReceipt,
  createReceiptProcessAtPmls,
  createReceiptForRenewMemberByCard,
  updateMemberProcess,
  parkingFeeCheckPendingPayment,
  parkingFeeCheckResultPayment,
  parkingFeeCheckCancelTransaction
};
export default PaymentService;
