import instance from "../config/apiAxios";

const BASE_URL = "/sys/user/inbox";

const InboxService = {
  // PMCS_SYS_0173 : Get total unread inbox message and get last ten inbox message by user id
  getPreviewInbox: async (userid) => {
    const response = await instance.get(BASE_URL + `/preview?userid=${userid}`, null);
    return response.data;
  },
};

export default InboxService;
