import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

const CloseQRTicketFeeParkingPage = () => {
  const { t, i18n } = useTranslation();

  if (true) {
    return (
      <div className="w-full h-screen flex justify-center  items-center justify-items-center px-10">
        <img
          src="/images/carpark-banner-payment-not-ready.jpg"
          className="w-[500px] h-auto border "
        />
      </div>
    );
  }
};

export default CloseQRTicketFeeParkingPage;
