import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

const ModalClosePaymentRequestReceipt = ({
  handleClose,
  open,
  showCloseIcon = true,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 650,
          bgcolor: "white",
          boxShadow: 24,
          p: 1,
        }}
      >
        <div className="w-full relative">
          {showCloseIcon && (
            <div className="absolute -right-4 -top-4">
              <div className="w-6 h-6 rounded-full bg-white hover:bg-primaryBlue_500">
                <button onClick={handleClose}>
                  <CloseIcon className="w-6 h-6 hover:text-white" />
                </button>
              </div>
            </div>
          )}
          <img className="w-full h-11" src="/images/modal/bar.png" alt="bar" />
          <div className="absolute right-0 top-14">
            <img
              src="/images/modal/aotsmartcarpark_2.png"
              alt="aotsmartcarpark_img"
              className="w-[100px] h-auto"
            />
          </div>
          <div className="px-6 py-10 pb-10">
            <div className="space-y-3 font-medium">
              <p>เรียน ผู้ใช้บริการ</p>
              <p>
                ขณะนี้ระบบไม่พร้อมให้บริการ กรุณาติดต่อเคาน์เตอร์การเงิน AOB
                ชั้น 1 ในวัน-เวลาทำการของ ทอท.
              </p>
              <p>ขออภัยในความไม่สะดวก</p>
            </div>
            <hr className="my-5" />
            <div className="space-y-3 font-medium">
              <p>Dear Valued Customers,</p>
              <p>
                We regret to inform you that our system is temporarily
                unavailable. Please contact staff at AOB financial counter, 1st
                Fl.
              </p>
              <p>
                We apologize for any inconvenience this may cause and appreciate
                your understanding.
              </p>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalClosePaymentRequestReceipt;
