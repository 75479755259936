const ContainerCenter = ({ children, className }) => {
  return (
    <div
      className={`w-full px-8 md:px-0 container mx-auto ${
        className ? className : ""
      }`}
    >
      {children}
    </div>
  );
};

export default ContainerCenter;
