import React, { useRef, useState } from "react";
import {
  DatePickerAOT,
  InputTextFieldAOT,
  SelectAOT,
} from "../../components/Controls";
import { AttachFile } from "@mui/icons-material";
import ButtonAction from "../../components/Base/ButtonAction";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  convertToBase64,
  convertToURI,
  getExtension,
} from "../../utils/fileUpload";
import { useCallback } from "react";
import CheckBoxAOT from "../../components/Controls/CheckBoxAOT";
import * as Yup from "yup";
import { useMemo } from "react";
import MemberService from "../../services/member-service";
import PreviewChangeVehicle from "../../components/ChangeVehicle/PreviewChangeVehicle";
import ResponseChangeVehicle from "../../components/ChangeVehicle/ResponseChangeVehicle";
import { useNavigate } from "react-router-dom";
import ChangeVehicleStepper from "../../components/ChangeVehicle/Stepper";
import { DATE_FORMAT } from "../../constants";
import { useTranslation } from "react-i18next";
import ContainerCenter from "../../layouts/ContainerCenter";
import {
  generateCarBrandOptions,
  generateLicenseOptions,
  generateOwnerVehicleOptions,
  generateProvinceLicenseOptions,
  generateVehicleTypeOptions,
} from "../../utils/optionsUtils";
import { toast } from "react-toastify";
import PageContent from "../../layouts/PageContent";
import { Breadcrumbs, Link, Typography } from "@mui/material";

const validationSchema = Yup.object({
  licenseplate: Yup.string().required("New license is required"),
  licenseby: Yup.string().required("Vehicle color is required"),
  licenseprovince: Yup.string().required(),
  brand: Yup.string().required(),
  vehicleowner: Yup.string().required(),
  ispermanent: Yup.number().required(),
});

const memberVehicleTypeOptions = [
  { id: 1, display: "ถาวร" },
  { id: 0, display: "ชั่วคราว" },
];

const ChangeVehiclePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { memberInfo, memberVehicles } = useSelector((state) => state.auth);
  const { pmlsId } = useSelector((state) => state.airport);
  const {
    vehicletype,
    ownerVehicle,
    provincelicense,
    license,
    carBrand,
    doctypeGroup,
    loading: loadingMs,
  } = useSelector((state) => state.masterData);

  const [selectedDocType, setSelectedDocType] = useState({ value: "" });
  const [stepChangeVehicle, setStepChangeVehicle] = useState(1);
  const inputFileRef = useRef(null);

  const primaryVehicle = useMemo(() => {
    return (
      memberVehicles?.find((vehicle) => vehicle.isprimary === 1) ||
      (memberVehicles?.length > 0 && memberVehicles[0])
    );
  }, [memberVehicles]);

  const formik = useFormik({
    initialValues: {
      startdate: "",
      enddate: "",
      licenseplate: "",
      licenseby: "", //province id
      licenseprovince: "", //province name
      brand: "",
      vehiclemodel: "", //รุ่น
      vehiclecolor: "",
      vehicletypeid: "1",
      vechicleremark: "",
      vehicleowner: "",
      personalremark: "",
      vehicleowner_identity: "",
      doctypeid: "",
      doc: [],
      ispaperless: 1,
      vehicletype_name: "รถยนต์",
      ispermanent: 1,
    },
    isInitialValid: false,
    validationSchema,
    onSubmit: (values) => {
      try {
        const payload = {
          pmlsid: pmlsId,
          processid: 0,
          membervehicleid: 0, // #1484 membervehicleid แก้เป็นส่ง 0
          memberid: primaryVehicle?.pmlsmemberid,
          licenseplate: values.licenseplate, //ทะเบียนรถใหม่
          licenseby: values.licenseby,
          licenseprovince: values.licenseprovince, // จังหวัด
          vehiclemodel: values.vehiclemodel,
          vehicletypeid: values.vehicletypeid,
          brand: values.brand,
          vehiclecolor: values.vehiclecolor,
          licensetypeid: values.licensetypeid,
          status: "Inactive", //? #1520 เปลี่ยนรถ: แก้ไขค่าที่ส่งใน api , 1. การเปลี่ยนรถ ทั้งประเภทถาวรและชั่วคราว แก้ field status ส่งเป็น Inactive (fix)
          isprimary: 0, //fix 0
          startdate: values.ispermanent == 1 ? null : values.startdate,
          enddate: values.ispermanent == 1 ? null : values.enddate, //? #1520 เปลี่ยนรถ: แก้ไขค่าที่ส่งใน api , เปลี่ยนรถประเภทชั่วคราวแก้เปลี่ยนเวลาของ enddate ส่งเป็น 23:59:59
          vehicleowner: values.vehicleowner,
          vehicleowner_identity:
            values.vehicleowner !== 1 ? values.vehicleowner_identity : null, // #1484 แก้เป็นส่ง null (ถ้า vehicleowner = 1)
          vechicleremark: values.vechicleremark, // ""
          personalremark: "", // ""
          doc: values.doc.map((m) => ({
            docid: m.docid,
            memberid: m.memberid,
            doctypeid: m.doctypeid,
            docname: m.docname,
            docsize: m.docsize,
            docext: m.docext,
            doctypegroupid: 1,
            docgroupid: m.docgroupid,
            status: m.status,
            data_base64: m.data_base64,
          })),
          isapproval: 1, //fix
          ispaperless: values.ispaperless,
          isverifydoc: 0,
          docverifyby: 0,
          approver: 0,
          docremark: "",
          ispermanent: values.ispermanent, // ใช้ field นี้แทน isprimary
          vechiclechangefrom: primaryVehicle?.membervehicleid,
        };
        updateChangeVehicleMutate.mutate(payload);
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
  });

  const updateChangeVehicleMutate = useMutation({
    mutationFn: (payload) => MemberService.mappMemberChangeVehicle(payload),
    onSuccess: (response) => {
      if (response.ouIsComplete === 1) {
        setStepChangeVehicle(3);
      } else {
        toast.error(response.ouMessage, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    },
    onError: () => {
      toast.error("Something went wrong!", {
        position: "top-center",
        autoClose: 1000,
      });
    },
  });

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size > 5e6) {
      toast.error("Please upload a file < 5 MB", {
        position: "top-center",
        autoClose: 1000,
      });
      return false;
    }
    const base64 = await convertToBase64(file);
    const oBase64 = base64.split(",");
    if (oBase64.length > 0) {
      let ext = getExtension(oBase64[1]);
      let base64 = oBase64[1];
      let data = {
        docid: "",
        memberid: primaryVehicle?.pmlsmemberid,
        doctypeid: selectedDocType?.value,
        docgroupid: 1,
        docname: file.name,
        docsize: file.size,
        docext: ext,
        doctypegroupid: selectedDocType
          ? Number(selectedDocType?.doctypegroupid)
          : 0,
        status: "",
        data_base64: base64,
      };
      const newData = [...formik.values.doc];
      newData.push(data);
      formik.setFieldValue("doc", newData);
    }
    inputFileRef.current.value = "";
    setSelectedDocType(null);
  };

  const handleRemoveUpload = (index) => {
    let b = window.confirm(`คุณแน่ใจว่าต้องการลบข้อมูลเอกสารนี้ ?`);
    if (!b) return;
    const x = [...formik.values.doc];
    x.splice(index, 1);
    formik.setFieldValue("doc", x);
  };

  const msProvinceLicenseOptions = useMemo(() => {
    return generateProvinceLicenseOptions(provincelicense);
  }, [provincelicense]);

  const msCardBandOptions = useMemo(() => {
    return generateCarBrandOptions(carBrand);
  }, [carBrand]);

  const msLicenseOptions = useMemo(() => {
    return generateLicenseOptions(license);
  }, [license]);

  const msVehicleTypeOptions = useMemo(() => {
    return generateVehicleTypeOptions(vehicletype);
  }, [vehicletype]);

  const msOwnerVehicleOptions = useMemo(() => {
    return generateOwnerVehicleOptions(ownerVehicle);
  }, [ownerVehicle]);

  const msDoctypeGroupOptions = useMemo(() => {
    const filterByIsNewVehicleIsPermanent =
      formik.values.ispermanent === 1 ? 2 : 3;
    return doctypeGroup
      ?.filter(
        (item) =>
          item.isactive === 1 &&
          item.docgroupid === filterByIsNewVehicleIsPermanent
      )
      .map((item) => ({
        id: item.doctypeid,
        display: item.name,
      }));
  }, [doctypeGroup, formik.values.ispermanent]);

  const getDoctypeName = useCallback(
    (id) => {
      const x = doctypeGroup.filter(
        (item) =>
          item.isactive === 1 && item.docgroupid === 1 && item.doctypeid === id
      );
      if (x.length > 0) {
        return x[0].name;
      } else {
        return "";
      }
    },
    [doctypeGroup]
  );

  const pageTitle = useCallback(() => {
    let title = "";
    if (stepChangeVehicle === 1) {
      title = `${t("Fill in the Details ")} - ${t("Change Registered Car")}`;
    } else if (stepChangeVehicle === 2) {
      title = "ตรวจสอบข้อมูล";
    } else {
      return "";
    }
    return title;
  }, [stepChangeVehicle]);

  return (
    <>
      <div className="bg-[#E9F0F5] pt-20 md:pt-[150px]">
        <ContainerCenter>
          <ChangeVehicleStepper
            sxa={{ transform: "translateY(-20px)" }}
            activeStep={stepChangeVehicle}
          />
        </ContainerCenter>
      </div>
      <PageContent
        title={pageTitle()}
        breadcrumb={
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              {t("Home")}
            </Link>
            <Typography color="#245AB1">
              {t("Change Registered Car")}
            </Typography>
          </Breadcrumbs>
        }
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full mt-10">
            {stepChangeVehicle === 1 && (
              <div>
                <div>
                  <TitleWithCarIcon title={t("Existing Car Information")} />
                  <div className="grid md:grid-cols-2 gap-8 py-7">
                    <InputTextFieldAOT
                      label={t("Existing License Plate")}
                      disabled
                      val={primaryVehicle?.licenseplate || ""}
                      type="text"
                    />
                    <InputTextFieldAOT
                      label={t("Province")}
                      disabled
                      val={primaryVehicle?.licenseprovince || ""}
                    />
                    <InputTextFieldAOT
                      label={t("Member Vehicle Type")}
                      disabled
                      val={
                        primaryVehicle?.isprimary === 1 ? "ถาวร" : "ชั่วคราว"
                      }
                    />
                    <InputTextFieldAOT
                      label={t("Vehicle Type")}
                      disabled
                      val={
                        msVehicleTypeOptions?.find(
                          (item) => item.id === primaryVehicle?.vehicletypeid
                        )?.display || ""
                      }
                    />
                  </div>
                </div>
                <div className="pt-4">
                  <TitleWithCarIcon title={t("Member Vehicle Type")} />
                  <div className="grid md:grid-cols-2 gap-8 py-7">
                    <SelectAOT
                      label={t("Member Vehicle Type")}
                      name="ispermanent"
                      options={memberVehicleTypeOptions}
                      onChange={formik.handleChange}
                      value={formik.values.ispermanent}
                      error={formik.errors.ispermanent}
                      required
                    />
                    <div className="grid md:grid-cols-2 gap-9">
                      <DatePickerAOT
                        label={t("Apply Start Date")}
                        disabled={formik.values.ispermanent === 1}
                        handleChange={(e) => {
                          const startDate = dayjs(e).startOf("day");
                          const startDateFormatted =
                            startDate.format(DATE_FORMAT);
                          const currentDate = dayjs();

                          if (startDate.isBefore(currentDate, "day")) {
                            return alert(
                              "ไม่สามารถเลือกวันที่ย้อนหลังปัจจุบันได้"
                            );
                          }

                          const endDate = dayjs(startDateFormatted)
                            .add(15, "day")
                            .endOf("day")
                            .format(DATE_FORMAT);
                          formik.setFieldValue("startdate", startDateFormatted);
                          formik.setFieldValue("enddate", endDate);
                        }}
                        val={formik.values.startdate}
                        required={formik.values.ispermanent !== 1}
                      />
                      <DatePickerAOT
                        label={t("End Date")}
                        disabled
                        val={formik.values.enddate}
                      />
                    </div>
                    <InputTextFieldAOT
                      label={t("New License Plate")}
                      name="licenseplate"
                      val={formik.values.licenseplate}
                      handleChange={formik.handleChange}
                      error={formik.errors.licenseplate}
                      required
                    />
                    <SelectAOT
                      label={t("Province")}
                      name="licenseby"
                      disabled={loadingMs}
                      options={msProvinceLicenseOptions}
                      onChange={(e) => {
                        const licenseprovince = msProvinceLicenseOptions?.find(
                          (item) => item.id === e.target.value
                        )?.display;
                        formik.setFieldValue("licenseby", e.target.value);
                        formik.setFieldValue(
                          "licenseprovince",
                          licenseprovince
                        );
                      }}
                      value={formik.values.licenseby}
                      error={formik.errors.licenseby}
                      required
                    />
                    <SelectAOT
                      label={t("Brand")}
                      name="brand"
                      disabled={loadingMs}
                      options={msCardBandOptions}
                      onChange={(e) => {
                        const brandName = msCardBandOptions?.find(
                          (item) => item.id === e.target.value
                        )?.display;
                        formik.setFieldValue("brand", e.target.value);
                        formik.setFieldValue("brand_name", brandName);
                      }}
                      value={formik.values.brand}
                      error={formik.errors.brand}
                      required
                    />
                    <SelectAOT
                      label={t("License Plate Type")}
                      name="licensetypeid"
                      disabled={loadingMs}
                      options={msLicenseOptions}
                      onChange={(e) => {
                        const licenseTypeName = msLicenseOptions?.find(
                          (item) => item.id === e.target.value
                        )?.display;
                        formik.setFieldValue("licensetypeid", e.target.value);
                        formik.setFieldValue(
                          "licensetype_name",
                          licenseTypeName
                        );
                      }}
                      value={formik.values.licensetypeid}
                    />
                    <InputTextFieldAOT
                      label={t("Model")}
                      name="vehiclemodel"
                      val={formik.values.vehiclemodel}
                      handleChange={formik.handleChange}
                    />
                    <InputTextFieldAOT
                      label={t("Vehicle Color")}
                      name="vehiclecolor"
                      val={formik.values.vehiclecolor}
                      handleChange={formik.handleChange}
                    />

                    <InputTextFieldAOT
                      label={t("Remark")}
                      name="vechicleremark"
                      val={formik.values.vechicleremark}
                      handleChange={formik.handleChange}
                    />

                    <SelectAOT
                      label={t("Owner Type")}
                      name="vehicleowner"
                      disabled={loadingMs}
                      options={msOwnerVehicleOptions}
                      onChange={(e) => {
                        const vehicleowner_name = msOwnerVehicleOptions?.find(
                          (item) => item.id === e.target.value
                        )?.display;
                        formik.setFieldValue("vehicleowner", e.target.value);
                        formik.setFieldValue(
                          "vehicleowner_name",
                          vehicleowner_name
                        );
                      }}
                      value={formik.values.vehicleowner}
                      error={formik.errors.vehicleowner}
                      required
                    />
                    <InputTextFieldAOT
                      label={t("ID No in case you are not a vehicle owner")}
                      name="vehicleowner_identity"
                      disabled={formik.values.vehicleowner === 1}
                      handleChange={formik.handleChange}
                      val={formik.values.vehicleowner_identity}
                      required={formik.values.vehicleowner !== 1}
                    />
                  </div>
                </div>

                <div>
                  <span className="text-[#2F2E2F] font-semibold opacity text-3xl">
                    {t("Upload the documents")}
                  </span>
                  <div className="px-4 py-12">
                    <div>
                      <div className="flex items-center gap-2">
                        <CheckBoxAOT
                          name="ispaperless"
                          checked={formik.values.ispaperless === 1}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "ispaperless",
                              e.target.checked ? 1 : 0
                            )
                          }
                        />
                        <span className="text-[#2F2E2F] font-semibold text-xl">
                          {t("Submit the electronic documents")}
                        </span>
                      </div>
                      <div className="grid md:grid-cols-2 gap-8 py-7">
                        <input
                          accept="image/*"
                          id="img_upload"
                          type="file"
                          style={{ display: "none" }}
                          ref={inputFileRef}
                          onChange={handleFileUpload}
                        />
                        <SelectAOT
                          label={t("Select document type")}
                          disabled={loadingMs}
                          options={msDoctypeGroupOptions}
                          onChange={(e) => {
                            setSelectedDocType({
                              ...e.target,
                              doctypegroupid: doctypeGroup?.find(
                                (item) =>
                                  item.isactive === 1 &&
                                  item.docgroupid == 1 &&
                                  item.doctypeid === e.target.value
                              )?.doctypegroupid,
                            });
                          }}
                          value={selectedDocType?.value}
                        />
                        <ButtonAction
                          icon={
                            <AttachFile
                              sx={{
                                color: "#FFFFFF",
                                transform: "rotate(44deg)",
                              }}
                            />
                          }
                          disabled={!selectedDocType?.value}
                          onClick={() => inputFileRef.current.click()}
                        >
                          {t("Attach DocumentImage")}
                        </ButtonAction>
                      </div>
                    </div>
                    <div>
                      <span className="text-[#2F2E2F] font-semibold text-xl">
                        {t("Uploaded Documents")}
                      </span>
                      <div className="border border-dashed border-[#6399EB] rounded-xl px-10 py-10 my-5">
                        <div className="flex justify-center md:justify-start gap-9 flex-wrap">
                          {formik.values.doc.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="text-center flex flex-col items-center gap-2 w-[250px] max-w-[250px]"
                              >
                                <div className="border border-neutral-200  gap-2 flex items-center flex-col py-2 px-2 rounded-md w-full">
                                  <img
                                    src={convertToURI(item.data_base64)}
                                    className="min-w-[120px] h-36 rounded-sm object-contain"
                                  />

                                  <span
                                    onClick={() => handleRemoveUpload(index)}
                                    className="text-red-500 cursor-pointer"
                                  >
                                    Remove
                                  </span>
                                </div>
                                <span>
                                  {`${index + 1}. ${getDoctypeName(
                                    item.doctypeid
                                  )}`}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {stepChangeVehicle == 2 && (
            <PreviewChangeVehicle
              formikValues={formik.values}
              primaryVehicle={primaryVehicle}
              memberInfo={memberInfo}
              getDoctypeName={getDoctypeName}
              handleRemoveUpload={handleRemoveUpload}
            />
          )}
          {stepChangeVehicle == 3 && <ResponseChangeVehicle />}
          {(stepChangeVehicle == 1 || stepChangeVehicle == 2) && (
            <div className="grid md:grid-cols-2 gap-8 py-12">
              <ButtonAction
                variant="secondary"
                onClick={() => {
                  if (stepChangeVehicle == 1) {
                    formik.resetForm();
                  } else {
                    setStepChangeVehicle(1);
                  }
                }}
              >
                {stepChangeVehicle == 1 ? "ยกเลิก" : "กลับ"}
              </ButtonAction>
              <ButtonAction
                onClick={() => {
                  if (stepChangeVehicle == 1) {
                    setStepChangeVehicle(2);
                  } else {
                    formik.handleSubmit();
                  }
                }}
                disabled={!formik.isValid || formik.values.doc?.length == 0}
              >
                {stepChangeVehicle == 1 ? t("SaveNext") : t("Submit Documents")}
              </ButtonAction>
            </div>
          )}
          {stepChangeVehicle == 3 && (
            <div className="flex items-center justify-center py-12 w-full">
              <div className="">
                <ButtonAction
                  onClick={() => {
                    navigate("/pages");
                  }}
                >
                  {t("Back to Home")}
                </ButtonAction>
              </div>
            </div>
          )}
        </form>
      </PageContent>
    </>
  );
};

const TitleWithCarIcon = ({ title }) => {
  return (
    <div className="text-[#2F2E2F] font-semibold text-xl inline-flex items-center gap-4">
      <img src="/images/change_vehicle/ico_car.svg" />
      {title}
    </div>
  );
};

export default ChangeVehiclePage;
