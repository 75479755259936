import * as React from "react"
import { styled } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#245AB1",
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0
    }
}))

const getDiffDate = (startdate, enddate) => {
    if (!startdate) return ""
    let sd = dayjs(startdate)
    let ed = dayjs()
    if (enddate) ed = dayjs(enddate)
    const mins = ed.diff(sd, "minutes", true)
    const totalHours = parseInt(mins / 60)
    const totalMins = dayjs().minute(mins).$m
    return `${totalHours}:${totalMins < 10 ? "0" + totalMins : totalMins}`
}

const calDuration = (item) => {
    if (item.TbEntryExit_exitdt) {
        if (item.TbParkingFee_duration_hour || item.TbParkingFee_duration_minute) {
            return `${item.TbParkingFee_duration_hour ? item.TbParkingFee_duration_hour : "0"}:${
                item.TbParkingFee_duration_minute ? item.TbParkingFee_duration_minute : "0"
            }`
        } else {
            return getDiffDate(item.TbEntryExit_entrydt, item.TbEntryExit_exitdt)
        }
    } else {
        return getDiffDate(item.TbEntryExit_entrydt, item.TbEntryExit_exitdt)
    }
}

export default function HistoryTable({ data, loading, onClickDetailHandler }) {
    const { t } = useTranslation()
    return (
        <TableContainer component={Paper} className='mt-5'>
            <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                <TableHead>
                    <TableRow>
                        {/* <StyledTableCell align="start">{t('Date')}</StyledTableCell>
            <StyledTableCell align="center">{t('Duration')}</StyledTableCell>
            <StyledTableCell align="center">{t('Parking Fee')}</StyledTableCell> */}
                        <StyledTableCell align='start'>{t("Licenseplate")}</StyledTableCell>
                        <StyledTableCell align='start'>{t("Province")}</StyledTableCell>
                        <StyledTableCell align='start'>{t("Entry Date")}</StyledTableCell>
                        <StyledTableCell align='start'>{t("Exit Date")}</StyledTableCell>
                        <StyledTableCell align='start'>{t("Duration")}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <StyledTableRow>
                            <StyledTableCell align='center' colSpan={6}>
                                Loading...
                            </StyledTableCell>
                        </StyledTableRow>
                    ) : (
                        data?.map((item, index) => (
                            // <StyledTableRow key={item.processid}>
                            //     <StyledTableCell component='th' scope='row' align='start'>
                            //         {index + 1}
                            //     </StyledTableCell>
                            //     <StyledTableCell align='center'>{item.processid}</StyledTableCell>
                            //     <StyledTableCell align='center'>{item.processname}</StyledTableCell>
                            //     <StyledTableCell align='center'>{item.createdt}</StyledTableCell>
                            // </StyledTableRow>
                            <StyledTableRow key={index}>
                                <StyledTableCell component='th' scope='row' align='start'>
                                    {item.TbEntryExit_licenseplate}
                                </StyledTableCell>
                                <StyledTableCell align='start'>{item.TbEntryExit_licprovince}</StyledTableCell>
                                <StyledTableCell align='start'>{item.TbEntryExit_entrydt}</StyledTableCell>
                                <StyledTableCell align='start'>{item.TbEntryExit_exitdt}</StyledTableCell>
                                <StyledTableCell align='start'>{calDuration(item)}</StyledTableCell>
                            </StyledTableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
