import React from "react";
import { Box, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import HistoryTable from "./HistoryTable";
import { useState } from "react";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../constants";
import { DatePickerAOT } from "../Controls";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import MemberService from "../../services/member-service";

const ImgRenew = styled((props) => <Box component="img" {...props} />)(() => ({
  width: "2.5rem",
}));

const History = () => {
  const { t } = useTranslation();
  const { memberInfo } = useSelector((state) => state.auth);
  const { pmlsId } = useSelector((state) => state.airport);
  const [filterValue, setFilterValue] = useState({
    startdt: "",
    enddt: "",
  });

  const memberReceiptHistory = useQuery({
    queryKey: ["GET_MEMBER_ENTRY_EXIT_HISTORY", filterValue],
    queryFn: () =>
      MemberService.mmapMemberHistoryEntryExitFilter({
        pmlsid: pmlsId,
        memberid: memberInfo?.pmlsmemberid,
        ...filterValue,
      }),
    select: (data) => data?.data?.tbentryexit,
    enabled: pmlsId !== undefined && memberInfo?.pmlsmemberid !== undefined,
  });

  return (
    <div className="w-full p-20 text-[#2F2E2F]">
      <div className="flex justify-between items-start">
        <div>
          <div className="inline-flex items-center mb-4">
            <div className="inline-flex gap-3 items-center">
              <ImgRenew src="/images/history/ico_carhistory_head.svg" />
              <p className="text-3xl text-[#2F2E2F] font-bold">
                {t("Parking Usage History")}
              </p>
            </div>
          </div>
        </div>
        <div className="text-sm">
          {t("Home")} /
          <span className={"text-[#245AB1] font-semibold"}>
            {t("Parking Usage History")}
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-5">
        <DatePickerAOT
          label={t("Start Date")}
          handleChange={(e) => {
            setFilterValue({
              ...filterValue,
              startdt: e !== null ? dayjs(e).format(DATE_FORMAT) : "",
            });
          }}
          sxa={{}}
          val={filterValue?.startdt}
        />
        <DatePickerAOT
          label={t("End Date")}
          handleChange={(e) => {
            setFilterValue({
              ...filterValue,
              enddt: e !== null ? dayjs(e).format(DATE_FORMAT) : "",
            });
          }}
          sxa={{}}
          val={filterValue?.enddt}
        />
      </div>

      <div>
        <HistoryTable
          data={memberReceiptHistory?.data}
          loading={memberReceiptHistory.isLoading}
        />
      </div>
    </div>
  );
};

export default History;
