import { setMasterData,setLoading } from '../features/masterDataSlice';
import MasterService from './master-service';

export const fetchMasterData = (langId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const [
        airportsData,
        pmlsData,
        membertypeData,
        nationalityData,
        titleData,
        licenseData,
        vehicletypeData,
        ownerVehicleData,
        doctypeData,
        doctypeGroupData,
        provincelicenseData,
        officeTypeData,
        provinceData,
        carBrandData,
        paymentTypeData,
      ] = await Promise.all([
        MasterService.getAirports(langId),
        MasterService.getPMLS(),
        MasterService.getMembertype(),
        MasterService.getNationality(),
        MasterService.getTitle(),
        MasterService.getLicense(),
        MasterService.getVehicletype(langId),
        MasterService.getOwnerVehicle(),
        MasterService.getDoctype(),
        MasterService.getDoctypeGroup(),
        MasterService.getProvinceLicense(),
        MasterService.getOfficeType(),
        MasterService.getProvince(),
        MasterService.getCarBrand(),
        MasterService.getPaymentType()
        // Add other master data service calls here
      ]);
          // Extract the data from the responses (assuming each service call returns an object with a 'data' property)
    const masterData = {
        airports: airportsData?.data?.allTbAirport || [],
        pmls: pmlsData?.data?.allSysPmls || [],
        membertype: membertypeData?.data?.allMsMemberType || [],
        nationality: nationalityData?.data?.allMsNationality || [],
        title: titleData?.data?.allMsTitle || [],
        license: licenseData?.data?.allMsLicense || [],
        vehicletype: vehicletypeData?.data?.allMsVehicleType || [],
        ownerVehicle: ownerVehicleData?.data?.allOwnerVechicle || [],
        doctype: doctypeData?.data?.allMsDocType || [],
        doctypeGroup: doctypeGroupData?.data?.allMsDocTypeGroup || [],
        provincelicense: provincelicenseData?.data?.allMsProvinceLicense || [],
        officeType: officeTypeData?.data?.allOffice || [],
        province: provinceData?.data?.allMsProvince || [],
        carBrand: carBrandData?.data?.allMsCarBrand || [],
        paymentType: paymentTypeData?.data?.allMsPaymentType || []
      };

    // Dispatch the data to the Redux store
    dispatch(
      setMasterData(masterData)
    );
    dispatch(setLoading(false));
  } catch (error) {
    console.error('Error fetching master data:', error);
    dispatch(setLoading(false));
  }
};
