export const getExtension = (base64) => {
    if (base64 == null || undefined) {
        return ''
    }
    const f = base64.charAt(0)
    let ext = ''
    switch (f) {
        case '/': {
            ext = 'jpeg'
            break
        }
        case 'i': {
            ext = 'png'
            break
        }
        case 'R': {
            ext = 'gif'
            break
        }
        case 'U': {
            ext = 'webp'
            break
        }
        case 'J': {
            ext = 'pdf'
            break
        }
        default: {
            ext = 'jpeg'
            break
        }
    }
    return ext
}

export const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
}

export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

export const convertToImageUrl = (base64) => {
    if (base64 == null || undefined) {
        return ''
    }
    const ext = getExtension(base64)
    let url = ''
    if (ext === 'pdf') {
        url = `url(data:application/${ext};base64,${base64})`
    } else {
        url = `url(data:image/${ext};base64,${base64})`
    }
    return url
}

export const convertToURI = (base64) => {
    if (base64 == null || undefined) {
        return ''
    }
    const ext = getExtension(base64)
    let url = ''
    if (ext === 'pdf') {
        url = `data:application/${ext};base64,${base64}`
    } else {
        url = `data:image/${ext};charset=utf-8;base64,${base64}`
    }
    return url
}

//export const printPreview = (data, type = 'application/pdf') => {
export const printPreview = (blob) => {
    //let blob = null
    //blob = this.b64toBlob(data, type)
    const blobURL = URL.createObjectURL(blob)
    const theWindow = window.open(blobURL)
    const theDoc = theWindow.document
    const theScript = document.createElement('script')
    function injectThis() {
        window.print()
    }
    theScript.innerHTML = `window.onload = ${injectThis.toString()};`
    theDoc.body.appendChild(theScript)
}

export const openPreview = (blob) => {
    const blobURL = URL.createObjectURL(blob)
    const theWindow = window.open(blobURL)
    const theDoc = theWindow.document
    const theScript = document.createElement('script')
    theDoc.body.appendChild(theScript)
}

export const openPreviewAndPrint = (blob) => {
    const blobURL = URL.createObjectURL(blob)
    const theWindow = window.open(blobURL)
    const theDoc = theWindow.document
    const theScript = document.createElement('script')
    function injectThis() {
        window.print()
    }
    theScript.innerHTML = `window.onload = ${injectThis.toString()};`
    theDoc.body.appendChild(theScript)
}

export const b64toBlob = (content, contentType) => {
    contentType = contentType || ''
    const sliceSize = 512
    // method which converts base64 to binary
    const byteCharacters = window.atob(content)

    const byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)
        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }
    const blob = new Blob(byteArrays, {
        type: contentType
    }) // statement which creates the blob
    return blob
}
