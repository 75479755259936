import { Box, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { InputAOT } from "./InputAOT";

const SelectAOT = ({
  label,
  width,
  data,
  val,
  setVal,
  sxa,
  sxb,
  imgsrc,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [display, setDisplay] = useState(
    data?.find((x) => x.id === val)?.id || ""
  );

  const handleClick = () => {
    if (!disabled) setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleSelectAndClose = (e) => {
    setDisplay(e.display);
    setVal(e.id);
    setIsOpen(false);
  };
  return (
    <Box sx={{ position: "relative", ...sxa }} onMouseLeave={handleClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          border: "2px solid #3D9AEE",
          height: "64px",
          width,
          ...sxa
        }}
      >
        <InputAOT
          sx={{ flex: "1 1 auto" }}
          label={label}
          variant="filled"
          value={display}
          InputProps={{ disableUnderline: true }}
          onClick={handleClick}
          disabled={disabled}
        />
        <Box
          sx={{
            cursor: "pointer",
            paddingX: "10px",
            margin: 0,
            display: "flex",
          }}
        >
          <img
            alt=""
            src={imgsrc ? imgsrc : "/images/home_aot/ico_ direction.svg"}
            onClick={handleClick}
            style={{ width: "26px" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          width: "100%",
          // height: '200px',
          display: isOpen ? "block" : "none",
          position: "absolute",
          zIndex: "4000",
          overflowY: "auto",
          ...sxb,
        }}
        onMouseLeave={handleClose}
      >
        {data &&
          data.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  handleSelectAndClose(item);
                }}
                disableRipple
                disabled={disabled}
              >
                {item.display}
              </MenuItem>
            );
          })}
      </Box>
    </Box>
  );
};

export default SelectAOT;
