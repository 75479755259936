import instance from "../config/apiAxios";

export const get = async (path, params, token) => {
  if (token) {
    return await instance.get(`${path}`, {
      withCredentials: false,
      crossDomain: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: `Bearer ${token}`,
      },
      params: params,
    });
  } else {
    return await instance.get(`${path}`, {
      withCredentials: false,
      crossDomain: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      params: params,
    });
  }
};
export const post = async (path, body, token) => {
  if (token) {
    return await instance.post(`${path}`, body, {
      withCredentials: false,
      crossDomain: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    return await instance.post(`${path}`, body, {
      withCredentials: false,
      crossDomain: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  }
};

export const put = async (path, body, token) => {
  if (token) {
    return await instance.put(`${path}`, body, {
      withCredentials: false,
      crossDomain: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    return await instance.put(`${path}`, body, {
      withCredentials: false,
      crossDomain: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "application/json",
      },
    });
  }
};

export const postBlob = async (path, body, token) => {
  if (token) {
    return await instance.post(`${path}`, body, {
      withCredentials: false,
      crossDomain: true,
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    return await instance.post(`${path}`, body, {
      withCredentials: false,
      crossDomain: true,
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  }
};

export const getX = async (path, params, xapp) => {
  return await instance.get(`${path}`, {
    withCredentials: false,
    crossDomain: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "x-app": xapp,
    },
    params: params,
  });
};

export const getT = async (path, params, token) => {
  const p_token = process.env.REACT_APP_TOKEN_PUBLIC;
  return await instance.get(`${path}`, {
    headers: {
      Authorization: `Bearer ${p_token}`,
    },
  });
};
