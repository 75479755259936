import { toast } from "react-toastify";
import { LANG, LANG_ID, PMLS_ID_TO_AIRPORT_TEXT } from "../constants";
import { METHOD_PAYMENT } from "../hooks/usePayment";

const isThaiNationality = (code) => code === "TH";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const getLangIdFormPMLSlang = (lang) => {
  let langId;
  if (lang === LANG.TH) {
    langId = LANG_ID.TH;
  } else if (lang === LANG.EN) {
    langId = LANG_ID.EN;
  }
  return langId;
};

const getAirportNameFormPmlsAndLang = (pmlsId, lang) => {
  if(!pmlsId || !lang) return '-'
  return PMLS_ID_TO_AIRPORT_TEXT[pmlsId][lang];
};

const getPaymentTranslationKeyByPaymentMethod = (paymentMethod) => {
  let key = "";
  if (paymentMethod === METHOD_PAYMENT.QR_CODE) {
    key = "QR PromptPay";
  } else if (paymentMethod === METHOD_PAYMENT.CREDIT_CARD) {
    key = "Credit Card";
  } else {
    key = "3";
  }
  return key;
};

const isAllAirportsAvailable = (availableAirports) => {
  if (availableAirports.donmuang && availableAirports.suvarnabhumi) {
    return true;
  }
  return false;
};

const successRequest = (response) => {
  return (
    response.ouIsComplete === 1 &&
    response.ouMessage === "Success" &&
    response.ouReasonCode === "100000"
  );
};

const toastErr = (msg, autoClose = 1500) =>
  toast.error(msg, {
    position: "top-center",
    autoClose: autoClose,
  });

const toastWarning = (msg, autoClose = 1500) =>
  toast.error(msg, {
    position: "top-center",
    autoClose: autoClose,
  });

const getLicensePlateProvinceText = (data) => {
  if (data?.licenseplate && data?.licenseprovince) {
    return `${data?.licenseplate} ${data?.licenseprovince}`;
  } else {
    return "-";
  }
};

export {
  classNames,
  isThaiNationality,
  getLangIdFormPMLSlang,
  getAirportNameFormPmlsAndLang,
  getPaymentTranslationKeyByPaymentMethod,
  isAllAirportsAvailable,
  toastErr,
  toastWarning,
  successRequest,
  getLicensePlateProvinceText
};
