import instance from "../config/apiAxios";

const BASE_PATH = "/ms";

const MasterService = {
  getAirports: async (langid) => {
    const response = await instance.get(BASE_PATH + "/airport", {
      params: {
        langid: langid,
      },
    });
    return response.data;
  },
  getPMLS: async () => {
    const response = await instance.get(BASE_PATH + "/pmls");
    return response.data;
  },
  getProvince: async () => {
    const response = await instance.get(BASE_PATH + `/province`);
    return response.data;
  },
  getDistrictByProvinceId: async (provinceid) => {
    const response = await instance.get(BASE_PATH + `/district`, {
      params: {
        provinceid,
      },
    });
    return response.data;
  },
  getSubDistrict: async (provinceid, districtid) => {
    const response = await instance.get(
      `${BASE_PATH}/subdistrict?provinceid=${provinceid}&districtid=${districtid}`,
      null
    );
    return response.data;
  },

  getMembertype: async () => {
    const response = await instance.get(`${BASE_PATH}/membertype`);
    return response.data;
  },

  getNationality: async () => {
    const response = await instance.get(`${BASE_PATH}/nationality`);
    return response.data;
  },

  getTitle: async () => {
    const response = await instance.get(`${BASE_PATH}/title`);
    return response.data;
  },

  getLicense: async () => {
    const response = await instance.get(`${BASE_PATH}/license`);
    return response.data;
  },

  getVehicletype: async (langid) => {
    const response = await instance.get(`${BASE_PATH}/vehicletype`, {
      params: {
        langid,
      },
    });
    return response.data;
  },

  getOwnerVehicle: async () => {
    const response = await instance.get(`${BASE_PATH}/ownervehicle`);
    return response.data;
  },

  getDoctype: async () => {
    const response = await instance.get(`${BASE_PATH}/doctype`);
    return response.data;
  },

  getDoctypeGroup: async () => {
    const response = await instance.get(`${BASE_PATH}/doctypegroup`);
    return response.data;
  },

  getProvinceLicense: async () => {
    const response = await instance.get(`${BASE_PATH}/provincelicense`);
    return response.data;
  },

  getOfficeType: async () => {
    const response = await instance.get(`${BASE_PATH}/office`);
    return response.data;
  },

  getCarBrand: async () => {
    const response = await instance.get(`${BASE_PATH}/carbrand`);
    return response.data;
  },

  getPaymentType: async () => {
    const response = await instance.get(`${BASE_PATH}/paymenttype`);
    return response.data;
  },
};

export const getAllMasterData = async () => {
  const airports = await MasterService.getAirports();
  const pmls = await MasterService.getPMLS();
  const membertype = await MasterService.getMembertype();
  const nationality = await MasterService.getNationality();
  const title = await MasterService.getTitle();
  const license = await MasterService.getLicense();
  const vehicletype = await MasterService.getVehicletype();
  const ownerVehicle = await MasterService.getOwnerVehicle();
  const doctype = await MasterService.getDoctype();
  const doctypeGroup = await MasterService.getDoctypeGroup();
  const provinceLicense = await MasterService.getProvinceLicense();
  const officeType = await MasterService.getOfficeType();
  const province = await MasterService.getProvince();
  const carBrand = await MasterService.getCarBrand();
  const paymentType = await MasterService.getPaymentType();
  return {
    airports,
    pmls,
    membertype,
    nationality,
    title,
    license,
    vehicletype,
    ownerVehicle,
    doctype,
    doctypeGroup,
    provinceLicense,
    officeType,
    province,
    carBrand,
    paymentType,
  };
};

export default MasterService;
