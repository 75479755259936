import React from "react";
import { useTranslation } from "react-i18next";

const CookiePolicyPage = () => {
  const { i18n } = useTranslation();

  return (
    <div className="w-full">
      <div
        className=""
        style={{
          backgroundImage: "url('/images/change_vehicle/img_changecar.png')",
          height: 300,
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
      <div className="container mx-auto py-24 ">
        {i18n.language === "th" ? <Thai /> : <English />}
      </div>
    </div>
  );
};

const Thai = () => {
  return (
    <div>
      <h1 className="text-center text-2xl font-semibold">
        นโยบายการใช้คุกกี้ (Cookies Policy)
      </h1>
      <p className="indent-28 mt-10 px-10">
        บริษัท ท่าอากาศยานไทย จำกัด (มหาชน) (“ทอท.”)
        มีการใช้งานคุกกี้หรือเทคโนโลยีอื่นใดที่มีลักษณะใกล้เคียงกันบนเว็บไซต์ของ
        ทอท. เพื่อช่วยให้ท่านได้รับประสบการณ์ที่ดีจากการใช้งานเว็บไซต์
        และช่วยให้ ทอท.
        สามารถพัฒนาคุณภาพการให้บริการเว็บไซต์ที่ตรงต่อความต้องการของท่านมากยิ่งขึ้น
      </p>
      <ul className="list-decimal px-28 mt-10 ">
        <div className="py-5">
          <li className="text-lg font-medium ">คุกกี้คืออะไร</li>
          <p className="py-2 indent-12">
            คุกกี้ คือ
            ไฟล์ข้อความขนาดเล็กที่ถูกดาวน์โหลดไปยังเว็บเบราว์เซอร์บนอุปกรณ์อิเล็กทรอนิกส์
            หรืออุปกรณ์เชื่อมต่ออินเทอร์เน็ตของท่าน
            เพื่อบันทึกข้อมูลเกี่ยวกับการเยี่ยมชมเว็บไซต์ของ ทอท.
            และการตั้งค่าต่างๆ ของท่าน โดยเครื่องเซิร์ฟเวอร์ของ ทอท.
            จะเรียกดูข้อมูลที่ถูกบันทึกและเก็บรวบรวมและใช้ข้อมูลดังกล่าว
            เพื่อพัฒนาการให้บริการของ ทอท.
            แก่ท่านได้อย่างต่อเนื่องและมีประสิทธิภาพ
          </p>
        </div>

        <div className="py-5">
          <li className="text-lg font-medium ">
            ประเภทและวัตถุประสงค์ในการใช้งานคุกกี้
          </li>
          <p className="py-2 indent-12">
            เว็บไซต์ของ ทอท. จะมีการใช้งานคุกกี้ตามประเภทและวัตถุประสงค์ต่างๆ
            เช่น
            <p>
              2.1 คุกกี้ประเภทจำเป็นถาวร (Strictly Necessary Cookies)
              คุกกี้ประเภทนี้มีความสำคัญต่อการทำงานของเว็บไซต์ ทอท.
              เพื่อให้ท่านสามารถเข้าถึงข้อมูลและใช้งานเว็บไซต์ของ ทอท.
              ได้อย่างปลอดภัย
            </p>
            <p>
              2.2 คุกกี้ประเภทการทำงาน (Functional Cookies)
              คุกกี้ประเภทนี้จะจดจำท่านระหว่างการเยี่ยมชม
              และตั้งค่าเว็บไซต์ให้สอดคล้องกับการใช้งานของท่าน
              เพื่ออำนวยความสะดวกเมื่อท่านกลับเข้ามาใช้งานเว็บไซต์อีกครั้ง
            </p>
            <p>
              2.3 คุกกี้ประเภทการวิเคราะห์หรือวัดผลการทำงาน
              (Analytic/Performance Cookies) คุกกี้ประเภทนี้ทำให้ ทอท.
              สามารถวิเคราะห์หรือวัดผลการทำงานของเว็บไซต์
              และเข้าใจถึงความสนใจของท่าน เพื่อนำไปบริหารจัดการ ปรับปรุง
              และพัฒนาเว็บไซต์ให้ดียิ่งขึ้น
            </p>
            <p>
              2.4 คุกกี้ประเภทการโฆษณา (Advertising Cookies)
              คุกกี้ประเภทนี้จะจดจำการตั้งค่าของท่านในการเข้าใช้งานหน้าเว็บไซต์
              และนำไปใช้เป็นข้อมูลประกอบการปรับเปลี่ยนหน้าเว็บไซต์ เพื่อตั้งค่า
              ปรับแต่ง
              และนำเสนอข้อมูลหรือโฆษณาให้เหมาะสมตรงตามความสนใจและความชื่นชอบของท่านมากยิ่งขึ้น
            </p>
          </p>
          <li className="text-lg font-medium ">การเก็บข้อมูลของคุกกี้</li>
          <p className="py-2 indent-12">
            ทอท.
            เก็บรวบรวมข้อมูลการเยี่ยมชมเว็บไซต์ของท่านโดยอัตโนมัติผ่านทางคุกกี้
            เช่น
            <p>
              3.1 อินเทอร์เน็ตโดเมน และหมายเลขประจำเครื่องคอมพิวเตอร์
              หรืออินเทอร์เน็ตโพรโทคอล (IP Address) จากจุดที่ท่านเข้าสู่เว็บไซต์
            </p>
            <p>
              3.2 ประเภทของเบราว์เซอร์ซอฟต์แวร์
              ตลอดจนโครงสร้างและระบบปฏิบัติงานที่ใช้ในการเข้าสู่เว็บไซต์ของ ทอท.
            </p>
            <p>3.3 วันที่และเวลาที่ท่านเข้าสู่เว็บไซต์ของ ทอท.</p>
            <p>
              3.4
              ที่อยู่ของเว็บไซต์อื่นที่ท่านใช้เพื่อเชื่อมโยงท่านเข้าสู่เว็บไซต์ของ
              ทอท. หรือออกจากเว็บไซต์ของ ทอท
            </p>
            <p>
              3.5 จำนวนผู้เยี่ยมชมเว็บไซต์ของ ทอท.
              พฤติกรรมการเยี่ยมชมเว็บไซต์ของ ทอท. จำนวนหน้าเว็บไซต์ของ ทอท.
              ที่ท่านเข้าใช้งาน ระยะเวลาที่ท่านใช้ในการเยี่ยมชม
              และเนื้อหาบนหน้าเว็บไซต์ของ ทอท. ที่ท่านเยี่ยมชม
            </p>
            <p>
              3.6 ภาษาที่ท่านเลือกใช้บนหน้าเว็บไซต์ และคำค้น (Keyword Search)
              ล่าสุดที่ท่านใช้งาน ทั้งนี้ ข้อมูลดังกล่าวจะไม่ถูกเปิดเผย
              หรือบ่งบอกความเป็นตัวตนของท่านได้ และไม่มีข้อมูลจำเพาะบุคคล
            </p>
          </p>
          <li className="text-lg font-medium ">การตั้งค่าและการปฏิเสธคุกกี้</li>
          <p className="py-2 indent-12">
            ท่านสามารถเลือกปฏิเสธการทำงานของคุกกี้ได้
            โดยการตั้งค่าเบราว์เซอร์หรือการตั้งค่าความเป็นส่วนตัวของท่าน
            เพื่อระงับการเก็บรวบรวมข้อมูลโดยคุกกี้ในอนาคต
            (ท่านสามารถศึกษารายละเอียดเพิ่มเติมเกี่ยวกับการตั้งค่าและการปฏิเสธคุกกี้ได้ที่
            AboutCookies.org)
            <p>
              อย่างไรก็ตาม บริการบางอย่างบนเว็บไซต์ของ ทอท. จำเป็นต้องใช้คุกกี้
              หากท่านตั้งค่าเบราว์เซอร์ หรือตั้งค่าความเป็นส่วนตัวของท่าน
              ด้วยการปฏิเสธการทำงานของคุกกี้ทั้งหมด
              อาจส่งผลให้ท่านไม่ได้รับความสะดวกในการเข้าใช้งานเว็บไซต์
              และถูกจำกัดพื้นที่การใช้งานบนเว็บไซต์ได้
            </p>
          </p>
        </div>
      </ul>
    </div>
  );
};

const English = () => {
  return (
    <div>
      <h1 className="text-center text-2xl font-semibold">Cookies Policy</h1>
      <p className="indent-28 mt-10 px-10">
        Airports of Thailand PLC. (“AOT”) uses Cookies or other similar
        technologies on AOT’s website in order to provide you with a better
        experience from use of website and to help AOT to improve AOT’s website
        service quality to better meet your preference.
      </p>
      <ul className="list-decimal px-28 mt-10 ">
        <div className="py-5">
          <li className="text-lg font-medium ">What are Cookies?</li>
          <p className="py-2 indent-12">
            Cookies are small files of data which are downloaded to web browsers
            on your electronic devices or your internet connected devices in
            order to store information about AOT’s website visits and your
            settings. AOT servers will retrieve the stored information and
            collect and use such information to develop AOT’s service gradually
            and efficiently.
          </p>
        </div>

        <div className="py-5">
          <li className="text-lg font-medium ">
            Types and Purposes of Use of Cookies
          </li>
          <p className="py-2 indent-12">
            AOT’s website will use Cookies according to the types and purposes
            such as:
            <p>
              2.1 Strictly Necessary Cookies – These Cookies are necessary for
              performance of AOT’s website in order that you can access to
              information and use AOT’s website safely.
            </p>
            <p>
              2.2 Functional Cookies – These Cookies will remember you during
              website visit and personalization to facilitate you when you are
              back to use the website again
            </p>
            <p>
              2.3 Analytic/Performance Cookies – These Cookies will enable AOT
              to analyze or evaluate website performance and to understand your
              interest in order to manage, improve and better AOT’s website.
            </p>
            <p>
              2.4 Advertising Cookies – These Cookies will remember your
              personalized setting for use of AOT’s website and will use the
              information for webpage customization to set up, modify and
              properly introduce information or advertisement to suit your
              interest and preference.
            </p>
          </p>
          <li className="text-lg font-medium ">Data Collection of Cookies</li>
          <p className="py-2 indent-12">
            AOT will automatically collect your website visit information
            through Cookies such as:
            <p>
              3.1 Internet Domain and IP Number or Internet Protocol Address (IP
              Address) from the website access point
            </p>
            <p>
              3.2 Types of browser software including structure and operating
              system used for accessing to AOT’s website
            </p>
            <p>3.3 Date and time you access to AOT’s website</p>
            <p>
              3.4 Other website address which you use to connect to AOT’s
              website or leave AOT’s website
            </p>
            <p>
              3.5 Total number of AOT’s website visitors, website visitor
              behavior, total number of website pages you access, web sessions
              and website information you visit
            </p>
            <p>
              3.6 Preferred language and recent keyword search you used
              <p className="indent-12">
                Such website visit information will not be disclosed or be
                personal identifiable to you and will not contain any personal
                specific information.
              </p>
            </p>
          </p>
          <li className="text-lg font-medium ">
            Cookies Setting and Refusal of Cookies
          </li>
          <p className="py-2 indent-12">
            You can choose to refuse Cookies by setting your browser or your
            privacy in order to restrict the storage of data through Cookies in
            the future (you can inquire more information on Cookies setting and
            Cookies restriction at AboutCookies.org).
            <p>
              However, some service on AOT’s website requires Cookies. If you
              set your browser or your privacy by refusing or restricting all
              Cookies, it may cause you inconvenience in accessing AOT’s website
              and limiting website usage.
            </p>
          </p>
        </div>
      </ul>
    </div>
  );
};

export default CookiePolicyPage;
