import React from "react";
import { FirstStepper, NextStepper } from "../Controls/StepperAOT";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RENEW_STEP } from "./RenewMember";

const RenewStepper = ({ sxa, step }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ width: "100%", ...sxa }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <FirstStepper
            label={`1 ${t("Enter Renew Membership Information")}`}
            sxbox={{
              background:
              step >= 1
                  ? "transparent linear-gradient(180deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box"
                  : "#B1BCC4 0% 0% no-repeat padding-box",
              borderColor: step === RENEW_STEP.FORM ? "#3D9AEE" : "#B3BBC2",
            }}
            sximg={{ display: "none" }}
          />
          <NextStepper
            label={`2 ${t("Payment Receipt")}`}
            sxbox={{
              background:
              step >= 2
                  ? "transparent linear-gradient(180deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box"
                  : "#B1BCC4 0% 0% no-repeat padding-box",
              borderColor: step === RENEW_STEP.WAIT_PAYMENT ? "#3D9AEE" : "#B1BCC4",
            }}
            sxline={{ borderColor: step == RENEW_STEP.WAIT_PAYMENT ? "#3D9AEE" : "#B3BBC2" }}
            sximg={{ display: "none" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default RenewStepper;
