import React, { useMemo, useState } from "react";
import PageContent from "../../layouts/PageContent";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import MyTicketTable from "../../components/MyTicket/MyTicketTable";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import TicketService from "../../services/ticket-service";
import { SelectAOT } from "../../components/Controls";
import { Breadcrumbs, Link, Typography } from "@mui/material";

const ticketTypeOptions = [
  {
    id: 1,
    display: "ทั้งหมด",
  },
  {
    id: 2,
    display: "บัตรจองที่จอดรถ",
  },
  {
    id: 3,
    display: "บัตรจอดรถทั่วไป",
  },
];

const MyTicketPage = () => {
  // const { t } = useTranslation();
  const [ticketTypeFilter, setTicketTypeFilter] = useState(
    ticketTypeOptions[0].id
  );
  const { profile } = useSelector((state) => state.auth);

  const { data: myTicketData, isLoading } = useQuery({
    queryKey: ["GET_MY_TICKET", profile],
    queryFn: () => TicketService.getMyTicket(profile?.userMenu?.userid),
    enabled: profile !== undefined,
    select: (data) => data?.data,
  });
  const myTicketDataFilter = useMemo(() => {
    return myTicketData?.data?.TbMyTicket?.filter((item) => {
      if (ticketTypeFilter === 2) {
        return item.tickettype === "reserve";
      } else if (ticketTypeFilter === 3) {
        return item.tickettype === "normal";
      }
      return item;
    });
  }, [myTicketData?.data, ticketTypeFilter]);

  return (
    <PageContent
      icon={<StyleOutlinedIcon fontSize="large" htmlColor="#245AB1" />}
      title="บัตรจอดรถของฉัน"
      breadcrumb={
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            หน้าหลัก
          </Link>
          <Typography color="#245AB1">บัตรจอดรถของฉัน</Typography>
        </Breadcrumbs>
      }
    >
      <div className="w-full space-y-4">
        <SelectAOT
          size="small"
          sxa={{ width: 200 }}
          label="ประเภทบัตรจอดรถ"
          options={ticketTypeOptions}
          onChange={(e) => {
            setTicketTypeFilter(e.target.value);
          }}
          value={ticketTypeFilter}
        />

        <div className="space-y-4 w-full">
          <p className="text-lg font-medium">ราบการบัตรจอดรถทั้งหมดของฉัน</p>
          <MyTicketTable data={myTicketDataFilter} loading={isLoading} />
        </div>
      </div>
    </PageContent>
  );
};

export default MyTicketPage;
