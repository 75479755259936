import { Box } from "@mui/material";
import React from "react";
import InputTextFieldAOT from "./InputTextFieldAOT";
import AutocompletePhone from "./AutocompletePhone";
import country from "../../json/country";
import { useTranslation } from "react-i18next";

const InputTelAutoCompleteAOT = ({
  width,
  sxa,
  name,
  handleChange,
  value,
  disabled,
}) => {
  const {t} = useTranslation()
  const [countryValue, setCountryValue] = React.useState({
    code: "TH",
    label: "Thailand",
    phone: "66",
  });
  const handleChangePhoneNumber = (e) => {
    const phone = e.target.value;
    const formattedPhone = `+${countryValue.phone} ${
      phone.startsWith("0") ? phone.slice(1) : ""
    }`;
    handleChange(phone);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "transparent",
        width,
        borderRadius: "10px",
        border: "2px solid #3D9AEE",
        ...sxa,
        // height: "60px",
        overflow: "hidden"
      }}
    >
      {/* <AutocompletePhone
        sxa={{
          border: "none",
          // borderRight: "2px solid #3D9AEE",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
        handleChange={setCountryValue}
        options={country.countryList}
        value={countryValue}
        disabled={true}
      /> */}
      <InputTextFieldAOT
        sxa={{
          width: "100%",
          height: '100%',
          border: 'none'
        }}
        label={t('Phone No')}
        name={name}
        handleChange={handleChangePhoneNumber}
        disabled={!countryValue || disabled}
        val={value}
        required
      />
    </Box>
  );
};

export default InputTelAutoCompleteAOT;
