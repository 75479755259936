import React, { useState } from "react";
import { DatePickerAOT } from "../../components/Controls";
import CheckingTable from "../../components/Checking/CheckingTable";
import { useQuery } from "@tanstack/react-query";
import MemberService from "../../services/member-service";
import { useSelector } from "react-redux";
import CheckingDetail from "../../components/Checking/CheckingDetail";
import MasterService from "../../services/master-service";
import { useCallback } from "react";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../constants";
import { useTranslation } from "react-i18next";

const Checking = () => {
  const { t } = useTranslation();
  const { memberInfo } = useSelector((state) => state.auth);
  const { pmlsId } = useSelector((state) => state.airport);
  const [selectedProcessHistory, setSelectedProcessHistory] = useState();
  // 1 = list, 2 = detail by processid
  const [statePage, setStatePage] = useState(1);
  const [filterValue, setFilterValue] = useState({
    startdt: "",
    enddt: "",
  });

  const memberProcessHistory = useQuery({
    queryKey: ["GET_MEMBER_PROCESS_HISTORY", filterValue],
    queryFn: () =>
    MemberService.mappMemberHistoryProcessFilter({
        pmlsid: pmlsId,
        memberid: memberInfo?.pmlsmemberid,
        ...filterValue,
      }),
    select: (data) => data?.data?.process,
    enabled: pmlsId !== undefined && memberInfo?.pmlsmemberid !== undefined,
  });

  const msProcessState = useQuery({
    queryKey: ["MS_PROCESS_STATE"],
    queryFn: () => MasterService.processState(),
    select: (data) => data?.data?.data?.allProcessState,
  });

  const findProcessStateNameByStateId = useCallback(
    (stateId) => {
      const find = msProcessState?.data?.find(
        (item) => item.stateid === stateId
      );
      return find?.statename || "";
    },
    [msProcessState?.data]
  );

  const onClickDetailHandler = (processid) => {
    const processHistoryByProcessId = memberProcessHistory?.data?.find(
      (process) => process.processid === processid
    );
    setStatePage(2);
    setSelectedProcessHistory(processHistoryByProcessId);
  };

  const onClickBackButton = () => {
    setStatePage(1);
  };

  return (
    <div className="w-full p-20 text-[#2F2E2F]">
      <div className="flex justify-between items-start">
        <div>
          <div className="inline-flex items-center mb-4">
            <div className="inline-flex gap-2 items-center">
              <ContentPasteSearchOutlinedIcon
                fontSize="large"
                htmlColor="#245AB1"
              />
              <p className="text-3xl text-[#2F2E2F] font-bold">
                {statePage === 1 ? t('Check Membership Status') : t('Request Information')}
              </p>
            </div>
          </div>
          {statePage === 1 && (
            <p className="font-medium text-base">
              {t("Please specify transaction date to find the request")}
            </p>
          )}
        </div>
        <div className="text-sm">
          {t("Home")} /
          <span
            className={`${statePage === 1 && "text-[#245AB1] font-semibold"} `}
          >
            {" "}
            {t("Check Membership Status")}
          </span>
          {statePage === 2 && (
            <span className="text-[#245AB1] font-semibold">
              {" "}
              / {t("Request Information")}
            </span>
          )}
        </div>
      </div>
      {statePage === 1 && (
        <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-5">
          <DatePickerAOT
            label={t("Start Date")}
            handleChange={(e) => {
              setFilterValue({
                ...filterValue,
                startdt: e !== null ? dayjs(e).format(DATE_FORMAT) : "",
              });
            }}
            sxa={{}}
            val={filterValue?.startdt}
          />
          <DatePickerAOT
            label={t('End Date')}
            handleChange={(e) => {
              setFilterValue({
                ...filterValue,
                enddt: e !== null ? dayjs(e).format(DATE_FORMAT) : "",
              });
            }}
            sxa={{}}
            val={filterValue?.enddt}
          />
        </div>
      )}
      <div>
        {statePage === 1 && (
          <CheckingTable
            data={memberProcessHistory?.data}
            loading={memberProcessHistory.isLoading}
            onClickDetailHandler={onClickDetailHandler}
            findProcessStateNameByStateId={findProcessStateNameByStateId}
          />
        )}
        {statePage === 2 && (
          <div >
            <CheckingDetail
              process={selectedProcessHistory}
              onClickBackButton={onClickBackButton}
              findProcessStateNameByStateId={findProcessStateNameByStateId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Checking;
