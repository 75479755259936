import instance from "../config/apiAxios";

const BASE_URL = "/subsystem/pmls/member";

//PMCS_TB_0381 : Get Member info by licenseplate and province from pmls
const getMemberInfoByLicensePlate = async (params) => {
  const response = await instance.get(BASE_URL + "/getbylicenseplate", {
    params,
  });
  return response?.data;
};

//PMCS_TB_0382 : Member renew by license plate at pmls
const renewUpdate = async (body) => {
  const response = await instance.post(BASE_URL + "/renewbylicenseplate", body);
  return response.data;
};

//PMCS_TB_0383 : Mapp create receipt for renew member by license plate of mapp pos
const createPaymentReceipt = async (body) => {
  const response = await instance.post(
    "/mapp/payment/online/renewbylicenseplate/request",
    body,
  );
  return response.data;
};

const createPaymentSaptransReceipt = async (body) => {
  const response = await instance.post(
    "/mapp/payment/memberorder/online/renewbylicenseplate/saptrans",
    body,
  );
  return response.data;
}

const getCaseMemberRenewByPmls = async (params) => {
  const response = await instance.get(BASE_URL + '/renew', { params })
  return response?.data
}

const RenewByLicensePlateService = {
  getMemberInfoByLicensePlate,
  renewUpdate,
  createPaymentReceipt,
  createPaymentSaptransReceipt,
  getCaseMemberRenewByPmls
};
export default RenewByLicensePlateService;
