import React, { useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import InboxService from "../../services/inbox-service";
import dayjs from "dayjs";
import "dayjs/locale/th";
import Pagination from "@mui/material/Pagination";

const BoxHeader = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const HeadersTop = styled(Box)(() => ({
  background: "#245AB1 0% 0% no-repeat padding-box",
  width: "100%",
  height: "40px",
  zIndex: "0",
  transform: "matrix(-1, 0, 0, -1, 0, 0)",
}));

const TextNotifiedMenu = styled(Typography)(() => ({
  font: "normal normal normal 18px/27px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
}));

const TextNotifiedMenuBl = styled(Typography)(() => ({
  font: "normal normal bold 18px/27px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#245AB1",
}));

const Notified = () => {
  const { t } = useTranslation();
  const { profile } = useSelector((select) => select.auth);
  const userid = profile?.userMenu?.userid;

  const { data } = useQuery({
    queryKey: ["GET_PREVIEW_INBOX", userid],
    queryFn: () => {
      return InboxService.getPreviewInbox(userid);
    },
    enabled: userid !== undefined,
    select: (data) => data?.data,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate the index of the first and last item of the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  // Get the items for the current page
  const currentItems = data?.last10msg?.slice(firstIndex, lastIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <BoxHeader>
        <div
          className="w-full h-80 bg-cover bg-center"
          style={{
            backgroundImage: 'url("/images/notified/img_bgnoti.png")',
          }}
        ></div>
      </BoxHeader>
      <div className="px-24 py-20">
        <div className="flex justify-between items-center">
          <p className="text-2xl md:text-3xl font-bold">การแจ้งเตือน</p>
          <Box sx={{ display: "flex" }}>
            <TextNotifiedMenu>{t("Home")} / </TextNotifiedMenu>
            <TextNotifiedMenuBl>การแจ้งเตือน</TextNotifiedMenuBl>
          </Box>
        </div>
        <div className="relative mt-24 w-full md:w-[80%] mx-auto">
          <div className="absolute right-0 -top-16 md:-top-24 z-20  ">
            <img
              src="/images/notified/ico_notitop.svg"
              className="w-[190px] md:w-[250px] h-auto"
            />
          </div>
          <Box sx={{ border: "1px solid #B3BBC2" }}>
            <HeadersTop></HeadersTop>
            <div className="px-14 md:px-20 py-7">
              <p className="mt-16 md:mt-0 text-2xl font-semibold">รายการแจ้งเตือน</p>
              {/* Render the notification items for the current page */}
              <div className="py-14 flex flex-col gap-y-6">
                {currentItems?.map((item, index) => (
                  <NotificationItem
                    header={item?.msg}
                    desc={item?.msgdetail}
                    date={item?.createdt}
                    key={item?.msgid}
                    isLastItem={currentItems.length - 1 === index}
                  />
                ))}
              </div>

              {/* Add the Pagination component */}
              {data?.last10msg.length > itemsPerPage && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <Pagination
                    count={Math.ceil(data.last10msg.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </Box>
              )}
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};

const NotificationItem = ({ date, header, desc, icon, isLastItem }) => {
  const formattedDate = dayjs(date).locale("th").format("DD MMM YY");
  return (
    <div
      className={`w-full flex items-start gap-x-6 md:gap-x-14 ${
        !isLastItem ? "border-b border-solid border-gray-300" : ""
      } pb-6`}
    >
      <img
        className="w-[50px] md:w-[70px] h-auto"
        src="/images/notified/ico_aot.svg"
      />
      <div className="overflow-hidden">
        <p className="text-[#898A8F] text-base md:text-lg">{formattedDate}</p>
        <p className="text-[#245AB1] font-semibold text-lg md:text-xl mt-1 line-clamp-2">
          {header}
        </p>
        <p className="text-base md:text-lg mt-1 line-clamp-3">{desc}</p>
      </div>
    </div>
  );
};

export default Notified;
