import { AppBar, Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const RegisterAppBar = styled((props) => (
  <AppBar position="static" {...props} />
))(({ theme }) => ({
  height: "12rem",
  justifyContent: "center",
  boxShadow: "none",
  background:
    "transparent linear-gradient(80deg, #2969BE 0%, #1F47A0 100%) 0% 0% no-repeat padding-box",
  [theme.breakpoints.down("lg")]: {
    //display: 'none'
  },
}));

const ImgLogo = styled((props) => <Box component="img" {...props} />)(
  ({ theme }) => ({
    width: "8rem",
    height: "4.5",
    [theme.breakpoints.down("lg")]: {
      //display: 'none'
    },
  })
);

const ImgCar = styled((props) => <Box component="img" {...props} />)(
  ({ theme }) => ({
    marginTop: "7rem",
    marginRight: "2rem",
    objectFit: "cover",
    zIndex: "3000",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  })
);

const BoxHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // paddingLeft: '9rem',
  [theme.breakpoints.down("lg")]: {
    // paddingLeft: '2rem'
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const AOTText = styled(Typography)(({ theme }) => ({
  font: "normal normal bold 30px/36px Origin",
  letterSpacing: "0px",
  color: "#FFFFFF",
  marginTop: "1rem",
  [theme.breakpoints.down("lg")]: {
    //display: 'none'
  },
}));

const RegisterHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <RegisterAppBar>
        <div className="container mx-auto px-10">
          <BoxHeader>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <ImgLogo src="/images/aot_logo_white.png" />
              <AOTText>{t("AOT SMART CAR PARK")}</AOTText>
            </Box>
            <ImgCar src="/images/auth/img_cartop.png" sx={{ width: "250px" }} />
          </BoxHeader>
        </div>
      </RegisterAppBar>
    </>
  );
};
export default RegisterHeader;
