import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";

const AutocompleteAOT = ({
  options = [],
  label,
  val,
  handleChange,
  sxa,
  disabled = false,
  required = false,
  size,
  fullWidth = false
}) => (
  <Autocomplete
    // id="country-select-demo"
    value={val}
    disabled={disabled}
    onChange={handleChange}
    sx={{
      width: "100%",
      backgroundColor: "#FFFFFF",
      borderRadius: "10px",
      border: "2px solid #3D9AEE",
      ...sxa,
    }}
    fullWidth={fullWidth}
    options={options}
    size={size}
    filterSelectedOptions
    autoHighlight
    isOptionEqualToValue={(option, value) => option?.id === value?.id}
    getOptionLabel={(option) => option?.display || ""}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        variant="filled"
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
        }}
        required={required}
        sx={{
          "& .MuiFilledInput-root": {
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
            },
          },
        }}
      />
    )}
  />
);

// Define PropTypes for clarity and maintainability
AutocompleteAOT.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired,
    })
  ),
  label: PropTypes.string,
  val: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  sxa: PropTypes.object,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default AutocompleteAOT;
