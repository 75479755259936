import { Typography, Box, styled } from "@mui/material";
import PropTypes from "prop-types";

const BoxMessErrS = styled(Box)(() => ({
  position: "relative",
  color: "red",
  marginRight: "auto",
  paddingLeft: "0.5rem",
}));

const BoxMessErr = ({message}) => {
  return (
    <BoxMessErrS>
      <Typography sx={{ position: "absolute", fontSize: 14 }}>
        {message ? message : null}
      </Typography>
    </BoxMessErrS>
  );
};

BoxMessErr.propTypes = {
  message: PropTypes.string | PropTypes.any,
};

export default BoxMessErr;
