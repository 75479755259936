import React from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../utils";

const AuthUserLayout = ({ children, contentCenter }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        "bg-cover bg-repeat bg-center min-h-screen flex flex-col items-center py-14",
        contentCenter ? "justify-center" : ""
      )}
      style={{
        backgroundImage: 'url("/images/auth/img_bgfooter.png")',
      }}
    >
      <img
        src="/images/aot_logo_white.png"
        alt="aot-logo"
        className="w-[150px] h-auto"
      />
      <h1 className="text-white font-bold text-2xl mt-6 shadow-md">
        {t("AOT SMART CAR PARK")}
      </h1>
      <div className="w-full px-2 lg:px-0">
        <div className="bg-white px-6 lg:px-10 mx-auto py-10 max-w-xl rounded-2xl flex items-center flex-col mt-6 ">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthUserLayout;
