//? FIXED FOR RENEW BY LICENSE PLATE
const NUMBER_OF_MONTH_RENEW_BY_LICENSE_PLATE = 1;

const getTotalPackagePriceByNumberOfMonth = (packages, numberOfMonth) => {
  if (packages?.length > 0) {
    const total = packages?.reduce((sum, item) => {
      return (
        sum + Number(item?.totalprice ? item?.totalprice * numberOfMonth : 0)
      );
    }, 0);
    return total;
  } else {
    return 0;
  }
};

export {
  NUMBER_OF_MONTH_RENEW_BY_LICENSE_PLATE,
  getTotalPackagePriceByNumberOfMonth,
};
