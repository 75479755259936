import { BrowserQRCodeReader } from "@zxing/library";
import { useEffect, useRef, useState } from "react";
import { FACING_MODE } from "../constants";
import { isMobile } from "react-device-detect";
import { toastErr } from "../utils";
const useScanner = () => {
  const webcamRef = useRef(null);
  const [showScanner, setShowScanner] = useState(false);
  const [facingMode, setFacingMode] = useState(
    isMobile ? FACING_MODE.ENVIRONMENT : FACING_MODE.USER
  );
  const [scannerValue, setScannerValue] = useState(undefined);

  
  const videoConstraints = {
    facingMode: facingMode,
  };

  const handleSwitchCamera = () => {
    if (facingMode === "user") {
      setFacingMode("environment");
    } else {
      setFacingMode("user");
    }
  };

  const handleScannerClose = () => {
    setShowScanner(false);
  };

  const handleScannerOpen = () => {
    setShowScanner(true)
  }

  useEffect(() => {
    const codeReader = new BrowserQRCodeReader();

    const scanQRCode = async () => {
      try {
        if (showScanner) {
          const videoElement = webcamRef.current.video;
          const result = await codeReader.decodeFromVideoElement(videoElement);

          // Handle the QR code result
          if (result?.text !== "") {
            setTimeout(() => {
              const data = result?.text;
              const sections = data.split("|");
              const desiredValue = sections[sections.length - 2];
              setScannerValue(desiredValue);
              setShowScanner(false);
            }, 1000);
          }
        }
      } catch (error) {
        toastErr("QR Code scan error, ", error);
      }
    };

    scanQRCode();

    return () => {
      codeReader.reset();
    };
  }, [showScanner]);

  return {
    handleScannerClose,
    handleSwitchCamera,
    handleScannerOpen,
    scannerValue,
    webcamRef,
    facingMode,
    showScanner,
    videoConstraints
  };
};

export default useScanner;
