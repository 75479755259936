import { Box, Button, Grid, styled } from "@mui/material";
import React from "react";
import InputTextFieldAOT from "../Controls/InputTextFieldAOT";
import NewSelectAOT from "../Controls/NewSelectAOT";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ButtonAction from "../Base/ButtonAction";
import AutocompleteAOT from "../Controls/AutoCompleteAOT";

const ReservedParking1 = ({
  formik,
  onClickCheckAvailableButton,
  provincesLicenseOptions,
  parking,
  parkingLoading,
  parkingZoneOption,
  parkingZoneLoading,
  parkingIsAvailable,
  checkParkingAvailableMutate,
  onClickBookingButton,
  activeStep,
  setActiveStep,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ marginBottom: 4 }}>
          <InputTextFieldAOT
            label={t("Airport")}
            disabled={true}
            val={t("Suvarnabhumi airport")}
          />
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{ marginBottom: 4 }}
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <NewSelectAOT
              name="parking"
              label={t("Parking Location")}
              fullWidth
              options={parking}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue("zone", null);
                setActiveStep(1);
              }}
              value={formik.values.parking}
              disabled={parkingLoading}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <NewSelectAOT
              name="zone"
              label={t("Parking Zone")}
              options={parkingZoneOption}
              onChange={(e) => {
                formik.handleChange(e);
                setActiveStep(1);
              }}
              value={formik.values.zone}
              disabled={parkingZoneLoading || parkingZoneOption?.length == 0}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            {activeStep == 1 ? (
              <div className="col-span-1">
                <ButtonAction
                  onClick={onClickCheckAvailableButton}
                  disabled={!formik.values.zone}
                  variant="primary"
                >
                  {t("Check Status")}
                </ButtonAction>
              </div>
            ) : checkParkingAvailableMutate?.data !== undefined ? (
              <p
                className={`text-xl ml-2 ${
                  parkingIsAvailable ? "text-green-500" : "text-red-500"
                }  font-bold`}
              >
                {parkingIsAvailable ? "Available" : "Not Available"}
              </p>
            ) : null}
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginBottom: 4 }}>
          <Grid item xs={6}>
            <InputTextFieldAOT
              label={t("License Plate")}
              name="licenseplate"
              disabled={!parkingIsAvailable || activeStep !== 2}
              val={formik?.values?.licenseplate}
              handleChange={(e) => {
                formik.setFieldValue("licenseplate", e.target.value);
              }}
              required
            />
            <span className="text-red-500 text-xs h-8">
              {formik.errors.licenseplate && formik.touched
                ? formik.errors.licenseplate
                : null}
            </span>
          </Grid>
          <Grid item xs={6}>
            <AutocompleteAOT
              required
              label={t("Province")}
              name="licenseprovince"
              options={provincesLicenseOptions}
              value={formik?.values?.licenseprovince}
              handleChange={(e, newValue) => {
                formik.setFieldValue("licenseprovince", newValue);
              }}
              disabled={!parkingIsAvailable || activeStep !== 2}
            />
            <span className="text-red-500 text-xs h-8">
              {formik.errors.licenseprovince && formik.touched
                ? formik.errors.licenseprovince
                : null}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <div className="mt-6">
        <ButtonAction
          onClick={onClickBookingButton}
          disabled={
            !formik.values.licenseplate ||
            !formik.values.licenseprovince?.id ||
            activeStep !== 2
          }
        >
          {t("Reserve a Parking Lot")}
        </ButtonAction>
      </div>
    </>
  );
};

export default ReservedParking1;
