import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
const BoxImgWelcome = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  position: "relative",
}));

const BoxTextWelcome = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "1rem",
  marginBottom: "2rem",
}));

const BoxTexDetail = styled(Box)(({ theme }) => ({
  width: "70%",
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "0.5rem",
  paddingTop: "0.5rem",
  borderBottom: "1px solid #B3BBC2",

}));


const TextWelcome = styled(Typography)(() => ({
  font: "normal normal bold 24px/40px Noto Sans Thai",
  letterSpacing: 0,
  color: "#3D9AEE",
}));

const TextSend = styled(Typography)(() => ({
  font: "normal normal normal 18px/40px Noto Sans Thai",
  letterSpacing: 0,
  color: "#1A191A",
}));

const TextGreyBold = styled(Typography)(() => ({
  font: "normal normal normal 16px/40px Noto Sans Thai",
  letterSpacing: 0,
  color: "#2F2E2F",
}));



const RenewSummary = ({ responseData, previewBoxData }) => {
  const { t } = useTranslation();

  return (
    <div className="py-15 w-full">
      <BoxImgWelcome>
        <img
          src="/images/prepaid/img_complate.png"
          alt=""
          className="w-[200px] h-auto"
        />
      </BoxImgWelcome>
      <BoxTextWelcome>
        <TextWelcome>สำเร็จ</TextWelcome>
        <TextSend>
          {responseData?.createdt
            ? dayjs(responseData?.createdt)
                .locale("th")
                .format("D MMM YYYY H:mm")
            : "-"}
        </TextSend>
      </BoxTextWelcome>

      <div className="w-[350px] md:w-[550px] border border-solid border-[#B3BBC2] mx-auto flex items-center flex-col">
        <BoxTexDetail>
          <TextGreyBold>{t("Receipt No")}</TextGreyBold>
          <TextGreyBold>{responseData?.receiptno}</TextGreyBold>
        </BoxTexDetail>
        <BoxTexDetail>
          <TextGreyBold>{t("Airport")}</TextGreyBold>
          <TextGreyBold>{previewBoxData?.airport}</TextGreyBold>
        </BoxTexDetail>
        <BoxTexDetail>
          <TextGreyBold>{t("License Plate")}</TextGreyBold>
          <TextGreyBold>{previewBoxData?.licenseplate}</TextGreyBold>
        </BoxTexDetail>
        <BoxTexDetail>
          <TextGreyBold>{t("Renew Duration")}</TextGreyBold>
          <TextGreyBold>
            {previewBoxData?.numberOfMonths} {t("Month")}
          </TextGreyBold>
        </BoxTexDetail>
        <BoxTexDetail>
          <TextGreyBold>{t("Apply Start Date")}</TextGreyBold>
          <TextGreyBold>{previewBoxData?.startdate}</TextGreyBold>
        </BoxTexDetail>
        <BoxTexDetail>
          <TextGreyBold>{t("Expired Date")}</TextGreyBold>
          <TextGreyBold>{previewBoxData?.enddate}</TextGreyBold>
        </BoxTexDetail>
        <BoxTexDetail>
          <TextGreyBold>{t("Payment Method")}</TextGreyBold>
          <TextGreyBold>{previewBoxData?.paymentType}</TextGreyBold>
        </BoxTexDetail>
        <BoxTexDetail>
          <TextGreyBold>{t("Transfer Amount")}</TextGreyBold>
          <TextGreyBold>{previewBoxData?.totalPrice}</TextGreyBold>
        </BoxTexDetail>
        <BoxTexDetail sx={{ borderBottom: "none" }}>
          <TextGreyBold>{t("Total")}</TextGreyBold>
          <TextGreyBold>{previewBoxData?.totalPrice}</TextGreyBold>
        </BoxTexDetail>{" "}
      </div>
    </div>
  );
};

export default RenewSummary;
