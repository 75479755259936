import instance from "../config/apiAxios";

const BASE_URL = "/mapp/account";

//PMCS_SYS_0207 : Mapp update user notification
const updateUserNotification = async (body) => {
  const response = await instance.post(BASE_URL + "/updatenotification", body);
  return response.data;
};

const getMyProfile = async () => {
  const response = await instance.post(BASE_URL + "/myprofile");
  if (response.data && response.data.ouIsComplete === 0) {
    throw new Error(response.data.ouMessage);
  }
  return response.data;
};

const AccountService = {
  updateUserNotification,
  getMyProfile,
};
export default AccountService;
