import { createTheme } from '@mui/material'

export const themeApp = createTheme({
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: { color: 'red' }
            }
        }
    },
    typography: {
        fontFamily: ['Noto Sans Thai', 'Helvetica Neue', 'sans-serif'].join(',')
    },
    palette: {
        primary: {
            main: '#3859CB'
        },
    },
})
