import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CONTENT_TYPE_CODE } from "../../constants";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./../LoadingSpinner";

const BoxOuter = styled(Box)(({ theme }) => ({
  paddingTop: "2rem",
  paddingBottom: "2rem",
}));

const TitleText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 24px/54px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    font: " normal normal 600 22px/33px Noto Sans Thai",
  },
}));

const RightTitleText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#3D9AEE",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 14px/21px Noto Sans Thai",
  },
}));

const BoxCard = styled(Box)(({ theme }) => ({
  display: "flex",
  // justifyContent: "space-between",
  gap: 30,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const BoxItem = styled(Box)(({ theme }) => ({
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "12px",
  width: "20rem",
  height: "24rem",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    width: "20rem",
    marginRight: "0.5rem",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
}));

const ImgBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "50%",
  objectFit: "cover", // แก้เป็น fill ถ้าให้ยืดเต็ม
  borderRadius: "12px 12px 0px 0px",
}));

const findPathFormContentTypeCode = (code) => {
  if (code === CONTENT_TYPE_CODE.INFO) {
    return "information";
  } else if (code === CONTENT_TYPE_CODE.NEWS) {
    return "aot-news";
  } else if (code === CONTENT_TYPE_CODE.PROMO) {
    return "campaign";
  }
};

const BoxNews = ({ type, header, data, hideSeeAllContent, isLoading }) => {
  const { memberInfo } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="mx-auto container px-10">
      <BoxOuter>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <TitleText>{header}</TitleText>
          </Box>
          {!hideSeeAllContent && (
            <Box
              sx={{
                display: "flex",
                flex: "1 1 auto",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
              onClick={() => {
                const path = `/${
                  memberInfo ? "pages" : "home"
                }/content/${findPathFormContentTypeCode(type)}`;
                navigate(path);
              }}
            >
              <RightTitleText>{t("View All")}</RightTitleText>
            </Box>
          )}
        </Box>
        <BoxCard>
          {isLoading ? (
            <div className="w-full flex items-center justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            data &&
            data?.length > 0 &&
            data?.map((item, index) => (
              <BoxItem key={index}>
                <ImgBox component="img" src={item.previewimage} />
                <div
                  dangerouslySetInnerHTML={{ __html: item.previewcontenttext }}
                  className="line-clamp-3 px-4 pt-4"
                />
                {data?.length > 0 && (
                  <div
                    className="absolute bottom-5 left-5 text-blue-600 font-semibold cursor-pointer text-sm hover:text-blue-400"
                    onClick={() => {
                      const path = `/${memberInfo ? "pages" : "home"}/content/${
                        item.contentid
                      }`;
                      navigate(path);
                    }}
                  >
                    {t("Read More")} &gt;
                  </div>
                )}
              </BoxItem>
            ))
          )}
        </BoxCard>
      </BoxOuter>
    </div>
  );
};

export default BoxNews;
