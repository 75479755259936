import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import airportReducer from "../features/airportSlice";
import masterReducer from "../features/masterDataSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    airport: airportReducer,
    masterData: masterReducer,
  },
});
