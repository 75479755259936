import React from "react";
import ChoosePaymentMethodStep from "../../Payment/ChoosePaymentMethodStep";
import ButtonAction from "../../Base/ButtonAction";
import { useTranslation } from "react-i18next";

const ConfirmPayment = ({
  method,
  paymentDetails,
  onChangePaymentMethod,
  onClickConfirmPaymentBtn,
  onClickBackBtn,
  totalPrice,
  previewBoxData
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <ChoosePaymentMethodStep
        airportText={previewBoxData?.airport}
        boxTitleText={t("Renew Membership Payment Summary")}
        details={paymentDetails}
        amount={totalPrice()}
        method={method}
        onChangePaymentMethod={onChangePaymentMethod}
      />
      <div className="flex flex-col md:flex-row gap-2 mt-10">
        <ButtonAction onClick={onClickBackBtn} variant="secondary">
          {t("Cancel")}
        </ButtonAction>
        <ButtonAction onClick={onClickConfirmPaymentBtn}>
          {t("Confirm Payment")}
        </ButtonAction>
      </div>
    </div>
  );
};

export default ConfirmPayment;
