import React from "react";
import { useTranslation } from "react-i18next";
import ButtonAction from "../Base/ButtonAction";
import { useNavigate } from "react-router-dom";
import ErrorResultWithText from "../Result/ErrorResultWithText";

const PaymentCancelPendingSummary = ({
  onClickMakeNewTransactionBtnHandler,
  isQRTicket,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-full">
      <div className="w-full">
        <ErrorResultWithText
          text={
            <p className="text-blue-800 text-xl md:text-2xl font-bold">
              {t(
                "The pending transaction was cancelled. Please make a new transaction"
              )}
            </p>
          }
        />
      </div>
      <div className="flex flex-col gap-4 mt-4">
        <ButtonAction
          size="small"
          onClick={onClickMakeNewTransactionBtnHandler}
        >
          {t("Make a new transaction")}
        </ButtonAction>
        {!isQRTicket && (
          <ButtonAction
            size="small"
            onClick={() => {
              navigate(`/`);
            }}
          >
            {t("Back to Home")}
          </ButtonAction>
        )}
      </div>
    </div>
  );
};

export default PaymentCancelPendingSummary;
