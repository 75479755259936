import React, { useState } from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DoneIcon from "@mui/icons-material/Done";
import BoxSemiCircle from "../BoxSemiCircle";
import RenewSummaryWait from "./RenewSummaryWait";
import { formattedDateByLocale } from "../../utils/format";
import { formattedPrice } from "../../utils/format";
import { PMLS_ID_TO_AIRPORT_TEXT } from "../../constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const HeaderText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 24px/45px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  paddingBottom: "2rem",
  // paddingLeft: "0.5rem",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 20px/45px Noto Sans Thai",
  },
}));

const TitleText = styled(Typography)(() => ({
  font: "normal normal 600 20px/41px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
}));

const SelectButton = styled(Button)(() => ({
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  border: "3px solid #3D9AEE",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  marginTop: "1rem",
}));

const PaymentBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  padding: "0.5rem",
}));

const ButtonText = styled(Typography)(() => ({
  font: "normal normal 600 18px/41px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
}));

const BoxImg = styled((props) => <Box component="img" {...props} />)(() => ({
  width: "35px",
  height: "35px",
  marginRight: "2rem",
}));

const BoxData = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const BoxLeft = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // width: "45%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const BoxRight = styled(Box)(({ theme }) => ({
  display: "flex",
  // width: "45%",
  border: "1px solid #B3BBC2",
  borderTop: "15px solid #3D9AEE",
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginTop: "2rem",
  },
}));

const BoxPay = styled(Box)(() => ({
  padding: "",
  width: "100%",
}));

const BoxButton = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  padding: "3.988rem 0px 0px 0px",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const ButtonCancel = styled(Button)(({ theme }) => ({
  width: "42rem",
  height: "4rem",
  background: "#E9F0F5 0% 0% no-repeat padding-box",
  borderRadius: "10px",
  font: "normal normal normal 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  marginRight: "2.568rem",
  "&:hover": {
    background: "#E9F0F5 0% 0% no-repeat padding-box",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginBottom: "1rem",
    marginRight: "1rem",
  },
}));

const ButtonSave = styled(Button)(({ theme }) => ({
  width: "42rem",
  height: "4rem",
  font: "normal normal normal 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  background:
    "transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box",
  border: "2px solid #3D9AEE",
  borderRadius: "10px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const RenewStep2 = ({
  activeStep,
  setActiveStep,
  selectedVehicle,
  dateDuration,
  totalPrice,
  onClickPaymentBtn,
  paymentData,
  responsePaymentData,
  numberOfMonths,
  pmlsId,
}) => {
  const [payment, SetPayment] = useState("qr");
  const { lang } = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const handleNext = () => {
    onClickPaymentBtn();
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const previewBoxData = {
    airport: PMLS_ID_TO_AIRPORT_TEXT[pmlsId][lang],
    licenseplate: selectedVehicle?.licenseplate,
    numberOfMonths: numberOfMonths,
    startdate: formattedDateByLocale(dateDuration?.startDate),
    enddate: formattedDateByLocale(dateDuration?.endDate),
    paymentType: "QR พร้อมเพย์", //? fixed
    totalPrice: formattedPrice(totalPrice),
  };

  return (
    <>
      {activeStep === 2 ? (
        <Box>
          <BoxPay>
            <HeaderText>{t("Payment Method")}</HeaderText>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              <div className="col-span-1 ">
                <TitleText>{t("QR PromptPay")}</TitleText>
                <SelectButton
                  sx={{
                    border:
                      payment === "qr"
                        ? "3px solid #3D9AEE"
                        : "2px solid #B3BBC2",
                  }}
                  onClick={() => {
                    SetPayment(payment === "qr" ? "" : "qr");
                  }}
                >
                  <PaymentBox>
                    <ButtonText>{t("Transfer Payment via QR Code")}</ButtonText>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: payment === "qr" ? "50%" : "0",
                        backgroundColor:
                          payment === "qr" ? "#1F47A0" : "transparent",
                      }}
                    >
                      {payment === "qr" ? (
                        // <img src='/images/feeparking/ico_checkcarpark.svg' alt='' />
                        <DoneIcon
                          sx={{ color: "white", width: "36px", height: "36px" }}
                        />
                      ) : (
                        <ArrowForwardIosIcon />
                      )}
                    </Box>
                  </PaymentBox>
                </SelectButton>
              </div>

              <div className="col-span-1 border-[1px] border-[#B3BBC2] border-t-[15px] border-t-[#3D9AEE] relative">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "4rem",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <BoxImg
                      component="img"
                      src="/images/feeparking/ico_name_carpark.svg"
                      alt=""
                    />
                    <Typography
                      sx={{
                        font: "normal normal bold 22px/37px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#245AB1",
                      }}
                    >
                      {t("Renew Membership Payment Summary")}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", marginTop: "1rem" }}>
                    <BoxImg src="/images/feeparking/ico_plane.svg" alt="" />
                    <Typography
                      sx={{
                        font: "normal normal normal 20px/33px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#2F2E2F",
                      }}
                    >
                      {previewBoxData?.airport}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      paddingBottom: "2rem",
                      marginTop: "1rem",
                      borderBottom: "1px solid #B3BBC2",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <BoxImg src="/images/feeparking/ico_car_1.svg" />
                      <Typography
                        sx={{
                          font: "normal normal normal 20px/32px Noto Sans Thai",
                          letterSpacing: "0px",
                          color: "#2F2E2F",
                        }}
                      >
                        {t("License Plate")}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          font: "normal normal normal 20px/32px Noto Sans Thai",
                          letterSpacing: "0px",
                          color: "#898A8F",
                        }}
                      >
                        {previewBoxData?.licenseplate}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      paddingTop: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    <BoxImg src="/images/feeparking/ico_package_1.svg" alt="" />
                    <Typography
                      sx={{
                        font: "normal normal 600 20px/25px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#2F2E2F",
                      }}
                    >
                      {t("Renew Duration")} {previewBoxData?.numberOfMonths}{" "}
                      {t("Month")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        font: "normal normal normal 20px/38px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#2F2E2F",
                      }}
                    >
                      {t("Apply Start Date")}
                    </Typography>
                    <Typography
                      sx={{
                        font: "normal normal normal 20px/38px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#898A8F",
                      }}
                    >
                      {previewBoxData?.startdate}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                      paddingBottom: "1rem",
                      borderBottom: "1px solid #B3BBC2",
                    }}
                  >
                    <Typography
                      sx={{
                        font: "normal normal normal 20px/38px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#2F2E2F",
                      }}
                    >
                      {t("Expired Date")}
                    </Typography>
                    <Typography
                      sx={{
                        font: "normal normal normal 20px/38px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#898A8F",
                      }}
                    >
                      {previewBoxData?.enddate}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                      paddingBottom: "1rem",
                      borderBottom: "1px solid #B3BBC2",
                    }}
                  >
                    <Typography
                      sx={{
                        font: "normal normal normal 20px/38px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#2F2E2F",
                      }}
                    >
                      {t("Payment Method")}
                    </Typography>
                    <Typography
                      sx={{
                        font: "normal normal normal 20px/38px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#898A8F",
                      }}
                    >
                      {t("QR PromptPay")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                      paddingBottom: "2rem",
                    }}
                  >
                    <Typography
                      sx={{
                        font: "normal normal normal 20px/38px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#2F2E2F",
                      }}
                    >
                      {t("Amount")}
                    </Typography>
                    <Typography
                      sx={{
                        font: "normal normal normal 20px/38px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#898A8F",
                      }}
                    >
                      {previewBoxData?.totalPrice}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                      paddingBottom: "3rem",
                    }}
                  >
                    <Typography
                      sx={{
                        font: "normal normal bold 24px/29px Noto Sans Thai",
                        letterSpacing: "0px",
                        color: "#2F2E2F",
                      }}
                    >
                      {t("Total Amount")}
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          font: "normal normal normal 24px/29px Noto Sans Thai",
                          letterSpacing: "0px",
                          color: "#FC3838",
                          marginRight: "1rem",
                        }}
                      ></Typography>
                      <Typography
                        sx={{
                          font: "normal normal bold 26px/29px Noto Sans Thai",
                          letterSpacing: "0px",
                          color: "#FC3838",
                        }}
                      >
                        {previewBoxData?.totalPrice}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <BoxSemiCircle />
              </div>
            </div>
          </BoxPay>
          <BoxButton>
            <ButtonCancel onClick={handleBack}>{t("Cancel")}</ButtonCancel>
            <ButtonSave onClick={handleNext}>{t("Confirm Payment")}</ButtonSave>
          </BoxButton>
        </Box>
      ) : (
        <RenewSummaryWait
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          paymentData={paymentData}
          responsePaymentData={responsePaymentData}
          previewBoxData={previewBoxData} //use in success status show receipt
        />
      )}
    </>
  );
};

export default RenewStep2;
