const ONLINE_PAYMENT_STATUS = {
  100: {
    statuscode: "100",
    name: "100",
    description: "QR Request Success",
  },
  101: {
    statuscode: "101",
    name: "101",
    description: "QR Request Error",
  },
  200: {
    statuscode: "200",
    name: "200",
    description: "Payment Success",
  },
  201: {
    statuscode: "201",
    name: "201",
    description: "Payment Error",
  },
  202: {
    statuscode: "202",
    name: "202",
    description: "Payment Timeout",
  },
  203: {
    statuscode: "203",
    name: "203",
    description: "Transaction Not Found",
  },
};


export default ONLINE_PAYMENT_STATUS