import instance from "../config/apiAxios";

const BASE_URL = "/mapp/ticket";

const TicketService = {
  //PMCS_TB_0179 : Insert user ticket
  addTicket: (body) => instance.post(BASE_URL + "/add", body),

  //PMCS_TB_0177 : Get ticket by userid
  getMyTicket: (id) =>
    instance.get(BASE_URL + "/getmyticket", {
      params: {
        userid: id,
      },
    }),

  //PMCS_TB_0178 : Get ticket by ticketno
  getMyTicketDetailByTicketNo: (ticketno) =>
    instance.get(BASE_URL + "/get", { params: ticketno }),

  //PMCS_TB_0352 : Delete user ticket
  deleteTicket: (body) => instance.post(BASE_URL + "/delete", body),

  //PMCS_TB_0204 : Mapp get gate ticket url
  getTicketQRCode: (body) =>
    instance.post("/mapp/gate/ticket/getticketurl", body),
};

export default TicketService;
