const LocalStorageKey = {
  PMSUSER: "pmsuser",
  ACCESS_TOKEN: "accesstoken",
  REFRESH_TOKEN: "refreshtoken",
  PMLSID: 'pmlsid'
};

const getItemFormLocalStorageByKey = (key) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

const setItemLocalStorage = (key, data) => {
  localStorage.setItem(key, data);
};

const getTokenFromStorage = () => {
  const data = getItemFormLocalStorageByKey(LocalStorageKey.PMSUSER);
  return data ? data.accesstoken : null;
};

const getRefreshTokenFromStorage = () => {
  const data = getItemFormLocalStorageByKey(LocalStorageKey.PMSUSER);
  return data ? data.refreshtoken : null;
};

const getPmlsIdFromStorage = () => {
  return getItemFormLocalStorageByKey(LocalStorageKey.PMLSID);
}

export {
  LocalStorageKey,
  getTokenFromStorage,
  getRefreshTokenFromStorage,
  getItemFormLocalStorageByKey,
  setItemLocalStorage,
  getPmlsIdFromStorage
};
