import { Checkbox, styled } from "@mui/material";
import React from "react";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const StyledCheckbox = styled((props) => {
  return (
    <Checkbox
      icon={
        <CircleOutlinedIcon
          sx={{ color: "#3C5AAD", width: "30px", height: "30px" }}
        />
      }
      checkedIcon={<CheckCircleIcon sx={{ width: "30px", height: "30px" }} />}
      {...props}
    
    />
  );
})(({ theme }) => ({}));

const CheckBoxAOT = (props) => {
  return <StyledCheckbox {...props} />;
};

export default CheckBoxAOT;
