import { classNames } from "../../utils";

export const TitleWithValue = ({
  title,
  value,
  titleStyles,
  valueStyles,
  boxStyles,
}) => {
  return (
    <div
      className={classNames("items-center flex justify-between", boxStyles)}
    >
      <p className={classNames("text-sm lg:text-base text-left", titleStyles)}>
        {title}
      </p>
      <div
        className={classNames(
          "text-sm lg:text-base text-right float-right",
          valueStyles
        )}
      >
        {value}
      </div>
    </div>
  );
};
