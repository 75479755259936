// masterDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  airports: [],
  pmls: [],
  membertype: [],
  nationality: [],
};

const masterDataSlice = createSlice({
  name: "masterData",
  initialState,
  reducers: {
    setMasterData: (state, action) => {
      return { ...state, ...action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const { setMasterData, setLoading } = masterDataSlice.actions;
export default masterDataSlice.reducer;
