import instance from "../config/apiAxios";

const BASE_PATH = "/mapp/member";

// PMCS_TB_0163 : Mapp get member info
const getMMapMemberInfo = async (params) => {
  const response = await instance.get(`${BASE_PATH}/info`, {
    params,
  });
  return response.data;
};

const mappMemberChangeVehicle = async (body) => {
  const response = await instance.post(
    BASE_PATH + "/changevehicle/update",
    body
  );
  return response.data;
};

// PMCS_TB_0166 : Mapp get member process history
const mappMemberHistoryProcess = async (params) => {
  const response = await instance.get(BASE_PATH + "/history/process", {
    params,
  });
  return response.data;
};

// PMCS_TB_0210 : Mapp filter member process history
const mappMemberHistoryProcessFilter = async (params) => {
  const response = await instance.get(BASE_PATH + "/history/process/filter", {
    params,
  });
  return response.data;
};

// PMCS_TB_0212 : Mapp Get member receipt history
const mappMemberHistoryReceiptFilter = async (params) => {
  const response = await instance.get(BASE_PATH + "/history/receipt/filter", {
    params,
  });
  return response.data;
};

//PMCS_TB_0209 : Mapp filter member entryexit history
const mmapMemberHistoryEntryExitFilter = async (params) => {
  const response = await instance.get(BASE_PATH + "/history/entryexit/filter", {
    params,
  });
  return response.data;
};

//PMCS_TB_0169 : Mapp get member package by memberid
const mmapMemberPackage = async (params) => {
  const response = await instance.get(BASE_PATH + "/package", { params });
  return response.data;
};

//PMCS_TB_0157 : Mapp create renew member request at pmls
const mmapMemberRenewUpdate = async (body) => {
  const response = await instance.post(BASE_PATH + "/renew/update", body);
  return response.data;
};

//PMCS_TB_0156 : Mapp get renew member at pmls
const mappGetMemberRenew = async (params) => {
  const response = await instance.get(BASE_PATH + "/renew", { params });
  return response.data;
};

const MemberService = {
  getMMapMemberInfo,
  mappMemberChangeVehicle,
  mappMemberHistoryProcess,
  mappMemberHistoryProcessFilter,
  mappMemberHistoryReceiptFilter,
  mmapMemberHistoryEntryExitFilter,
  mmapMemberPackage,
  mmapMemberRenewUpdate,
  mappGetMemberRenew,
};
export default MemberService;
