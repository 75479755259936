import React from "react";
import { InputTextFieldAOT } from "../../Controls";
import { useTranslation } from "react-i18next";
import AutocompleteAOT from "../../Controls/AutoCompleteAOT";
import { useMemo } from "react";
import {
  generateProvinceOptions,
  generateVehicleTypeOptions,
} from "../../../utils/optionsUtils";
import { useSelector } from "react-redux";
import ButtonAction from "../../Base/ButtonAction";
import BoxMessErr from "../../Base/BoxMessErr";

const Form = ({ formik, isLoading }) => {
  const { t } = useTranslation();
  const { province, vehicletype } = useSelector((state) => state.masterData);

  const provinceOptions = useMemo(() => {
    return generateProvinceOptions(province);
  }, [province]);

  const vehicleTypeOptions = useMemo(() => {
    return generateVehicleTypeOptions(vehicletype);
  }, [vehicletype]);

  return (
    <section className="w-full">
      <p className="text-base lg:text-lg font-normal">
        <span className="text-xl font-semibold">{t("Find your member")} - </span>{" "}
        {t('Please enter your license plate and license province')}
      </p>
      <form onSubmit={formik.handleSubmit} className="mt-8">
        <div className="flex flex-col md:flex-row gap-8 md:gap-5">
          <div className="w-full">
            <AutocompleteAOT
              label={t("Vehicle Type")}
              name="vehicleType"
              options={vehicleTypeOptions}
              val={formik.values.vehicleType}
              handleChange={(e, newValue) => {
                formik.setFieldValue("vehicleType", newValue);
              }}
              required
            />
            <BoxMessErr
              message={
                formik.errors.vehicleType && formik.touched
                  ? formik.errors.vehicleType
                  : null
              }
            />
          </div>
          <div className="w-full">
            <InputTextFieldAOT
              label={t("License Plate")}
              placeholder={t('Sample')}
              name="licensePlate"
              val={formik.values.licensePlate}
              handleChange={formik.handleChange}
              fullWidth
              required
            />
            <BoxMessErr
              message={
                formik.errors.licensePlate && formik.touched
                  ? formik.errors.licensePlate
                  : null
              }
            />
          </div>
          <div className="w-full">
            <AutocompleteAOT
              label={t("Province")}
              name="province"
              options={provinceOptions}
              val={formik.values.province}
              handleChange={(e, newValue) => {
                formik.setFieldValue("province", newValue);
              }}
              required
            />
            <BoxMessErr
              message={
                formik.errors.province && formik.touched
                  ? formik.errors.province
                  : null
              }
            />
          </div>
        </div>
        <div className="mt-8">
          <ButtonAction disabled={!formik.isValid || isLoading} type="submit" >
            {t('Search')}
          </ButtonAction>
        </div>
      </form>
    </section>
  );
};

export default Form;
