import React from "react";

const Email = () => {
  return (
    <div className="w-full flex flex-col min-h-screen">
      <header className="w-full h-[108px] bg-[#BAD7F3] px-28 items-center flex gap-5">
        <div className="bg-[#285E9A] h-10 w-10 rounded-full" />
        <p className="text-[#285E9A] font-normal text-4xl">
          Chalong Pier Phuket
        </p>
      </header>
      <div className="px-28 py-12 text-2xl flex flex-col flex-grow">
        <div className="">
          <p className="">เรียน คุณxxx (ชื่อจริง)</p>
          <div className="my-12 leading-10">
            ทางเราดำเนินการรีเซ็ตรหัสผ่านของท่านแล้ว กรุณาใช้รหัสผ่านด้านล่างนี้
            เพื่อเข้าสู่ระบบและ <br /> ตั้งรหัสผ่านใหม่ รหัสผ่าน: xxxxx <br />
          </div>
          <div className="my-12 mb-20">
            ฝ่ายบริการลูกค้าท่าเทียบเรือท่องเที่ยวอ่าวฉลอง
          </div>
          <div className="border-b border-2" />
        </div>
        <div className="pt-10">
          <p className="">Dear xxx (firstname),</p>
          <div className="my-12 leading-10">
            Your password has been reset. Please use the below password to login
            and set <br /> new password. <br /> Password: xxxxx
          </div>
          <div className="my-12 ">Chalong Pier Phuket Customer Support</div>
        </div>
      </div>
      <footer className="w-full flex flex-col">
        <div className="bg-[#F4F4F4] w-full h-[181px] px-28 text-[#4E4E4E] text-lg flex justify-center flex-col gap-4">
          <p>
            อีเมลฉบับนี้เป็นการแจ้งข้อมูลจากระบบโดยอัตโนมัติ กรุณาอย่าตอบกลับ
            <br />
            หากต้องการความช่วยเหลือสามารถติดต่อเจ้าหน้าที่ทางอีเมล:
            transport@phuketpao.go.th <br />
          </p>
          <p>
            Please do not reply to this email, as we are unable to respond from
            this email address.
            <br /> If you need support, please contact us at
            transport@phuketpao.go.th.
          </p>
        </div>
        <div className="items-center flex gap-5 h-[128px] bg-[#3F7FC6] px-28">
          <div className="bg-white h-20 w-20 rounded-full" />
          <p className="text-white font-medium text-base ">
            องค์การบริหารส่วนจังหวัดภูเก็ต
            <br /> Phuket Provincial Administrative Organization
            <br />
            "icon" 076-356024
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Email;
