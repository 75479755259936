import instance from "../config/apiAxios";

const BASE_PATH = "/mapp/parkingfee";

const parkingFeeCheck = async (body) => {
  const response = await instance.post(BASE_PATH + "/check", body);
  return response.data;
};

const parkingFeeCalculate = async (body) => {
  const response = await instance.post(BASE_PATH + "/calculate", body);
  return response.data;
};

const ParkingFeeService = {
  parkingFeeCheck,
  parkingFeeCalculate,
};
export default ParkingFeeService;
