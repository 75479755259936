import React, { useMemo, useState } from "react";
import { Alert, Box, Snackbar, useMediaQuery } from "@mui/material";
import BoxCarparkChart from "../components/HomeAOT/BoxCarparkChart";
import BoxNews from "../components/HomeAOT/BoxNews";
// import BoxAppParking from "../components/HomeAOT/BoxAppParking";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  CONTENT_TYPE_CODE,
  LANG,
  LANG_ID,
  VISIBILITY_TYPE,
} from "../constants";
import { useQueries, useQuery } from "@tanstack/react-query";
import ParkingService from "../services/parking-service";
import ContentService from "../services/content-service";
import ContainerCenter from "../layouts/ContainerCenter";
import LoadingSpinner from "../components/LoadingSpinner";

export const fetchContentPreviewFilter = (
  pmlsId,
  contenttypecode,
  name,
  pagesize,
  lang
) => {
  const payload = {
    pmlsid: pmlsId,
    pagesize: pagesize ? pagesize : 3,
    languagecode: lang,
    contenttypecode: contenttypecode,
    visibilitytype: VISIBILITY_TYPE.PUBLIC,
    contentstatuscode: "CS003",
    isactive: 1,
    publishdt: undefined,
    name: name ? name : undefined,
  };
  return ContentService.contentPreviewFilter(payload);
};

const HomeAOT = () => {
  const { pmlsId } = useSelector((state) => state.airport);
  const { t, i18n } = useTranslation();
  const isLGScr = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [open, setOpen] = useState(false);

  const contentPreviewFilterQueries = useQueries({
    queries: [
      {
        queryKey: ["CONTENT_PREVIEW_FILTER_INFO", i18n.language],
        queryFn: () =>
          fetchContentPreviewFilter(
            pmlsId,
            CONTENT_TYPE_CODE.INFO,
            undefined,
            undefined,
            i18n.language
          ),
        enabled: pmlsId !== "0",
        select: (data) => data?.data?.allTbContentHdr,
      },
      {
        queryKey: ["CONTENT_PREVIEW_FILTER_PROMO", i18n.language],
        queryFn: () =>
          fetchContentPreviewFilter(
            pmlsId,
            CONTENT_TYPE_CODE.PROMO,
            undefined,
            undefined,
            i18n.language
          ),
        enabled: pmlsId !== "0",
        select: (data) => data?.data?.allTbContentHdr,
      },
      {
        queryKey: ["CONTENT_PREVIEW_FILTER_NEWS", i18n.language],
        queryFn: () =>
          fetchContentPreviewFilter(
            pmlsId,
            CONTENT_TYPE_CODE.NEWS,
            undefined,
            undefined,
            i18n.language
          ),
        enabled: pmlsId !== "0",
        select: (data) => data?.data?.allTbContentHdr,
      },
    ],
  });

  const contentPreviewInformationData = useMemo(() => {
    return contentPreviewFilterQueries[0]?.data?.map((item) => ({
      contentid: item.contentid,
      previewcontenttext:
        item.detail?.length > 0 ? item.detail[0].previewcontenttext : "",
      previewimage:
        item.detail?.length > 0
          ? `data:image/${item.detail[0].previewimage_data.imageext};base64,${item.detail[0].previewimage_data.data_base64}`
          : "",
    }));
  }, [contentPreviewFilterQueries]);

  const contentPreviewPromoData = useMemo(() => {
    return contentPreviewFilterQueries[1]?.data?.map((item) => ({
      contentid: item.contentid,
      previewcontenttext:
        item.detail?.length > 0 ? item.detail[0].previewcontenttext : "",
      previewimage:
        item.detail?.length > 0
          ? `data:image/${item.detail[0].previewimage_data.imageext};base64,${item.detail[0].previewimage_data.data_base64}`
          : "",
    }));
  }, [contentPreviewFilterQueries]);

  const contentPreviewNewsData = useMemo(() => {
    return contentPreviewFilterQueries[2]?.data?.map((item) => ({
      contentid: item.contentid,
      previewcontenttext:
        item.detail?.length > 0 ? item.detail[0].previewcontenttext : "",
      previewimage:
        item.detail?.length > 0
          ? `data:image/${item.detail[0].previewimage_data.imageext};base64,${item.detail[0].previewimage_data.data_base64}`
          : "",
    }));
  }, [contentPreviewFilterQueries]);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const queryParkingRemainSpace = useQuery({
    queryKey: ["PARKING_REMAIN_SPACE", pmlsId, i18n?.language],
    queryFn: () =>
      ParkingService.remainSpace({
        airportid: pmlsId,
        langid: i18n?.language === LANG.TH ? LANG_ID.TH : LANG_ID.EN,
      }),
    enabled: pmlsId !== undefined,
  });

  const parkingRemainSpaceData =
    queryParkingRemainSpace?.data?.data?.data?.allParkingStat;

  // const boxZoneAvailableData = parkingRemainSpaceData?.map((item) => {
  //   const left = Number(item.totalguideplace) - Number(item.leftguideplace);
  //   const status =
  //     left < Number(item.totalguideplace) ? "Available" : "Unavailable";
  //   return {
  //     label: item.parkingname,
  //     val1: left,
  //     val2: Number(item.totalguideplace),
  //     status: status,
  //   };
  // });

  return (
    <>
      {isLGScr ? null : (
        <Box
          sx={{
            minHeight: "20vh",
            backgroundColor: "#E9F0F5",
            paddingX: "8rem",
            paddingTop: "10rem",
          }}
        ></Box>
      )}
      <div className="pt-20">
        {queryParkingRemainSpace.isLoading ? (
          <LoadingSpinner />
        ) : (
          <BoxCarparkChart data={parkingRemainSpaceData} />
        )}
        {/* <BoxZoneAvailable data={boxZoneAvailableData} /> */}
        <ContainerCenter>
          <BoxNews
            type={CONTENT_TYPE_CODE.PROMO}
            header={t("Campaign and Promotion")}
            data={contentPreviewPromoData}
            isLoading={contentPreviewFilterQueries[1].isLoading}
          />

          <BoxNews
            type={CONTENT_TYPE_CODE.INFO}
            header={t("Press Release")}
            data={contentPreviewInformationData}
            isLoading={contentPreviewFilterQueries[0].isLoading}
          />
          <BoxNews
            type={CONTENT_TYPE_CODE.NEWS}
            header={t("AOT News")}
            data={contentPreviewNewsData}
            isLoading={contentPreviewFilterQueries[2].isLoading}
          />
          {/* ? TASK: 1897: Remove Mobile App banner - เนื่องจากยังไม่เริ่มเปิดใช้งานในส่วนของ Mobile App จึงขอเอารูปออกก่อน จะกลับมาแสดงเมื่อเริ่มเปิดใช้งาน  */}
          {/* <BoxAppParking /> */}
          <Snackbar open={open} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              ผู้ใช้งานได้สมัครลานจอดรถแล้ว
            </Alert>
          </Snackbar>
        </ContainerCenter>
      </div>
    </>
  );
};

export default HomeAOT;
