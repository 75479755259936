import React from "react";

const LicenseVehicleBlock = ({ name, license }) => {
  return (
    <div className="flex flex-col gap-6 text-center  items-center w-[100px] md:w-[220px]">
      <img
        src="/images/change_vehicle/ico_car_big.svg"
        className="w-[65px] md:w-[100px] h-auto"
      />
      <p className="text[#2F2E2F] font-semibold text-lg md:text-xl">{name}</p>
      <p className="text-[#245AB1] font-bold text-xl md:text-2xl">
        {license || "-"}
      </p>
    </div>
  );
};

export default LicenseVehicleBlock;
