import React from "react";
import { Box, Button, Grid, styled, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SelectAOT from "../Controls/SelectAOT";
import CheckBoxAOT from "../Controls/CheckBoxAOT";
import { useMemo } from "react";
import { useRef } from "react";
import {
  b64toBlob,
  convertToBase64,
  convertToImageUrl,
  convertToURI,
  getExtension,
  openPreview,
} from "../../utils/fileUpload";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generateDocTypeGroupOptions } from "../../utils/optionsUtils";
import { Colors } from "../../constants/colors";

const TextUpload = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 24px/35px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  marginLeft: "0.68rem",
}));

const TextLodge = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/24px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  marginLeft: "0.68rem",
}));

const TextAttach = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  marginLeft: "1.301rem",
}));

const TextListUpload = styled(Typography)(({ theme }) => ({
  font: " normal normal 600 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  marginTop: "2.592rem",
  marginLeft: "0.3rem",
}));

const BoxHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
  },
}));

const BoxDoc = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingLeft: "0.8rem",
  paddingTop: "2.493rem",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
  },
}));


const GridContainer = styled((props) => (
  <Grid container spacing={2} {...props} />
))(({ theme }) => ({
  padding: "1.308rem 2.125rem",
  border: "2px dashed #6399EB",
  borderRadius: " 11px",
  justifyContent: "center",
  marginTop: "1rem",
  [theme.breakpoints.down("lg")]: {
    //display: 'none'
  },
}));

const GridItem = styled((props) => (
  <Grid item xl={3} lg={6} xs={12} {...props} />
))(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.down("lg")]: {
    //display: 'none'
    maxWidth: "11rem",
  },
}));

const BoxIDCard = styled(Box)(({ theme }) => ({
  border: "1px solid #E9F0F5",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0.3rem",
  marginBottom: "0.5rem",
  [theme.breakpoints.down("lg")]: {
    //display: 'none'
    maxWidth: "11rem",
  },
}));

const ButtonAttach = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "64px",
  borderRadius: "10px",
  marginLeft: "2.761rem",
  background:
    "transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "0",
    marginTop: "1rem",
  },
  "&.Mui-disabled": {
    background: Colors.disabledButton,
    border: "none",
    cursor: "not-allowed",
    pointerEvents: "auto",
  },
}));

const BoxButton = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  paddingTop: "5.233rem",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const ButtonCancel = styled(Button)(({ theme }) => ({
  width: "47%",
  height: "5rem",
  background: "#E9F0F5 0% 0% no-repeat padding-box",
  borderRadius: "10px",
  font: "normal normal normal 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  "&:hover": { background: "#E9F0F5 0% 0% no-repeat padding-box" },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginBottom: "1rem",
    marginRight: "1rem",
  },
}));

const ButtonSave = styled(Button)(({ theme }) => ({
  width: "47%",
  height: "5rem",
  font: "normal normal normal 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  background:
    "transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box",
  border: "2px solid #3D9AEE",
  borderRadius: "10px",
  "&.Mui-disabled": {
    color: "#FFFFFF",
    opacity: 0.5,
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const RemoveText = styled(Typography)(() => ({
  font: "normal normal 600 16px/25px Noto Sans Thai",
  letterSpacing: "0px",
  color: "red",
  paddingTop: "0.5rem",
  cursor: "pointer",
}));

const ImgPlane = styled((props) => <Box component="img" {...props} />)(
  ({ theme }) => ({
    width: "3rem",
    height: "1.8rem",
    marginRight: "1rem",
    [theme.breakpoints.down("lg")]: {
      //display: 'none'
    },
  })
);

const ImgDoc = styled(Box)(({ theme }) => ({
  width: "120px",
  height: "120px",
  objectFit: "cover",
  paddingTop: "0.5rem",
}));

const AirportText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 24px/45px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
  },
}));

const RegisterParkingStep3 = ({ formik, currentAirportPmlsId }) => {
  const { t } = useTranslation();
  const { doctypeGroup } = useSelector((state) => state.masterData);
  const inputFileRef = useRef(null);

  const handleNext = () => {};

  const handleIsPaperless = (e) => {
    formik.setFieldValue("d_ispaperless", e.target.checked ? 1 : 0);
  };

  const handleFileUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (file.size > 5e6) {
        alert("The uploaded file size exceeds the limit.");
        return false;
      }
      const base64 = await convertToBase64(file);
      const oBase64 = base64.split(",");
      if (oBase64.length > 0) {
        const ext = getExtension(oBase64[1]);
        if (!["png", "jpg", "jpeg", "pdf"].includes(ext)) {
          alert("This file type is not supported.");
          return false;
        }

        const selectedDocTypeGroupId = doctypeGroup?.find(
          (item) => item.doctypeid == formik.values.doctypeid
        );
        const base64 = oBase64[1];
        const data = {
          docid: "",
          memberid: formik.values.memberid,
          doctypeid: formik.values.doctypeid,
          docgroupid: selectedDocTypeGroupId
            ? selectedDocTypeGroupId.docgroupid
            : 0,
          doctypegroupid: selectedDocTypeGroupId
            ? selectedDocTypeGroupId.doctypegroupid
            : 0,
          docname: file.name,
          docsize: file.size,
          docext: ext,
          status: "",
          data_base64: base64,
        };
        const newData = [...formik.values.doc];
        newData.push(data);
        formik.setFieldValue("doc", newData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      inputFileRef.current.value = "";
      formik.setFieldValue("doctypeid", undefined);
    }
  };

  // const handleOpenNewTab = (base64, ext) => {
  //   if (ext === "pdf") {
  //     let blob = b64toBlob(base64, "application/pdf");
  //     openPreview(blob);
  //   } else {
  //     let a = convertToURI(base64);
  //     var image = new Image();
  //     image.src = a;

  //     var w = window.open("");
  //     w.document.write(image.outerHTML);
  //   }
  // };
  const handleRemoveUpload = (index) => {
    let b = window.confirm(`คุณแน่ใจว่าต้องการลบข้อมูลเอกสารนี้ ?`);
    if (!b) return;
    const x = [...formik.values.doc];
    x.splice(index, 1);
    formik.setFieldValue("doc", x);
  };

  const doctypeGroupList = useMemo(() => {
    return generateDocTypeGroupOptions(doctypeGroup);
  }, [doctypeGroup]);

  const getDoctypeName = useCallback(
    (id) => {
      const x = doctypeGroupList.filter((x) => {
        return x.id === id;
      });
      if (x.length > 0) {
        return x[0].display;
      } else {
        return "";
      }
    },
    [doctypeGroupList]
  );

  return (
    <>
      <Box>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <input
            // accept="image/*"
            id="img_upload"
            type="file"
            style={{ display: "none" }}
            ref={inputFileRef}
            onChange={handleFileUpload}
          />

          <BoxHeader>
            <ImgPlane src="/images/auth/ico_plane.svg" />
            {currentAirportPmlsId == "1" ? (
              <AirportText>{t("Suvarnabhumi airport")}</AirportText>
            ) : (
              <AirportText>
                {t("Don Mueang International Airport")}{" "}
              </AirportText>
            )}
          </BoxHeader>
          <Box
            sx={{
              display: "flex",
              paddingLeft: "0.8rem",
              paddingTop: "1.648rem",
            }}
          >
            <ImgPlane
              component="img"
              src="/images/register/ico_upload_2.svg"
              alt=""
            ></ImgPlane>
            <TextUpload>{t("Upload the documents")}</TextUpload>
          </Box>
          <Box
            sx={{
              display: "flex",
              paddingLeft: "3.813rem",
              paddingTop: "3.728rem",
              alignItems: "center",
            }}
          >
            <CheckBoxAOT
              name="d_ispaperless"
              checked={formik.values.d_ispaperless === 1}
              onChange={handleIsPaperless}
            ></CheckBoxAOT>
            <TextLodge>{t("Submit the electronic documents")}</TextLodge>
          </Box>
          <BoxDoc>
            <div className="w-full">
              <SelectAOT
                label={`${t("Select document type")} *`}
                data={doctypeGroupList}
                setVal={(e) => {
                  formik.setFieldValue("doctypeid", e);
                }}
                val={formik.values.doctypeid}
                sxa={{ width: "100%" }}
              />
              <p className="text-blue-700 mt-2 font-semibold text-sm">
                {t("The maximum file size")}
              </p>
            </div>
            <ButtonAttach
              onClick={() => {
                inputFileRef.current.click();
              }}
              disabled={!formik.values.doctypeid}
            >
              <AttachFileIcon
                sx={{ color: "#FFFFFF", transform: "rotate(44deg)" }}
              ></AttachFileIcon>
              <TextAttach>{t("Attach DocumentImage")}</TextAttach>
            </ButtonAttach>
          </BoxDoc>
          <Box sx={{ paddingLeft: "0.8rem" }}>
            {formik.values.doc.length > 0 ? (
              <>
                <TextListUpload>{t("Uploaded Documents")}</TextListUpload>
                <GridContainer>
                  {formik.values.doc.map((item, index) => {
                    return (
                      <GridItem key={index}>
                        <BoxIDCard>
                          <ImgDoc
                            component="img"
                            src={
                              item?.docext === "pdf"
                                ? "https://pmlsdev.teammcloud.com/images/pdf/logo-pdf.png"
                                : convertToURI(item.data_base64)
                            }
                          ></ImgDoc>
                          <RemoveText onClick={() => handleRemoveUpload(index)}>
                            Remove
                          </RemoveText>
                        </BoxIDCard>
                        {`${index + 1}. ${getDoctypeName(item.doctypeid)}`}
                      </GridItem>
                    );
                  })}
                </GridContainer>
              </>
            ) : null}
          </Box>

          <BoxButton>
            {/* <ButtonCancel onClick={handleBack}>ยกเลิก</ButtonCancel> */}
            <ButtonSave
              onClick={handleNext}
              type="submit"
              disabled={formik.values.doc.length === 0}
            >
              {t("Submit")}
            </ButtonSave>
          </BoxButton>
        </form>
      </Box>
    </>
  );
};

export default RegisterParkingStep3;
