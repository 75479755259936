import React from "react";
import { Box, Grid, styled, Typography, Button } from "@mui/material";
import DatePickerAOT from "../Controls/DatePickerAOT";
import InputTextFieldAOT from "../Controls/InputTextFieldAOT";
import { SelectAOT } from "../Controls/index";
import { useMemo } from "react";
import { formattedPrice } from "../../utils/format";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../constants";
import TablePackageDetail from "../Base/TablePackageDetail";
import { useTranslation } from "react-i18next";
import { Colors } from "../../constants/colors";
import { useSelector } from "react-redux";
import { generateVehicleTypeOptions } from "../../utils/optionsUtils";

const BoxDataRenew = styled(Box)(() => ({
  width: "100%",
}));

const BoxInputData = styled(Box)(() => ({
  padding: "2rem 0",
}));

const TextTitleDataRenew = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 22px/45px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 18px/40px Noto Sans Thai",
  },
}));

const GridContainer = styled((props) => (
  <Grid container spacing={3} {...props} />
))(() => ({
  padding: "0",
}));

const GridItem = styled((props) => <Grid item lg={6} xs={12} {...props} />)(
  () => ({
    padding: "0",
  })
);

const GridItemFull = styled((props) => <Grid item xs={12} {...props} />)(
  () => ({
    padding: "0",
  })
);

const BoxButton = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyItems: "center",
  alignItems: "center",
  width: "100%",
  padding: "3rem 0rem 0rem 0rem",
}));

const ButtonSave = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "4rem",
  font: "normal normal normal 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  background:
    "transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box",
  border: "2px solid #3D9AEE",
  borderRadius: "10px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  "&.Mui-disabled": {
    background: Colors.disabledButton,
    border: "none",
    cursor: "not-allowed",
    pointerEvents: "auto",
  },
}));

const RenewStep1 = ({
  data,
  selectedVehicle,
  packageData,
  dateDuration,
  setDataDuration,
  getTotalPackagePrice,
  disabled,
  numberOfMonths,
  setNumberOfMonths,
  onClickUpdateButtonHandler,
  memberPackage,
}) => {
  const { t } = useTranslation();
  const { vehicletype } = useSelector(
    (state) => state.masterData
  );

  const handleNext = () => {
    onClickUpdateButtonHandler();
  };

  const msVehicleTypeOptions = useMemo(() => {
    return generateVehicleTypeOptions(vehicletype);
  }, [vehicletype]);

  return (
    <>
      <BoxDataRenew>
        <Box sx={{ display: "flex" }}>
          <TextTitleDataRenew>
            {t("Renew Membership Information")}
          </TextTitleDataRenew>
        </Box>
        <BoxInputData>
          <GridContainer>
            <GridItem>
              <InputTextFieldAOT
                label={t("Member Type")}
                name="membertype"
                val="Member"
                disabled={true}
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Member Number")}
                name="xxxx"
                val={data?.memberno || ""}
                disabled
              />
            </GridItem>

            <GridItem>
              <SelectAOT
                label={t("Vehicle Type")}
                options={msVehicleTypeOptions}
                fullWidth
                disabled
                value={selectedVehicle?.vehicletypeid}
                onChange={(e) => {}}
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("License Plate")}
                name="xxxx"
                disabled
                val={selectedVehicle?.licenseplate || ""}
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Province")}
                name="province"
                val={selectedVehicle?.licenseprovince}
                disabled
              />
            </GridItem>
            <GridItem>
              <DatePickerAOT
                label={t("Expired Date")}
                name="xxxx"
                val={memberPackage?.enddate}
                disabled
                handleChange={() => {}}
              />
            </GridItem>
            <GridItemFull>
              <InputTextFieldAOT
                label={t("Member Package")}
                name="xxxx"
                disabled
                val={packageData?.name || ""}
              />
            </GridItemFull>
          </GridContainer>
        </BoxInputData>
        <Box sx={{ paddingBottom: "3.176rem" }}>
          <TextTitleDataRenew>{t("Renew Duration")}</TextTitleDataRenew>
          <GridContainer>
            <GridItem>
              <DatePickerAOT
                required={true}
                label={t("Apply Start Date")}
                disabled
                handleChange={(e) => {
                  var currentMonth = dayjs().startOf("month");
                  var selectedMonth = dayjs(e).startOf("month");
                  if (
                    selectedMonth.isBefore(currentMonth, "month") ||
                    selectedMonth.isSame(currentMonth, "month")
                  ) {
                    alert(
                      "Please select a month that is not the current month or earlier."
                    );
                    return;
                  }
                  const startOfMonth = dayjs(e)
                    .startOf("month")
                    .format(DATE_FORMAT);
                  const endDateWithMonthDuration = dayjs(startOfMonth)
                    .add(Number(numberOfMonths - 1), "month")
                    .endOf("month")
                    .format(DATE_FORMAT);
                  setDataDuration({
                    endDate: endDateWithMonthDuration,
                    startDate: startOfMonth,
                  });
                }}
                val={dateDuration?.startDate}
              />
            </GridItem>
            <GridItem>
              <SelectAOT
                label={t("Month Amount")}
                options={[
                  {
                    id: "1",
                    display: 1,
                  },
                ]}
                fullWidth
                disabled={true}
                value={numberOfMonths}
                onChange={(e) => {
                  const _numberOfMonth = Number(e.target.value);
                  setNumberOfMonths(_numberOfMonth);
                  let endDateWithMonthDuration = null;
                  if (_numberOfMonth === 1) {
                    endDateWithMonthDuration = dayjs(dateDuration?.startDate)
                      .endOf("month")
                      .format(DATE_FORMAT);
                  } else {
                    endDateWithMonthDuration = dayjs(dateDuration?.startDate)
                      .add(Number(_numberOfMonth - 1), "month")
                      .endOf("month")
                      .format(DATE_FORMAT);
                  }
                  setDataDuration({
                    ...dateDuration,
                    endDate: endDateWithMonthDuration,
                  });
                }}
              />
            </GridItem>
            <GridItem>
              <DatePickerAOT
                label={t("Expired Date")}
                handleChange={() => {}}
                val={dateDuration?.endDate}
                disabled
              />
            </GridItem>
          </GridContainer>
        </Box>
        <TablePackageDetail
          isRenew
          data={packageData?.items?.filter((item) => item.chargetype === "002")}
          totalPrice={formattedPrice(getTotalPackagePrice)}
        />
      </BoxDataRenew>
      <BoxButton>
        <ButtonSave onClick={handleNext} disabled={disabled}>
          {t("SaveNext")}
        </ButtonSave>
      </BoxButton>
    </>
  );
};

export default RenewStep1;
