import React from "react";
import ErrorResultWithText from "../Result/ErrorResultWithText";
import { useTranslation } from "react-i18next";
import ButtonAction from "../Base/ButtonAction";
import { useNavigate } from "react-router-dom";
const PaymentExpired = ({
  onClickMakeNewTransactionBtnHandler,
  showBackToHomeBtn = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClickBackToMainBtnHandler = () => navigate("/");
  return (
    <div className="w-full">
      <ErrorResultWithText
        text={
          <p className="text-xl text-red-500 text-center">
            {t("Your transaction has expired")}
          </p>
        }
      />
      <div className="flex flex-col gap-4 mt-4">
        <ButtonAction
          size="small"
          onClick={() => {
            onClickMakeNewTransactionBtnHandler();
          }}
        >
          {t("Make a new transaction")}
        </ButtonAction>
        {showBackToHomeBtn && (
          <ButtonAction
            size="small"
            onClick={() => {
              onClickBackToMainBtnHandler();
            }}
          >
            {t("Back to Home")}
          </ButtonAction>
        )}
      </div>
    </div>
  );
};

export default PaymentExpired;
