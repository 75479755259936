import instance from "../config/apiAxios";

const getSysUserInformation = async (params) => {
  const response = await instance.get("/sys/user", { params });
  return response.data;
};

const userService = {
  getSysUserInformation,
};

export default userService;
