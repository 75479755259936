import { useState } from "react";

export const METHOD_PAYMENT = {
  QR_CODE: "QR_CODE",
  CREDIT_CARD: "CREDIT_CARD",
};

export const STATUS_PAYMENT = {
  WAIT: "WAIT", // loop
  FAIL: "FAIL",
  SUCCESS: "SUCCESS",
  EXPIRED: "EXPIRED",
  STOP_PAYMENT: "STOP_PAYMENT", // หยุด loop
};

export const PROCESS_PAYMENT_STATUS = {
  CONFIRM: "Confirm",
  CANCEL: "Cancel",
  REQUEST: "Request",
};

export const INTERVAL_QR_QUERY = 2000;

export const usePayment = () => {
  const [onlinetransid, setOnlinetransid] = useState(undefined);
  const [receiptData, setReceiptData] = useState(undefined)
  const [method, setMethod] = useState(METHOD_PAYMENT.QR_CODE);
  const [completedDisplayData, setCompletedDisplayData] = useState(null);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [intervalId, setIntervalId] = useState(null); // qr payment interval
  const [isPaymentPendingTransaction, setIsPaymentPendingTransaction] =
    useState(null); // ใช่รายการค้างชำระมั้ย

  const [
    checkPaymentResultPendingPaymentData,
    setCheckPaymentResultPendingPaymentData,
  ] = useState(undefined); // data result payment pending

  const [openCancelTransactionDialog, setOpenCancelTransactionDialog] =
    useState(false);

  //feat. Receive payment confirmation via email.
  const [checkedSendEmail, setCheckedSendEmail] = useState(false);
  const [receivePaymentConfirmEmail, setReceivePaymentConfirmEmail] =
    useState("");

  const onChangePaymentMethod = (value) => {
    setMethod(value);
  };

  const onChangeIntervalId = (value) => {
    setIntervalId(value);
  };

  const onChangePaymentCompleted = (value) => {
    setIsPaymentCompleted(value);
  };

  const onChangePaymentStatus = (status) => {
    setPaymentStatus(status);
  };

  const clearIntervalQRCode = () => {
    clearInterval(intervalId);
  };

  const onFailedPaymentHandler = () => {
    setPaymentStatus(STATUS_PAYMENT.FAIL);
    if (method === METHOD_PAYMENT.QR_CODE) {
      clearIntervalQRCode();
    }
  };

  const onSuccessPaymentHandler = () => {
    setPaymentStatus(STATUS_PAYMENT.SUCCESS);
    if (method === METHOD_PAYMENT.QR_CODE) {
      clearIntervalQRCode();
    }
  };

  const onExpiredPaymentHandler = () => {
    setPaymentStatus(STATUS_PAYMENT.EXPIRED);
    if (method === METHOD_PAYMENT.QR_CODE) {
      clearIntervalQRCode();
    }
  };

  //หยุด loop
  const onStopPaymentHandler = () => {
    setPaymentStatus(STATUS_PAYMENT.STOP_PAYMENT);
    if (method === METHOD_PAYMENT.QR_CODE) {
      clearIntervalQRCode();
    }
  };

  const onChangeReceivePaymentConfirmViaEmail = (value) => {
    setReceivePaymentConfirmEmail(value);
  };

  const onChangeCheckedBoxSendEmailHandler = (value) => {
    setCheckedSendEmail(value);
  };

  return {
    method,
    intervalId,
    paymentStatus,
    checkedSendEmail,
    isPaymentCompleted,
    completedDisplayData,
    receivePaymentConfirmEmail,
    isPaymentPendingTransaction,
    onlinetransid,
    checkPaymentResultPendingPaymentData,
    openCancelTransactionDialog,
    receiptData, setReceiptData,
    setOpenCancelTransactionDialog,
    setOnlinetransid,
    setIsPaymentPendingTransaction,
    onChangePaymentMethod,
    onChangeIntervalId,
    onChangePaymentCompleted,
    clearIntervalQRCode,
    onFailedPaymentHandler,
    onExpiredPaymentHandler,
    onSuccessPaymentHandler,
    onStopPaymentHandler,
    onChangePaymentStatus,
    setCompletedDisplayData,
    onChangeReceivePaymentConfirmViaEmail,
    onChangeCheckedBoxSendEmailHandler,
    setCheckPaymentResultPendingPaymentData,
  };
};
