import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const CountdownTimer = ({ initialSeconds, onTimeout }) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const { t } = useTranslation();

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      // If the timer reaches 0 seconds, trigger the onTimeout callback
      onTimeout();
    }
  }, [seconds, onTimeout]);

  const formattedTime = `${seconds} ${t("sec")}`;

  return <div>({formattedTime})</div>;
};

export default CountdownTimer;
