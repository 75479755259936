import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import BoxCarparkChart from "../components/HomeAOT/BoxCarparkChart";
import { useQuery } from "@tanstack/react-query";
import ParkingService from "../services/parking-service";
import { LANG, LANG_ID } from "../constants";
import { useTranslation } from "react-i18next";

const ParkingDensityWebView = () => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get("lang");
  const pmlsid = searchParams.get("pmlsid");

  const queryParkingRemainSpace = useQuery({
    queryKey: ["PARKING_REMAIN_SPACE", pmlsid, lang],
    queryFn: () =>
      ParkingService.remainSpace({
        airportid: pmlsid,
        langid: lang === LANG.TH ? LANG_ID.TH : LANG_ID.EN,
      }),
    enabled: pmlsid !== undefined && lang !== undefined,
  });

  //set lang from params
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  const parkingRemainSpaceData =
    queryParkingRemainSpace?.data?.data?.data?.allParkingStat;

  return (
    <section>
      {queryParkingRemainSpace.isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="mx-auto">
          <BoxCarparkChart data={parkingRemainSpaceData} />
        </div>
      )}
    </section>
  );
};

export default ParkingDensityWebView;
