import React from 'react'
import { Box, Button, styled, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const DetailHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3.991rem 6.25rem 3.341rem 6.25rem',
    [theme.breakpoints.down('lg')]: {
        padding: '3.991rem 5.25rem 1.322rem 5.25rem'
    },
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        padding: '3.991rem 2.25rem 1.322rem 2.25rem'
    }
}))

const BoxHeaderRight = styled(Box)(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        margin: '1rem 0'
    }
}))

const BoxDetailPayment = styled(Box)(({ theme }) => ({
    padding: '0 15.563rem 0 15.563rem',
    marginBottom: '2.688rem',
    [theme.breakpoints.down('lg')]: {
        padding: '0 7rem 0 7rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '0 5rem 0 5rem'
    }
}))

const BoxButton = styled(Box)(({ theme }) => ({
    padding: '0 15.563rem 0 15.563rem',
    marginBottom: '10rem',
    [theme.breakpoints.down('lg')]: {
        padding: '0 7rem 0 7rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '0 5rem 0 5rem'
    }
}))

const BoxOuter = styled(Box)(() => ({
    border: '1px solid #B3BBC2',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    opacity: 1
}))

const BoxInside = styled(Box)(({ theme }) => ({
    width: '80%',
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    }
}))

const BoxSubject = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '2rem',
    borderBottom: '1px solid #B3BBC2',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        paddingRight: '0'
    }
}))

const BoxTitle = styled(Box)(() => ({
    display: 'flex',
    marginLeft: '2rem'
}))

const BoxSummary = styled(Box)(({ theme }) => ({
    paddingTop: '2rem',
    marginLeft: '2rem',
    marginRight: '2rem',
    marginBottom: '0.5rem',
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '0',
        marginBottom: '0',
        marginRight: '0',
        flexDirection: 'column'
    }
}))

const BoxTotalLeft = styled(Box)(({ theme }) => ({
    width: '50%',
    background: '#E9F0F5 0% 0% no-repeat padding-box',
    height: '7rem',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingBottom: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '0',
        paddingRight: '0'
    }
}))

const BoxTotalRight = styled(Box)(({ theme }) => ({
    width: '50%',
    background: '#245AB1 0% 0% no-repeat padding-box',
    height: '7rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const BoxDetail = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: '2rem',
    marginRight: '2rem',

    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column'
    }
}))

const TotalLeftText = styled(Typography)(() => ({
    font: 'normal normal 600 22px/33px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#2F2E2F'
}))

const TitleText = styled(Typography)(() => ({
    font: 'normal normal 600 22px/46px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#2F2E2F',
    paddingLeft: '2rem',
    marginTop: '2.571rem'
}))

const TextDetailHeader = styled(Typography)(() => ({
    font: 'normal normal 600 36px/54px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#2F2E2F'
}))

const TotalMoneyText = styled(Typography)(() => ({
    font: 'normal normal 600 36px/54px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#FFFFFF',
    paddingBottom: '0.5rem'
}))

const TotalRightText = styled(Typography)(() => ({
    font: 'normal normal 600 48px/73px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#FFFFFF',
    paddingLeft: '1rem',
    opacity: 1
}))

const TextRight = styled(Typography)(() => ({
    font: 'normal normal normal 18px/27px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#2F2E2F',
    marginLeft: '0.2rem'
}))

const TextRightBlue = styled(Typography)(() => ({
    font: 'normal normal 600 18px/27px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#3C5AAD',
    marginLeft: '0.2rem'
}))

const TextDownload = styled(Typography)(() => ({
    font: 'normal normal normal 14px/16px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#8D9193',
    textAlign: 'left',
    marginTop: '0.3rem',
    marginLeft: '1.393rem'
}))

const TextDownloadDetail = styled(Typography)(() => ({
    font: 'normal normal normal 22px/16px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#1A191A',
    marginLeft: '1.393rem'
}))

const TextUpload = styled(Typography)(() => ({
    font: 'normal normal normal 22px/16px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#FFFFFF',
    marginLeft: '1.393rem'
}))

const NormalText = styled(Typography)(({ theme }) => ({
    font: 'normal normal normal 22px/62px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#2F2E2F',
    [theme.breakpoints.down('lg')]: {
        marginBottom: '1rem'
    }
}))

const GreyText = styled(Typography)(({ theme }) => ({
    font: 'normal normal normal 22px/62px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#898A8F',
    [theme.breakpoints.down('lg')]: {
        marginBottom: '1rem'
    }
}))

const ImgRenew = styled((props) => <Box component='img' {...props} />)(() => ({
    width: '3.063rem',
    marginRight: '2.108rem'
}))

const ImgUpload = styled((props) => <Box component='img' {...props} />)(() => ({
    marginLeft: '1.25rem'
}))

const ImgDownloadDetail = styled((props) => <Box component='img' {...props} />)(() => ({
    marginLeft: '1.25rem'
}))

const ButtonUpload = styled(Button)(() => ({
    width: '100%',
    height: '86px',
    justifyContent: 'center',
    borderRadius: '10px',
    marginBottom: '1rem',
    background: 'transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box'
}))

const ButtonDownloadDetail = styled(Button)(() => ({
    width: '100%',
    height: '86px',
    justifyContent: 'center',
    borderRadius: '10px',
    marginBottom: '1rem',
    border: '1px solid #B3BBC2'
}))

const PaymentDetail = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <DetailHeader>
                    <Box sx={{ display: 'flex' }}>
                        <ImgRenew src='/images/payment/ico_payment_head.svg' alt='' />
                        <TextDetailHeader>{t('Payment')}</TextDetailHeader>
                    </Box>
                    <BoxHeaderRight>
                        <TextRight>{t('Home')} /</TextRight>
                        <TextRight>{t('Payment')} /</TextRight>
                        <TextRightBlue>{t('View Document')}</TextRightBlue>
                    </BoxHeaderRight>
                </DetailHeader>
                <Box sx={{ alignContent: 'center', width: '100%' }}>
                    <BoxDetailPayment>
                        <BoxOuter>
                            <BoxTitle>
                                <TitleText>รายละเอียดการชำระเงิน</TitleText>
                            </BoxTitle>
                            <BoxDetail>
                                <BoxInside>
                                    <BoxSubject>
                                        <NormalText>{t('Order ID')} </NormalText>
                                        <GreyText>1245678923</GreyText>
                                    </BoxSubject>
                                    <BoxSubject>
                                        <NormalText>วันที่และเวลา 3 มี.ค. 2022, 10.03</NormalText>
                                        <GreyText>3 มี.ค. 2022, 10.03</GreyText>
                                    </BoxSubject>
                                    <BoxSubject>
                                        <NormalText>เลขทะเบียนรถ</NormalText>
                                        <GreyText>2กณ 5999</GreyText>
                                    </BoxSubject>
                                    <BoxSubject>
                                        <NormalText>วิธีการชำระเงิน</NormalText>
                                        <GreyText>โอน/ชำระผ่าน บัญชีธนาคาร</GreyText>
                                    </BoxSubject>
                                    <BoxSubject sx={{ borderBottom: 'none' }}>
                                        <NormalText>ราคาแพ็คเกจที่ต่ออายุ</NormalText>
                                        <GreyText>THB 4,500.00</GreyText>
                                    </BoxSubject>
                                </BoxInside>
                            </BoxDetail>
                            <BoxSummary>
                                <BoxTotalLeft>
                                    <TotalLeftText>ยอดทั้งหมด</TotalLeftText>
                                </BoxTotalLeft>
                                <BoxTotalRight>
                                    <TotalMoneyText>THB</TotalMoneyText>
                                    <TotalRightText>4,500.00</TotalRightText>
                                </BoxTotalRight>
                            </BoxSummary>
                        </BoxOuter>
                    </BoxDetailPayment>
                    <BoxButton>
                        <ButtonUpload>
                            <ImgUpload src='/images/payment/ico_upload_wh.svg'></ImgUpload>
                            <TextUpload>{t('Upload Proof of Payment')}</TextUpload>
                        </ButtonUpload>
                        <ButtonDownloadDetail
                            onClick={() => {
                                navigate('/members/payment/download')
                            }}
                        >
                            <ImgDownloadDetail src='/images/payment/ico_download_bl.svg'></ImgDownloadDetail>
                            <Box>
                                <TextDownloadDetail>{t('Download Payment Document')}</TextDownloadDetail>
                                <TextDownload>สำหรับไปชำระที่ธนาคาร</TextDownload>
                            </Box>
                        </ButtonDownloadDetail>
                        <ButtonDownloadDetail
                            onClick={() => {
                                navigate('/members/payment/taxinvoice')
                            }}
                        >
                            <ImgDownloadDetail src='/images/payment/ico_tax_bl.svg'></ImgDownloadDetail>
                            <TextDownloadDetail>ขอใบกำกับภาษีแบบเต็มรูป</TextDownloadDetail>
                        </ButtonDownloadDetail>
                    </BoxButton>
                </Box>
            </Box>
        </>
    )
}

export default PaymentDetail
