import React from "react";

const TextWithIcon = ({ icon, text, gap = 10 }) => {
  return (
    <div
      className="flex items-center"
      style={{
        gap: gap,
      }}
    >
      <div>{icon}</div>
      {text}
    </div>
  );
};

export default TextWithIcon;
