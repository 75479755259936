import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const NewCarparkBarChart = ({ remainSpaceData }) => {
  const { i18n, t } = useTranslation();
  const isMobile = window.innerWidth <= 550;
  
  const options = {
    plugins: {
      title: {
        display: false,
      },
      datalabels: {
        color: isMobile?  "white" : "white",
        font: {
          weight: "bold",
          size: isMobile ? 12 : 20,
        },
        textStrokeColor: '#3f3f3f',
        textStrokeWidth: 2,
        formatter: function (value, context) {
          const label = Math.round(value).toLocaleString() + " " + t("cars");
          return label;
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false, // do not maintain aspect ratio
    indexAxis: "y", // set indexAxis to y for horizontal bars
    barThickness: isMobile ? 70 : 100, // set the thickness of each bar
    barPercentage: isMobile ? 0.5 : 0.8, // set the width of each bar
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
        title: {
          display: true,
          text: t("Car Density"),
          font: {
            size: isMobile ? 14 : 16,
            weight: "bold",
          },
          align: "end", // align text to the right
          padding: {
            top: 30, // add 10 pixels of padding to the top of the title
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          font: {
            size: isMobile ? 12 : 14,
            weight: "bold",
          },
        },
        title: {
          display: false,
          text: "สถานที่ (คัน)",
          font: {
            size: isMobile ? 14 : 18,
            weight: "bold",
          },
          align: "end", // align text to the right
          padding: {
            bottom: -30,
          },
        },
      },
    },
  };

  const chartData = {
    labels: remainSpaceData?.map((parking) => parking?.parkingname),
    datasets: [
      {
        label: t("Occupied"),
        data: remainSpaceData?.map((parking) =>
          Number(parking?.totalguideplace - parking?.leftguideplace)
        ),
        backgroundColor: "#F26665",
      },
      // {
      //   label: "จอง",
      //   data: remainSpaceData?.map(() => 0),
      //   backgroundColor: "#EF8A49",
      // },
      {
        label: t("Vacant"),
        data: remainSpaceData?.map((parking) =>
          Number(parking?.leftguideplace)
        ),
        backgroundColor: "#4DC398",
      },
    ],
  };

  return (
    <div
      style={{
        // paddingLeft: 150,
        // paddingRight: 150,
        position: "relative",
        height: "60vh",
        width: "100%",
      }}
    >
      <Bar
        options={options}
        data={chartData}
        fallbackContent={<div>Chart not ready...</div>}
      />
    </div>
  );
};

export default NewCarparkBarChart;
