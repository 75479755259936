import React from 'react'
import { Box, Button, styled, Typography } from '@mui/material'
import { useNavigate } from 'react-router'

const BoxImgWelcome = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'relative'
}))

const BoxTextWelcome = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '1rem'
}))

const BoxTex = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '65%',
    border: '1px solid #B3BBC2',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    padding: '5.5rem 0',
    marginBottom: '3.338rem',
    [theme.breakpoints.down('lg')]: {
        width: '90%',
        padding: '3.5rem 0'
    },
    [theme.breakpoints.down('md')]: {
        marginBottom: '10rem',
        padding: '1.5rem 0'
    }
}))

const BoxTexDetail = styled(Box)(({ theme }) => ({
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '1.5rem',
    paddingTop: '1.5rem',
    borderBottom: '1px solid #B3BBC2',
    [theme.breakpoints.down('xl')]: {
        marginLeft: '1rem',
        width: '80%',
        marginRight: '1rem'
    }
}))

const TextWelcome = styled(Typography)(({ theme }) => ({
    font: 'normal normal bold 36px/54px Noto Sans Thai',
    letterSpacing: 0,
    color: '#3D9AEE'
}))

const TextSend = styled(Typography)(({ theme }) => ({
    font: 'normal normal normal 22px/60px Noto Sans Thai',
    letterSpacing: 0,
    color: '#1A191A',
    marginBottom: '1.83rem'
}))

const TextTittle = styled(Typography)(({ theme }) => ({
    font: 'normal normal normal 22px/40px Noto Sans Thai',
    letterSpacing: 0,
    color: '#2F2E2F'
}))

const TextLightGrey = styled(Typography)(({ theme }) => ({
    font: 'normal normal normal 22px/40px Noto Sans Thai',
    letterSpacing: 0,
    color: '#8D9193'
}))

const ButtonBackHome = styled(Button)(({ theme }) => ({
    width: '65%',
    height: '5.375rem',
    font: 'normal normal normal 22px/33px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#FFFFFF',
    background: 'transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box',
    border: '2px solid #3D9AEE',
    borderRadius: '10px',
    [theme.breakpoints.down('lg')]: {
        width: '90%'
    }
}))

const PaymentSummary = () => {
    const navigate = useNavigate()
    return (
        <>
            <BoxImgWelcome>
                <img src='/images/register/img_complate.png' alt='' />
            </BoxImgWelcome>
            <BoxTextWelcome>
                <TextWelcome>สำเร็จ</TextWelcome>
                <TextSend>ส่งคำขอเอกสารอิเล็กทรอนิกส์สำเร็จ</TextSend>
            </BoxTextWelcome>
            <Box sx={{ width: '100%', textAlign: '-webkit-center' }}>
                <BoxTex>
                    <BoxTexDetail>
                        <TextTittle>เลขที่คำขอรับใบกำกับภาษีแบบเต็มรูป</TextTittle>
                        <TextLightGrey>245678923 </TextLightGrey>
                    </BoxTexDetail>
                    <BoxTexDetail>
                        <TextTittle>วันเวลาที่ยื่น </TextTittle>
                        <TextLightGrey>12/12/2022 10.49 น.</TextLightGrey>
                    </BoxTexDetail>
                    <BoxTexDetail sx={{ border: 'none' }}>
                        <TextTittle>ช่องทางรับเอกสาร</TextTittle>
                        <TextLightGrey>รับด้วยตัวเองที่ ทอท. เท่านั้น</TextLightGrey>
                    </BoxTexDetail>
                </BoxTex>
            </Box>
            <Box
                sx={{ textAlignLast: 'center', marginBottom: '8.688rem' }}
                onClick={() => {
                    navigate('/home')
                }}
            >
                <ButtonBackHome>กลับหน้าหลัก</ButtonBackHome>
            </Box>
        </>
    )
}

export default PaymentSummary
