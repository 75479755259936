/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import InputTextFieldAOT from "../components/Controls/InputTextFieldAOT";
import BoxFeeParking from "../components/FeeParking/BoxFeeParking";
import BoxFeeParking4 from "../components/FeeParking/BoxFeeParking4";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { REGEX } from "../constants";
import PaymentService from "../services/payment-service";
import ONLINE_PAYMENT_STATUS from "../constants/payment";
import { useTranslation } from "react-i18next";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ConfigService from "../services/config-service";
import DatePickerAOT from "../components/Controls/DatePickerAOT";
import dayjs from "dayjs";
import GateService from "../services/gate-service";
import ContainerCenter from "../layouts/ContainerCenter";
import ButtonAction from "../components/Base/ButtonAction";
import PageContent from "../layouts/PageContent";
import { toast } from "react-toastify";
import ParkingFeeService from "../services/parkingfee-service";
import {
  INTERVAL_QR_QUERY,
  PROCESS_PAYMENT_STATUS,
  STATUS_PAYMENT,
  usePayment,
} from "../hooks/usePayment";
import {
  ChoosePaymentMethodStep,
  WaitPaymentStep,
} from "../components/Payment";
import {
  getAirportNameFormPmlsAndLang,
  getLicensePlateProvinceText,
  getPaymentTranslationKeyByPaymentMethod,
  successRequest,
  toastErr,
  toastWarning,
} from "../utils";
import LoadingSpinner from "../components/LoadingSpinner";
import useScanner from "../hooks/useScanner";
import WebcamModal from "../components/WebcamModal";
import PaymentPendingVerifyBox from "../components/Payment/PaymentPendingVerifyBox";
import PaymentCancelPendingSummary from "../components/Payment/PaymentCancelPendingSummary";
import DialogPaymentConfirmCancel from "../components/Payment/DialogPaymentConfirmCancel";
import { FEE_PARKING_RESPONSE_CODE } from "../constants/response-code";
import NotFoundTicket from "../components/FeeParking/NotFoundTicket";
import ErrorCalculate from "../components/FeeParking/ErrorCalculate";
import { isTimeoutTransactionByBankExpiredt } from "./QRTicketFeeParkingPage";
import PaymentExpired from "../components/Payment/PaymentExpired";
import { convertGmtToClientTimeZone } from "../utils/format";
import NotFoundEntryExit from "../components/FeeParking/NotFoundEntryExit";

export const STEP_FEE_PARKING = {
  SHOW_DETAIL: 1,
  CONFIRM_PAYMENT: 2,
  WAIT_PAYMENT: 3,
  RESULT_PAYMENT: 4,
  PENDING_TRANSACTION: 5,
  CANCELED_TRANSACTION: 6,
  CAR_ALREADY_EXIT: 7,
  NOT_FOUND: 8,
  ERROR_CALCULATE: 9,
  BANK_EXPIREDT: 10,
  ENTRY_EXIT_NOT_FOUND: 11,
};

const ParkingFee = () => {
  const { t, i18n } = useTranslation();
  const { pmlsId } = useSelector((state) => state.airport);

  const {
    method,
    checkedSendEmail,
    paymentStatus,
    receivePaymentConfirmEmail,
    onlinetransid,
    isPaymentPendingTransaction,
    openCancelTransactionDialog,
    receiptData,
    setReceiptData,
    onStopPaymentHandler,
    onChangePaymentMethod,
    onChangeIntervalId,
    onChangePaymentStatus,
    onSuccessPaymentHandler,
    onFailedPaymentHandler,
    onChangeReceivePaymentConfirmViaEmail,
    onChangeCheckedBoxSendEmailHandler,
    setOnlinetransid,
    setIsPaymentPendingTransaction,
    setOpenCancelTransactionDialog,
  } = usePayment();

  const {
    scannerValue,
    webcamRef,
    showScanner,
    videoConstraints,
    handleSwitchCamera,
    handleScannerClose,
    handleScannerOpen,
  } = useScanner();

  const [step, setStep] = useState(STEP_FEE_PARKING.SHOW_DETAIL);
  const [disabledBillBtn, setDisabledBillBtn] = useState(false);
  const [mode, setMode] = useState(1);
  const [loadingSearchButton, setLoadingSearchButton] = useState(false);

  const resetStateToInitial = () => {
    onStopPaymentHandler();
    setIsPaymentPendingTransaction(false);
    setReceiptData(undefined);
    setOpenCancelTransactionDialog(false);
  };

  const validationSchema = useMemo(() => {
    return Yup.object().shape(
      mode === 1
        ? {
            entrydate: Yup.string().required(),
            licenseplate: Yup.string().required(),
          }
        : {}
    );
  }, [mode]);

  useEffect(() => {
    setLoadingSearchButton(false);
  }, []);

  const formik = useFormik({
    initialValues: {
      ticketno: "",
      licenseplate: "",
      entrydate: "",
    },
    validationSchema,
    isInitialValid: false,
    onSubmit: (values) => {
      try {
        setLoadingSearchButton(true);
        setTimeout(() => {
          setLoadingSearchButton(false);
        }, 3000);
        resetStateToInitial();
        setStep(STEP_FEE_PARKING.SHOW_DETAIL);
        if (mode === 1) {
          const payload = {
            pmlsid: pmlsId,
            licenseplate: values.licenseplate,
            entrydate: values.entrydate,
          };
          ticketSearchMutate.mutate(payload);
        } else if (mode === 2) {
          parkingFeeCheckPendingPaymentMutate.mutate({
            pmlsid: pmlsId,
            ticketno: values.ticketno,
          });
        } else {
          toastErr("Something went wrong!");
        }
      } catch (err) {
        toastErr(err);
      }
    },
  });

  useEffect(() => {
    if (scannerValue) {
      formik.setFieldValue("ticketno", scannerValue);
    }
  }, [scannerValue]);

  const { data: configTicketLessData, isLoading: configTicketLessLoading } =
    useQuery({
      queryKey: ["GET_CONFIG_TICKET_LESS_PMLS", pmlsId],
      queryFn: () =>
        ConfigService.getEntryExitGlobalConfigFormPMLS({
          pmlsid: pmlsId,
          conf: "ticketless",
          confgroup: "ticket",
        }),
      select: (data) => data?.data?.config[0]?.ticketless,
    });

  useEffect(() => {
    const mode = configTicketLessData === "TRUE" ? 1 : 2;
    setMode(mode);
  }, [configTicketLessData]);

  const ticketSearchMutate = useMutation({
    mutationFn: (payload) => GateService.ticketSearch(payload),
    onSuccess: (response) => {
      if (response?.issuccess && response?.data?.length > 0) {
        const data = response.data[0];
        const ticketNo = data?.ticketno;
        if (ticketNo) {
          const payload = {
            pmlsid: pmlsId,
            ticketno: ticketNo.trim(),
          };
          parkingFeeCalculateMutate.mutate(payload);
        } else {
          toast.error("ไมพบหมายเลขบัตรจอดรถ", {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else if (!response?.issuccess) {
        toast.error("ไม่พบการค้นหา", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    },
    onError: (err) => {
      toast.error(err, {
        position: "top-center",
        autoClose: 1000,
      });
    },
  });

  const parkingFeeCheckPendingPaymentMutate = useMutation({
    mutationFn: (body) => PaymentService.parkingFeeCheckPendingPayment(body),
    onSuccess: async (res) => {
      const { ouMessage, ouReasonCode, data } = res;
      const parkingPayment = data?.parkingpayment[0] || {};

      //? 2534 - จ่ายค่าจอดรถ: ค้นตั๋วหรือสร้างใบเสร็จ แล้วเจอ error Entry Exit Not Found
      if (
        parkingPayment?.createreceipterrormessage === "Entry Exit Not Found"
      ) {
        setStep(STEP_FEE_PARKING.ENTRY_EXIT_NOT_FOUND);
        return;
      }

      const isNoPendingOrFinalized =
        (ouMessage === "Parking Pay Hdr Req Not Found" &&
          ouReasonCode === "100207") ||
        parkingPayment.parkingpaymentstatus === PROCESS_PAYMENT_STATUS.CANCEL ||
        parkingPayment.parkingpaymentstatus === PROCESS_PAYMENT_STATUS.CONFIRM;

      //? เลยเวลาจ่ายเงิน ให้ cancel payment transactions ล่าสุดทิ่งแล้วยิง calculate ใหม่
      const isRequestWithExpiredDate = isTimeoutTransactionByBankExpiredt(
        parkingPayment.parkingpaymentstatus,
        parkingPayment.bankexpiredt
      );

      if (isNoPendingOrFinalized) {
        await calculateParkingFee();
      } else if (isRequestWithExpiredDate) {
        await cancelAndRecalculateFee(parkingPayment?.onlinetransid);
      } else if (
        parkingPayment.parkingpaymentstatus === PROCESS_PAYMENT_STATUS.REQUEST
      ) {
        if (
          !parkingPayment?.bankexpiredt &&
          parkingPayment.createreceipterrormessage !== ""
        ) {
          toastErr(parkingPayment.createreceipterrormessage);
        }
        setOnlinetransid(parkingPayment?.onlinetransid);
        setIsPaymentPendingTransaction(true);
        setStep(STEP_FEE_PARKING.PENDING_TRANSACTION);
      } else {
        toastErr(ouMessage);
      }
    },
    onError: (res) => {
      toastErr(res?.message);
    },
  });

  const parkingFeeCalculateMutate = useMutation({
    mutationFn: (body) => ParkingFeeService.parkingFeeCalculate(body),
    onSuccess: (response) => {
      const { responsecode, issuccess } = response || {};
      if (issuccess && responsecode) {
        if (
          [
            FEE_PARKING_RESPONSE_CODE.CAR_GOT_EXIT_PARKING_ALREADY,
            FEE_PARKING_RESPONSE_CODE.NOT_FOUND,
          ].includes(responsecode)
        ) {
          setDisabledBillBtn(true);
          setStep(STEP_FEE_PARKING.NOT_FOUND);
        } else if (responsecode === FEE_PARKING_RESPONSE_CODE.CALCULATE_ERROR) {
          setDisabledBillBtn(true);
          setStep(STEP_FEE_PARKING.ERROR_CALCULATE);
        }
      }
    },
    onError: (response) => {
      toastErr(response?.message);
    },
  });

  const parkingFeeCancelTransactionMutate = useMutation({
    mutationFn: (body) => PaymentService.parkingFeeCheckCancelTransaction(body),
    onSuccess: (res) => {
      if (!successRequest(res)) {
        //? ชำระเงินแล้วแต่กด Cancel รายการชำระเงินที่ค้างไว้
        if (res?.ouReasonCode === "100210") {
          checkPaymentResult(onlinetransid, true);
        } else {
          toastErr(res?.ouMessage);
        }
      } else if (successRequest(res)) {
        setStep(STEP_FEE_PARKING.CANCELED_TRANSACTION);
      }
    },
    onError: (res) => {
      toastErr(res?.message);
    },
  });

  const createPaymentQRMutate = useMutation({
    mutationFn: async (payload) =>
      PaymentService.createParkingFeePaymentQR(payload),
    onSuccess: (response) => {
      if (successRequest(response) && response?.data?.onlinetransid) {
        setOnlinetransid(response?.data?.onlinetransid);
        setStep(STEP_FEE_PARKING.WAIT_PAYMENT);
        onChangePaymentStatus(STATUS_PAYMENT.WAIT);
      } else {
        if (
          response?.ouReasonCode === "100134" &&
          response?.ouMessage === "Entry Exit Not Found"
        ) {
          toast.error(t("Parkingfee changed"), {
            position: "top-center",
            autoClose: 1200,
            onClose: () => window.location.reload(),
          });
        } else {
          toastErr(response?.ouMessage);
          onFailedPaymentHandler();
        }
      }
    },
    onError: (response) => {
      toastErr(response);
      onFailedPaymentHandler();
    },
    select: (data) => data?.data,
  });

  //loop
  const checkPaymentResultMutate = useMutation({
    mutationFn: async (payload) =>
      PaymentService.parkingFeeCheckResultPayment({
        pmlsid: pmlsId,
        onlinetransid: payload.onlinetransid,
      }),
    onSuccess: async (response, variable) => {
      if (variable?.skipResponseLogic) {
        return;
      }
      const resultPaymentData = response?.data?.TbParkingPayHdrReq || {};
      //? เลยเวลาชำระเงิน -> cancelAndRecalculateFee() -> first step show detail
      if (
        isTimeoutTransactionByBankExpiredt(
          parkingFeeCheckPendingData?.parkingpaymentstatus,
          parkingFeeCheckPendingData?.bankexpiredt
        )
      ) {
        callBackWhenPaymentExpired(resultPaymentData?.onlinetransid);
      } else {
        //? ถ้าเป็นการเรียกมาจากการกด Cancel Transaction ที่มีการชำระเงินแล้ว จะเข้าเงื่อนไขนี้ได้เลย
        if (
          (successRequest(response) && resultPaymentData) ||
          variable.isCancelTransaction
        ) {
          if (
            resultPaymentData?.status === PROCESS_PAYMENT_STATUS.CONFIRM ||
            variable?.isCancelTransaction
          ) {
            createReceipt(resultPaymentData).then(() => {
              if (variable?.isCancelTransaction) {
                toastErr(t("Your payment has already been paid"));
              }
            });
          }
        }
      }
    },
    onError: (response) => {
      toastErr("Error occurred!, Please contact admin :", response?.message);
      onFailedPaymentHandler();
      setStep(STEP_FEE_PARKING.SHOW_DETAIL);
    },
  });

  const qrQueryStatusMutate = useMutation({
    mutationFn: async (payload) => PaymentService.queryQRStatus(payload),
    onSuccess: async (response) => {
      const onlinePaymentData = response?.data?.tbonlinepayment;
      if (
        response?.ouIsComplete === 1 &&
        onlinePaymentData?.responsestatus === "200" &&
        onlinePaymentData?.responsedt !== null
      ) {
        setOnlinetransid(onlinePaymentData?.onlinetransid);
        checkPaymentResultMutate
          .mutateAsync({
            onlinetransid: onlinePaymentData?.onlinetransid,
            skipResponseLogic: true,
          })
          .then((res) => {
            if (successRequest(res)) {
              createReceipt(res?.data?.TbParkingPayHdrReq);
            }
          });
      } else if (onlinePaymentData?.responsestatus !== null) {
        const paymentStatusDesc =
          ONLINE_PAYMENT_STATUS[Number(onlinePaymentData?.responsestatus)]
            ?.description;
        toastErr(paymentStatusDesc);
        onFailedPaymentHandler();
        setStep(STEP_FEE_PARKING.SHOW_DETAIL);
      } else if (onlinePaymentData?.responsestatus === null) {
        //ยังไม่จ่าย เรียก /mapp/payment/online/parkingfee/checkpaymentresult (loop) ต่อ
        toastWarning(t("Not found your payment"));
        onChangePaymentStatus(STATUS_PAYMENT.WAIT); //? useEffect for loop
      }
    },
    onError: () => {
      toastErr("Error occurred!, Please contact admin.");
      onFailedPaymentHandler();
      setStep(STEP_FEE_PARKING.SHOW_DETAIL);
    },
  });

  const createReceiptParkingFee = useMutation({
    mutationFn: (payload) => PaymentService.createReceiptParkingFee(payload),
    onSuccess: async (response) => {
      if (successRequest(response)) {
        onSuccessPaymentHandler();
        setReceiptData(response?.data);
        await calculateParkingFee().then(() => {
          setStep(STEP_FEE_PARKING.RESULT_PAYMENT);
        });
      } else {
        //? 2534 - จ่ายค่าจอดรถ: ค้นตั๋วหรือสร้างใบเสร็จ แล้วเจอ error Entry Exit Not Found
        if (
          response?.ouReasonCode === "100134" &&
          response?.ouMessage === "Entry Exit Not Found"
        ) {
          setStep(STEP_FEE_PARKING.ENTRY_EXIT_NOT_FOUND);
          return;
        } else if (!response?.ouReasonCode === "800005") {
          toastErr(response?.ouMessage);
        }
      }
    },
    onError: (response) => {
      toastErr(response);
    },
  });

  const parkingFeeCalculateData = parkingFeeCalculateMutate?.data?.data || {};
  const parkingFeeCheckPendingData =
    parkingFeeCheckPendingPaymentMutate?.data?.data?.parkingpayment[0] || {};
  const checkPaymentResultData =
    checkPaymentResultMutate?.data?.data?.TbParkingPayHdrReq || {};
  const createReceiptParkingFeeData = createReceiptParkingFee?.data?.data || {};

  useEffect(() => {
    let newIntervalId = null;
    if (paymentStatus === STATUS_PAYMENT.WAIT && onlinetransid) {
      newIntervalId = setInterval(() => {
        checkPaymentResult(onlinetransid);
      }, INTERVAL_QR_QUERY);
      onChangeIntervalId(newIntervalId);
    }

    return () => {
      clearInterval(newIntervalId);
    };
  }, [paymentStatus, onlinetransid]);

  const checkPaymentResult = async (
    _onlinetransid,
    isCancelTransaction = false
  ) => {
    try {
      const response = await checkPaymentResultMutate.mutateAsync({
        onlinetransid: _onlinetransid,
        isCancelTransaction,
      });
      return response;
    } catch (error) {
      toastErr(error);
    }
  };

  const qrQueryStatus = (_onlinetransid) => {
    if (!_onlinetransid) {
      toastErr("onlinetransid not found!");
      return;
    }
    onStopPaymentHandler();
    const payloadQRQueryStatus = {
      pmlsid: pmlsId,
      onlinetransid: _onlinetransid,
    };
    qrQueryStatusMutate.mutate(payloadQRQueryStatus);
  };

  const createReceipt = async (resultPaymentData) => {
    if (!resultPaymentData) {
      toastErr("result payment data not found!");
      return;
    }

    //2094 - ก่อนสร้างใบเสร็จ เพิ่มเรียก checkpending -> เรียกเฉยๆ ไม่ต้องเช็คอะไร เมื่อเรียกเส้นนี้แล้ว หลังบ้านจะมีไปอัพเดทข้อมูล
    await PaymentService.parkingFeeCheckPendingPayment({
      pmlsid: pmlsId,
      ticketno: formik?.values?.ticketno,
    });

    if (resultPaymentData?.parkingpaydtlreq?.length > 0) {
      const parkingpaydtlreqData = resultPaymentData?.parkingpaydtlreq[0] || {};
      const createReceiptPayload = {
        pmlsid: pmlsId,
        transtype: resultPaymentData?.transtype,
        paytype: resultPaymentData?.paytype,
        totalamount: Number(resultPaymentData?.totalamount),
        paysourcecode: resultPaymentData?.paysourcecode,
        ticketno: resultPaymentData?.ticketno,
        memberno: resultPaymentData?.memberno,
        remark: resultPaymentData?.remark,
        refreceiptno: resultPaymentData?.refreceiptno,
        receipttype: resultPaymentData?.receipttype,
        detail: [
          {
            itemno: parkingpaydtlreqData?.itemno,
            unitamount: Number(parkingpaydtlreqData?.unitamount),
            qty: parkingpaydtlreqData?.qty,
            itemdesc1: parkingpaydtlreqData?.itemdesc1,
            itemdesc2: parkingpaydtlreqData?.itemdesc2,
            itemdesc3: parkingpaydtlreqData?.itemdesc3,
            parkingfeeid: parkingpaydtlreqData?.parkingfeeid,
          },
        ],
        entrydt: resultPaymentData?.entrydt,
        exitdt: resultPaymentData?.exitdt,
        exitbefore: resultPaymentData?.exitbefore,
        duration_day: resultPaymentData?.duration_day,
        duration_hour: resultPaymentData?.duration_hour,
        duration_minute: resultPaymentData?.duration_minute,
        productcode: resultPaymentData?.productcode,
        refid: resultPaymentData?.refid,
        displaydelaytime: resultPaymentData?.displaydelaytime,
        printcount: resultPaymentData?.printcount,
        info1: resultPaymentData?.info1,
        info2: resultPaymentData?.info2,
        email: checkedSendEmail ? receivePaymentConfirmEmail : null,
      };

      return await createReceiptParkingFee.mutateAsync(createReceiptPayload);
    }
  };

  const onClickVerifyPaymentBtnHandler = async () => {
    const onlinetransidToVerify = isPaymentPendingTransaction
      ? parkingFeeCheckPendingData.onlinetransid
      : onlinetransid;

    qrQueryStatus(onlinetransidToVerify);
  };

  const onClickCancelTransactionBtnHandler = async () => {
    setOpenCancelTransactionDialog(true);
  };

  //? Confirm cancel transaction -> "YES" click!
  const onClickYesDialogCancelTransactionBtnHandler = () => {
    try {
      setOpenCancelTransactionDialog(false);
      onChangePaymentStatus(STATUS_PAYMENT.STOP_PAYMENT);
      cancelParkingFee(parkingFeeCheckPendingData?.onlinetransid);
    } catch (err) {
      toastErr(err);
    }
  };
  //? Confirm cancel transaction -> "NO" click!
  const onClickNoDialogCancelTransactionBtnHandler = () => {
    setOpenCancelTransactionDialog(false);
  };

  const onClickMakeNewTransactionBtnHandler = () => {
    setStep(STEP_FEE_PARKING.SHOW_DETAIL);
  };

  const callBackWhenPaymentExpired = async (_onlinetransid) => {
    onStopPaymentHandler();
    cancelParkingFee(_onlinetransid).then((res) => {
      setStep(STEP_FEE_PARKING.BANK_EXPIREDT);
    });
  };

  const calculateParkingFee = async () => {
    if (!pmlsId || !formik?.values?.ticketno) {
      return;
    }
    await parkingFeeCalculateMutate.mutateAsync({
      pmlsid: pmlsId,
      ticketno: formik?.values?.ticketno,
    });
  };

  const cancelParkingFee = async (onlinetransid) => {
    if (!onlinetransid) {
      return;
    }
    return await parkingFeeCancelTransactionMutate.mutateAsync({
      pmlsid: pmlsId,
      onlinetransid: onlinetransid,
    });
  };

  const cancelAndRecalculateFee = async (onlinetransid) => {
    await cancelParkingFee(onlinetransid);
    await calculateParkingFee();
  };

  const onClickPaymentBtnHandler = () => {
    try {
      const feeIdPad = String(parkingFeeCalculateData?.feeid).padStart(9, "0");
      const ticketNoPad = String(parkingFeeCalculateData?.ticketno).padStart(
        9,
        "0"
      );
      const payload = {
        pmlsid: pmlsId,
        amount: parkingFeeCalculateData?.fee,
        paymentsource: "09",
        paymentref1: feeIdPad + ticketNoPad,
        clientid: "",
        refsource: 0,
        refip: "",
        transtype: "T01",
        paytype: "02",
        ticketno: formik.values.ticketno,
        memberno: "",
        remark: "",
        refreceiptno: "",
        receipttype: "01",
        productcode: "",
        refid: "",
        info1: parkingFeeCalculateData?.licenseplate,
        info2: "",
        duration_day: parkingFeeCalculateData?.durationday,
        duration_hour: parkingFeeCalculateData?.durationhour,
        duration_minute: parkingFeeCalculateData?.durationminute,
        printcount: 0,
        entrydt: parkingFeeCalculateData?.start,
        exitdt: parkingFeeCalculateData?.end,
        exitbefore: parkingFeeCalculateData?.exitbefore,
        displaydelaytime: parkingFeeCalculateData?.displaydelaytime,
        items: [
          {
            itemdesc1: parkingFeeCalculateData?.itemdesc1,
            itemdesc2: parkingFeeCalculateData?.itemdesc2,
            itemdesc3: parkingFeeCalculateData?.itemdesc3,
            itemno: 1,
            qty: 1,
            unitamount: parkingFeeCalculateData?.fee,
            parkingfeeid: parkingFeeCalculateData?.feeid,
          },
        ],
        email: checkedSendEmail ? receivePaymentConfirmEmail : null,
      };
      createPaymentQRMutate.mutate(payload);
    } catch (err) {
      toastErr(err);
    }
  };

  //? show data in result payment step
  const resultPaymentReceiptDisplay = useMemo(() => {
    const isPending = isPaymentPendingTransaction && checkPaymentResultData;
    const paymentData = isPending
      ? checkPaymentResultData
      : createReceiptParkingFeeData?.payment;
    const parkingData = checkPaymentResultData;

    // Helper function to format the total parking time
    const formatDuration = (days, hours, minutes) => {
      return `${days ? days + ` ${t("Day")} ` : ""}${
        hours ? hours + ` ${t("Hour")} ` : ""
      }${minutes ? minutes + ` ${t("Minute")}` : ""}`.trim();
    };

    const totalParkingTime = formatDuration(
      parkingData?.duration_day || parkingData?.durationday,
      parkingData?.duration_hour || parkingData?.durationhour,
      parkingData?.duration_minute || parkingData?.durationminute
    );

    const airportName = getAirportNameFormPmlsAndLang(pmlsId, i18n?.language);

    return {
      airport: airportName,
      licensePlate: isPending
        ? parkingData?.info1
        : getLicensePlateProvinceText(parkingFeeCalculateData),
      start: parkingData?.entrydt || parkingFeeCalculateData?.start,
      totalParkingTime,
      paymentMethod: getPaymentTranslationKeyByPaymentMethod(method),
      fee: parkingFeeCalculateData?.fee,
      totalamount: paymentData?.totalamount,
      displaydelaytime: parkingData?.displaydelaytime,
    };
  }, [
    isPaymentPendingTransaction,
    checkPaymentResultData,
    createReceiptParkingFeeData,
    parkingFeeCalculateData,
    method,
    pmlsId,
    i18n?.language,
  ]);

  if (configTicketLessLoading) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  }

  const displayInformationData = {
    ...parkingFeeCalculateData,
    airport: getAirportNameFormPmlsAndLang(pmlsId, i18n?.language),
    licensePlate: getLicensePlateProvinceText(parkingFeeCalculateData),
  };

  const confirmPaymentDetails = [
    {
      title: t("License Plate"),
      value: displayInformationData?.licensePlate,
    },
    {
      title: t("Entry Date Time"),
      value:
        (parkingFeeCalculateData?.start &&
          dayjs(parkingFeeCalculateData?.start).format("DD/MM/YYYY HH:mm")) ||
        "-",
    },
    {
      title: t("Exit Date Time"),
      value:
        (parkingFeeCalculateData?.end &&
          dayjs(parkingFeeCalculateData?.end).format("DD/MM/YYYY HH:mm")) ||
        "-",
    },
    {
      title: t("Total Parking Time"),
      value: `${
        parkingFeeCalculateData?.durationday
          ? parkingFeeCalculateData?.durationday + ` ${t("Day")}`
          : ""
      }
      ${
        parkingFeeCalculateData?.durationhour
          ? parkingFeeCalculateData?.durationhour + ` ${t("Hour")}`
          : ""
      } ${
        parkingFeeCalculateData?.durationminute
          ? parkingFeeCalculateData?.durationminute + ` ${t("Minute")}`
          : ""
      }`,
    },
  ];

  const waitPaymentTopHeaderDisplay = (
    <div className="border-b border-solid w-full pb-3 text-center">
      <p className="text-sm md:text-base text-red-500">
        {t("QR code for parking fee payment")}{" "}
        {`${displayInformationData?.licensePlate}`}
        <br />
        {t("Entry Date Time")}{" "}
        {(displayInformationData?.start &&
          dayjs(displayInformationData?.start).format("DD/MM/YYYY HH:mm")) ||
          ""}{" "}
        <br />
        {t("and can be used to pay only 1 time")}
      </p>
    </div>
  );

  const loadingConfirmPayment = createPaymentQRMutate.isLoading;

  return (
    <>
      <div className="bg-[#E9F0F5] pt-5 md:pt-[150px]">
        <ContainerCenter>
          <form onSubmit={formik.handleSubmit} className="w-full">
            {mode === 1 ? (
              <div>
                <p className="font-bold text-xl mb-2">
                  {t("Check a Parking Fee")} -{" "}
                  <span className="font-normal text-lg">
                    {t("Please specify entry date and license plate")}
                  </span>
                </p>
                <div className="flex flex-col md:flex-row items-center w-full gap-6 pb-4">
                  <div className="w-full md:w-[50%]">
                    <DatePickerAOT
                      label={t("Apply Start Date")}
                      name="entrydate"
                      val={formik.values.entrydate}
                      handleChange={(e) => {
                        let selectedDate = dayjs(e).format("YYYY-MM-DD");
                        formik.setFieldValue("entrydate", selectedDate);
                      }}
                      required
                      sxa={{ background: "white" }}
                    />
                  </div>
                  <div className="flex items-center gap-x-4 w-full">
                    <div className="w-[80%]">
                      <InputTextFieldAOT
                        label={t("License Plate")}
                        name="licenseplate"
                        val={formik.values.licenseplate}
                        handleChange={formik.handleChange}
                      />
                    </div>

                    <ButtonAction
                      type="submit"
                      color="primary"
                      disabled={!formik.isValid || loadingSearchButton}
                    >
                      {t("Search")}
                    </ButtonAction>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full">
                <p className="font-bold text-xl mb-2">
                  {t("Check a Parking Fee")} -{" "}
                  <span className="font-normal text-lg">
                    {t(
                      "Calculate the parking fee by scanning the QR Code or typing the Parking Card ID"
                    )}
                  </span>
                </p>
                <div className="flex items-center gap-8 pb-5 ">
                  <div className="w-full">
                    <InputTextFieldAOT
                      label={t("Parking Card No")}
                      name="ticketno"
                      val={formik.values.ticketno}
                      handleChange={formik.handleChange}
                      sxb={{
                        endAdornment: (
                          <QrCodeScannerIcon
                            onClick={handleScannerOpen}
                            edge="end"
                            className="cursor-pointer"
                          />
                        ),
                      }}
                    />
                  </div>
                  <div className="w-full md:w-[40%] lg:w-[20%]">
                    <ButtonAction
                      type="submit"
                      disabled={
                        !formik.isValid ||
                        step === STEP_FEE_PARKING.WAIT_PAYMENT ||
                        loadingSearchButton
                      }
                    >
                      {t("Search")}
                    </ButtonAction>
                  </div>
                </div>
              </div>
            )}
          </form>
        </ContainerCenter>
      </div>

      <PageContent
        breadcrumb={
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              {t("Home")}
            </Link>
            <Typography color="#245AB1">{t("Check a Parking Fee")}</Typography>
          </Breadcrumbs>
        }
      >
        {step === STEP_FEE_PARKING.SHOW_DETAIL ? (
          <BoxFeeParking
            displayInformationData={displayInformationData}
            disabledBillBtn={disabledBillBtn}
            onClickPaymentButtonHandler={() =>
              setStep(STEP_FEE_PARKING.CONFIRM_PAYMENT)
            }
          />
        ) : loadingConfirmPayment ? (
          <LoadingSpinner />
        ) : step === STEP_FEE_PARKING.CONFIRM_PAYMENT ? (
          <div className="space-y-8">
            <ChoosePaymentMethodStep
              isPaidMode
              parkingFeeCalculateData={parkingFeeCalculateData}
              boxTitleText={t("Parking Fee Summary")}
              onChangePaymentMethod={onChangePaymentMethod}
              airportText={displayInformationData?.airport}
              method={method}
              details={confirmPaymentDetails}
              receivePaymentConfirm
              receivePaymentConfirmFn={{
                emailIsValid: REGEX.EMAIL.test(receivePaymentConfirmEmail),
                checkedSendEmail: checkedSendEmail,
                sendEmailValue: receivePaymentConfirmEmail,
                setCheckedSendEmail: (value) => {
                  onChangeCheckedBoxSendEmailHandler(value);
                },
                setSendEmailValue: (value) => {
                  onChangeReceivePaymentConfirmViaEmail(value);
                },
              }}
            />
            <div className="flex flex-col lg:flex-row gap-4 lg:gap-10">
              <ButtonAction
                variant="secondary"
                onClick={() => {
                  setStep(STEP_FEE_PARKING.SHOW_DETAIL);
                }}
              >
                {t("Back")}
              </ButtonAction>
              <ButtonAction
                onClick={onClickPaymentBtnHandler}
                disabled={parkingFeeCalculateData?.fee === 0}
              >
                {t("Confirm Payment")}
              </ButtonAction>
            </div>
          </div>
        ) : step === STEP_FEE_PARKING.WAIT_PAYMENT ? (
          <WaitPaymentStep
            waitPaymentTopHeaderDisplay={waitPaymentTopHeaderDisplay}
            requestData={createPaymentQRMutate?.data?.data}
            showCheckPaymentButton
            onClickCheckPaymentBtnHandler={onClickVerifyPaymentBtnHandler}
            callBackWhenPaymentExpired={() => {
              //จากหน้าชำระเงินปกติ ใช้ interval เช็ค bankexpiredt
              callBackWhenPaymentExpired(onlinetransid);
            }}
          />
        ) : step === STEP_FEE_PARKING.RESULT_PAYMENT ? (
          <BoxFeeParking4
            receiptNo={receiptData?.payment?.receiptno}
            completedTransactionDate={receiptData?.payment?.createdt}
            paymentMethod={method}
            resultPaymentReceiptDisplay={resultPaymentReceiptDisplay}
            isPaymentPendingTransaction={isPaymentPendingTransaction}
            onClickAdditionalPaymentBtnHandler={
              onClickMakeNewTransactionBtnHandler
            }
          />
        ) : step === STEP_FEE_PARKING.PENDING_TRANSACTION ? (
          <PaymentPendingVerifyBox
            onlinetransid={parkingFeeCheckPendingData?.onlinetransid}
            bankexpiredt={convertGmtToClientTimeZone(
              parkingFeeCheckPendingData?.bankexpiredt
            )}
            onClickVerifyPaymentBtnHandler={onClickVerifyPaymentBtnHandler}
            onClickCancelTransactionBtnHandler={
              onClickCancelTransactionBtnHandler
            }
          />
        ) : step === STEP_FEE_PARKING.CANCELED_TRANSACTION ? (
          <PaymentCancelPendingSummary
            onClickMakeNewTransactionBtnHandler={
              onClickMakeNewTransactionBtnHandler
            }
          />
        ) : step === STEP_FEE_PARKING.NOT_FOUND ||
          step === STEP_FEE_PARKING.CAR_ALREADY_EXIT ? (
          <NotFoundTicket />
        ) : step === STEP_FEE_PARKING.ERROR_CALCULATE ? (
          <ErrorCalculate />
        ) : step === STEP_FEE_PARKING.BANK_EXPIREDT ? (
          <PaymentExpired
            onClickMakeNewTransactionBtnHandler={
              onClickMakeNewTransactionBtnHandler
            }
          />
        ) : step === STEP_FEE_PARKING.ENTRY_EXIT_NOT_FOUND ? (
          <NotFoundEntryExit />
        ) : null}
      </PageContent>

      <WebcamModal
        showScanner={showScanner}
        videoConstraints={videoConstraints}
        webcamRef={webcamRef}
        handleScannerClose={handleScannerClose}
        handleSwitchCamera={handleSwitchCamera}
      />
      <DialogPaymentConfirmCancel
        open={openCancelTransactionDialog}
        setOpen={setOpenCancelTransactionDialog}
        onClickYesBtn={onClickYesDialogCancelTransactionBtnHandler}
        onClickNoBtn={onClickNoDialogCancelTransactionBtnHandler}
      />
    </>
  );
};

export default ParkingFee;
