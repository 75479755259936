import React from "react";
import { FirstStepper, NextStepper } from "../Controls/StepperAOT";
import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const ChangeVehicleStepper = ({ sxa, activeStep }) => {
  const isSMScr = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ width: "100%", ...sxa }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <FirstStepper
            label={
            `1. ${t('Enter Registered Car Changing Information')}`
            }
            sxbox={{
              background:
                activeStep >= 1
                  ? "transparent linear-gradient(180deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box"
                  : "#B1BCC4 0% 0% no-repeat padding-box",
              borderColor: activeStep === 1 ? "#3D9AEE" : "#B3BBC2",
            }}
            sximg={{ display: "none" }}
          />
          <NextStepper
            label={`2. ${t('Submit')}`}
            sxbox={{
              background:
                activeStep >= 2
                  ? "transparent linear-gradient(180deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box"
                  : "#B1BCC4 0% 0% no-repeat padding-box",
              borderColor: activeStep === 2 ? "#3D9AEE" : "#B1BCC4",
            }}
            sxline={{ borderColor: "#3D9AEE" }}
            sximg={{ display: "none" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default ChangeVehicleStepper;
