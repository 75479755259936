import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const BoxImgWelcome = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "200px",
  justifyContent: "center",
  position: "relative",
}));

const BoxTextWelcome = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "1rem",
}));

const BoxTex = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "50%",
  border: "1px solid #B3BBC2",
  flexDirection: "column",
  alignItems: "center",
  alignSelf: "center",
  marginBottom: "5rem",
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "5rem",
  },
}));

const BoxTexDetail = styled(Box)(({ theme }) => ({
  width: "60%",
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "1rem",
  paddingTop: "1rem",
  borderBottom: "1px solid #B3BBC2",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

const BoxGreyRegister = styled(Box)(() => ({
  display: "flex",
  // padding: '2rem 0',
  // transform: 'translate(-95px, 10px)'
}));

const TextWelcome = styled(Typography)(({ theme }) => ({
  font: "normal normal bold 25px/54px Noto Sans Thai",
  letterSpacing: 0,
  color: "#3D9AEE",
  marginBottom: "0.2rem",
}));

const TextSendBottom = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 24px/54px Noto Sans Thai",
  letterSpacing: 0,
  color: "#1A191A",
  marginBottom: "1rem",
}));

const TextSend = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 36px/54px Noto Sans Thai",
  letterSpacing: 0,
  color: "#1A191A",
}));

const TextTittle = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 20px/40px Noto Sans Thai",
  letterSpacing: 0,
  color: "#1A191A",
}));

const TextLightGrey = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 20px/40px Noto Sans Thai",
  letterSpacing: 0,
  color: "#8D9193",
}));

const BoxButtonBackHome = styled(Box)(({ theme }) => ({
  width: "100%",
  //   display: "flex",
  //   justifyContent: "center",
}));

const ButtonBackHome = styled(Button)(() => ({
  width: "60%",
  height: "4rem",
  font: "normal normal normal 22px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  background:
    "transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box",
  //   border: "2px solid #3D9AEE",
  borderRadius: "10px",
}));

const ImgRegCar = styled((props) => <Box component="img" {...props} />)(
  ({ theme }) => ({
    width: "3.6rem",
    height: "2.6rem",
    marginRight: "2rem",
    [theme.breakpoints.down("lg")]: {
      //display: 'none'
    },
  })
);

const RegisterParkingSummary = ({ formik }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center flex-col py-10 w-full">
      <div className="flex gap-2 self-start">
        <ImgRegCar src="/images/auth/ico_name_carpark.svg" />
        <Typography
          sx={{
            font: "normal normal 600 24px/54px Noto Sans Thai",
            letterSpacing: "0px",
            color: "#2F2E2F",
          }}
        >
          {t("Apply for Parking Membership")}
        </Typography>
      </div>
      <BoxImgWelcome>
        <img src="/images/register/img_complate.png" alt="" />
      </BoxImgWelcome>
      <BoxTextWelcome>
        <TextWelcome>{t("Completed")}</TextWelcome>
        {/* <TextSend>คุณส่งข้อมูล</TextSend> */}
        <p className="text-[#1A191A] text-lg md:text-2xl mb-10">
          {t("You have successfully submitted a membership")}
        </p>
      </BoxTextWelcome>
      <BoxTex>
        <BoxTexDetail>
          <TextTittle>{t("Member Number")} :</TextTittle>
          <TextLightGrey>{formik.values.memberno ? formik.values.memberno : '-'}</TextLightGrey>
        </BoxTexDetail>
        <BoxTexDetail sx={{ borderBottom: "none" }}>
          <TextTittle>{t("Submitted Date Apply")} :</TextTittle>
          <TextLightGrey>{dayjs().format("DD/MM/YYYY HH:mm")}</TextLightGrey>
        </BoxTexDetail>
      </BoxTex>
      <div className="w-full self-center flex items-center justify-center"
        onClick={() => {
          navigate("/home");
        }}
      >
        <ButtonBackHome>{t("Back to Home")}</ButtonBackHome>
      </div>
    </div>
  );
};

export default RegisterParkingSummary;
