import { Box } from '@mui/material'
import React from 'react'

export const FirstStepper = ({ label, sxbox, sximg }) => {
    return (
        <Box sx={{ flex: '1 1 0%', position: 'relative' }}>
            <Box component='span' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box component='span' sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {label}
                </Box>
                <Box component='span'>
                    <Box
                        sx={{
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            textAlign: 'center',
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            border: '1px solid #1A4E91',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ...sxbox
                        }}
                    >
                        <Box component='img' alt='' src='' sx={{ width: '20px', height: '20px', ...sximg }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export const NextStepper = ({ label, sxline, sxbox, sximg }) => {
    return (
        <Box sx={{ flex: '1 1 0%', position: 'relative' }}>
            <Box
                sx={{
                    display: 'block',
                    flex: '1 1 auto',
                    position: 'absolute',
                    top: '0px',
                    left: 'calc(-50% + 15px)',
                    right: 'calc(50% + 15px)',
                    textAlign: 'center',
                    transform: 'translateY(36px)'
                }}
            >
                <Box
                    component='span'
                    sx={{ display: 'block', border: '2px solid #B1B4B8', backgroundColor: '#B1B4B8', ...sxline }}
                />
            </Box>
            <Box component='span' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box component='span' sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {label}
                </Box>
                <Box component='span'>
                    <Box
                        sx={{
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                            textAlign: 'center',
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            border: '1px solid #1A4E91',
                            //
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ...sxbox
                        }}
                    >
                        <Box component='img' alt='' src='' sx={{ width: '20px', height: '20px', ...sximg }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
