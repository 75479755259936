import React from "react";
import InputTextFieldAOT from "../../components/Controls/InputTextFieldAOT";
// import BoxAppParking from "../../components/Login/BoxAppParking";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthUserLayout from "../../components/Layouts/AuthUserLayout";
import ButtonAction from "../../components/Base/ButtonAction";

const validationSchema = Yup.object({
  email: Yup.string().email().required("email is required"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      console.log(values);
    },
    isInitialValid: false,
    validationSchema,
  });

  const handleChangeEmail = (e) => {
    formik.setFieldValue("email", e.target.value);
  };

  return (
    <AuthUserLayout contentCenter>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className="w-full"
      >
        <p className="text-2xl font-semibold text-center">
          {t("Forget Password")}
        </p>
        <p className="text-base font-semibold text-center">
          {t("Please enter your email to reset password")}
        </p>
        <InputTextFieldAOT
          label={t("Email")}
          name="email"
          width="100%"
          sxa={{ height: "50px", marginTop: 7, marginBottom: 5 }}
          handleChange={handleChangeEmail}
        />
        <ButtonAction type="submit" disabled={!formik.isValid}>
          {t("Submit")}
        </ButtonAction>
        <p
          className="mt-4 font-semibold cursor-pointer text-center"
          onClick={() => navigate("/auth/user/login")}
        >
          {t("Back")}
        </p>
      </form>
    </AuthUserLayout>
  );
};

export default ResetPassword;
