import { FormControl, FormControlLabel, Radio, RadioGroup, styled } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ReceiptRadio = styled((props) => <Radio {...props} />)(({ theme }) => ({
    color: '#3C5AAD',
    '&.Mui-checked': {
        color: '#3C5AAD'
    },
    '& .MuiSvgIcon-root': {
        fontSize: '30px'
    },
    [theme.breakpoints.down('lg')]: {
        //display: 'none'
    }
}))

const ReceiptRadioAOT = ({name, onChange}) => {
    const {t} = useTranslation()
    return (
        <FormControl>
            <RadioGroup row name={name} onChange={onChange}>
                <FormControlLabel
                    value='1'
                    control={<ReceiptRadio />}
                    label={t('Reimbursable Receipt')}
                    sx={{ marginRight: '5rem' }}
                />
                <FormControlLabel
                    value='2'
                    control={<ReceiptRadio />}
                    label={t('Apply the same tax invoice address')}
                />
            </RadioGroup>
        </FormControl>
    )
}

export default ReceiptRadioAOT
