import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

const Loading = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                zIndex: 3000,
                gap: 3
            }}
        >
            <CircularProgress color='secondary' />
            <Typography sx={{ color: '#3D9AEE' }}>AOT Smart Car Park</Typography>
        </Box>
    )
}

export default Loading
