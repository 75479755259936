import {
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AIRPORT_ID } from "../constants";
import { toast } from "react-toastify";
import { DISABLED_PAGE } from "../App";

const CustomButtonMenu = styled(Button)(({ theme, ishover, hidden }) => ({
  textTransform: "none",
  color: `${ishover ? "#3D9AEE" : "#2F2E2F"}`,
  font: `${
    ishover === "1"
      ? "normal normal 600 16px/31px Noto Sans Thai"
      : "normal normal 600 16px/31px Noto Sans Thai"
  }`,
  paddingLeft: "1rem",
  paddingRight: "0.5rem",
  "&:hover": {
    color: "#3D9AEE",
    font: "normal normal 600 16px/31px Noto Sans Thai",
    backgroundColor: "transparent",
  },
  [theme.breakpoints.down("xl")]: {
    font: "normal normal 600 16px/20px Noto Sans Thai",
    "&:hover": {
      color: "#3D9AEE",
      font: "normal normal 600 16px/20px Noto Sans Thai",
      backgroundColor: "transparent",
    },
  },
}));

const CustomMenuItem = styled(MenuItem)(({ theme, ishover }) => ({
  textTransform: "none",
  color: "#2F2E2F",
  font: "normal normal normal 16px/30px Noto Sans Thai",
  width: "16rem",
  "&:hover": {
    color: "#3D9AEE",
    font: "normal normal 600 17px/30px Noto Sans Thai",
    "&::before": {
      content: '""',
      display: "block",
      top: "50%",
      left: 0,
      width: "1.5rem",
      height: "2px",
      marginRight: "0.5rem",
      backgroundColor: "#3D9AEE",
    },
  },
}));

const HeaderMenu = ({ showImgAirport, children }) => {
  const { user, currentPmls, isMemberParking } = useSelector(
    (state) => state.auth
  );
  const { pmlsId } = useSelector((state) => state.airport);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const isLGScr = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [isHover1, setIsHover1] = useState(false);

  const [isHover6, setIsHover6] = useState(false);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setIsHover6(false);
    setAnchorEl2(null);
  };
  const normalUser = !user;

  const navigateTo = (path) => {
    if (user) {
      navigate(`/pages${path}`);
    } else {
      navigate(`/home${path}`);
    }
  };

  return (
    <>
      {/* header menu desktop / mobile */}
      <Box
        sx={{
          display: isLGScr ? "none" : "flex",
          justifyContent: "space-between",
          paddingLeft: "70px",
          paddingRight: "80px",
          alignItems: "center",
          height: "4.5rem",
          boxShadow: "0px 3px 6px #00000029",
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: "center",
            height: "100%",
            position: "relative",
          }}
        >
          <img
            alt=""
            style={{ cursor: "pointer", width: "90px" }}
            src="/images/aot_logo.png"
            onClick={() => {
              if (isMemberParking) {
                navigate("/pages");
              } else {
                if (user) {
                  navigate("/pages");
                } else {
                  navigate("/home");
                }
              }
            }}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomButtonMenu
              ishover={
                pathname?.includes("/content") ||
                pathname.includes("/content/campaign") ||
                pathname.includes("/content/information") ||
                pathname.includes("/content/aot-news")
              }
              onMouseEnter={handleOpen}
            >
              {t("News and Promotion")}
              <KeyboardArrowDownIcon
                sx={{ visibility: `${isHover1 ? "hidden" : "visible"} ` }}
              />
            </CustomButtonMenu>
            <CustomButtonMenu
              ishover={pathname?.includes("findparking")}
              onClick={() => {
                navigateTo("/findparking");
              }}
            >
              {t("Parking Density Information")}
            </CustomButtonMenu>
            <CustomButtonMenu
              sx={{
                color: pathname?.includes("feeparking") ? "#3D9AEE" : "#2F2E2F",
              }}
              onClick={() => {
                navigateTo("/feeparking");
              }}
            >
              {t("Check a Parking Fee")}
            </CustomButtonMenu>
            <CustomButtonMenu
              onClick={() => {
                navigateTo("/calparking");
              }}
              sx={{
                color: pathname?.includes("calparking") ? "#3D9AEE" : "#2F2E2F",
              }}
            >
              {t("Calculate a Parking Fee")}
            </CustomButtonMenu>
            {!normalUser && (
              <CustomButtonMenu
                sx={{
                  color: pathname?.includes("findmycar")
                    ? "#3D9AEE"
                    : "#2F2E2F",
                }}
                onClick={() => {
                  navigateTo("/findmycar");
                }}
              >
                {t("Search a Parking Lot")}
              </CustomButtonMenu>
            )}
            {!normalUser && pmlsId == AIRPORT_ID.SUVARNABHUMI && (
              <CustomButtonMenu
                sx={{
                  color: pathname?.includes("reservedparking")
                    ? "#3D9AEE"
                    : "#2F2E2F",
                }}
                onClick={() => {
                  navigateTo("/reservedparking");
                }}
              >
                {t("Reserve a Parking Lot")}
              </CustomButtonMenu>
            )}
            <CustomButtonMenu
              sx={{
                color: pathname?.includes("renew") ? "#3D9AEE" : "#2F2E2F",
              }}
              onClick={() => {
                navigateTo("/renew");
              }}
            >
              {t("Renew Membership")}
            </CustomButtonMenu>
            {isMemberParking && (
              <CustomButtonMenu
                sx={{
                  color: pathname?.includes("change-vehicle")
                    ? "#3D9AEE"
                    : "#2F2E2F",
                }}
                onClick={() => {
                  navigateTo("/change-vehicle");
                }}
              >
                {t("Change Registered Car")}
              </CustomButtonMenu>
            )}
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
          >
            <CustomMenuItem
              onClick={() => {
                if (user) {
                  navigate("/pages/content");
                } else {
                  navigate("/home/content");
                }
                handleClose();
              }}
            >
              {t("All News")}
            </CustomMenuItem>
            <CustomMenuItem
              onClick={() => {
                navigateTo("/content/campaign");
                handleClose();
              }}
            >
              {t("Campaign and Promotion")}
            </CustomMenuItem>
            <CustomMenuItem
              onClick={() => {
                navigateTo("/content/information");
                handleClose();
              }}
            >
              {t("Press Release")}
            </CustomMenuItem>
            <CustomMenuItem
              onClick={() => {
                navigateTo("/content/aot-news");
                handleClose();
              }}
            >
              {t("AOT News")}
            </CustomMenuItem>
          </Menu>
          <Menu
            anchorEl={anchorEl2}
            id="account-menu2"
            open={open2}
            onClose={handleClose2}
            onClick={handleClose2}
            MenuListProps={{ onMouseLeave: handleClose2 }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                borderRadius: 0,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <CustomMenuItem
              onClick={() => {
                navigate("/members/renew");
                handleClose();
              }}
            >
              {t("Renew Membership")}
            </CustomMenuItem>
            <CustomMenuItem onClick={handleClose}>
              {t("Change Registered Car")}
            </CustomMenuItem>
            <CustomMenuItem onClick={handleClose}>
              {t("Parking Density Information")}
            </CustomMenuItem>
            <CustomMenuItem
              onClick={() => {
                navigateTo("/content/findmycar");
                handleClose();
              }}
            >
              {t("Search a Parking Lot")}
            </CustomMenuItem>
          </Menu>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: "center",
            height: "100%",
            position: "relative",
          }}
        >
          {children}
        </Stack>

        {!DISABLED_PAGE.REGISTER_MEMBER_PARKING && (
          <Typography
            sx={{
              paddingLeft: "2rem",
              font: "normal normal bold 16px/33px Noto Sans Thai",
              color: "#2F2E2F",
              cursor: "pointer",
              "&:hover": {
                color: "#3D9AEE",
                font: "normal normal bold 17px/33px Noto Sans Thai",
              },
            }}
            onClick={async () => {
              if (user) {
                if (!isMemberParking) {
                  navigate({
                    pathname: "/auth/parking/register",
                    search: new URLSearchParams({ pmlsId: pmlsId }).toString(),
                  });
                } else {
                  toast.warning("ผู้ใช้งานได้สมัครลานจอดรถแล้ว", {
                    position: "top-center",
                    autoClose: 1000,
                  });
                }
              } else {
                navigate({
                  pathname: "/auth/user/register",
                });
              }
            }}
          >
            {user && t("Apply for Parking Membership")}
          </Typography>
        )}
      </Box>
      {showImgAirport ? (
        <Box
          component="img"
          alt="Background Image"
          loading="lazy"
          src={`/images/header/${
            pmlsId === AIRPORT_ID.SUVARNABHUMI
              ? "img_bgsuwannapun"
              : "img_bgdonmaeung"
          }.png`}
          sx={{
            height: "20rem",
            width: "100%",
            objectFit: "cover",
          }}
        />
      ) : null}
    </>
  );
};

export default HeaderMenu;
