import React from "react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useTranslation } from "react-i18next";

const SavePaymentAction = ({
  onClickSaveScreenshot,
  onClickRequestReceipt,
  requestReceipt = true,
  screenShot = true,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-10">
      {screenShot && onClickSaveScreenshot && (
        <button
          className="flex items-center gap-2 text-[#1C4999] hover:text-[#618bd4] text-lg cursor-pointer"
          onClick={onClickSaveScreenshot}
        >
          <SaveAltIcon fontSize="medium" />
          <span>{t("Save")}</span>
        </button>
      )}
      {requestReceipt && onClickRequestReceipt && (
        <div
          className="flex items-center gap-2 text-[#1C4999] text-lg cursor-pointer"
          onClick={onClickRequestReceipt}
        >
          <img
            src="/images/header/ico_payment_bl.svg"
            alt=""
            className="w-[25px] h-[25px]"
          />
          <span>{t("Request for Tax Invoice")}</span>
        </div>
      )}
    </div>
  );
};

export default SavePaymentAction;
