import instance from "../config/apiAxios";

const BASE_URL = "/subsystem/pmls/content";

//PMCS_TB_0205 : Search content preview from pmls
const contentPreviewFilter = async (body) => {
  const response = await instance.post(BASE_URL + "/preview/filter", body);
  return response.data;
};

const getContentFull = async (params) => {
  const response = await instance.get(BASE_URL + "/full/get", { params });
  return response.data;
};

const ContentService = {
  contentPreviewFilter,
  getContentFull,
};
export default ContentService;