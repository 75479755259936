import React from "react";
import BoxCarparkChart from "../components/HomeAOT/BoxCarparkChart";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ParkingService from "../services/parking-service";
import { useTranslation } from "react-i18next";
import { LANG, LANG_ID } from "../constants";

const FindParking = () => {
  const { pmlsId } = useSelector((state) => state.airport);
  const { i18n } = useTranslation();

  const queryParkingRemainSpace = useQuery({
    queryKey: ["PARKING_REMAIN_SPACE", pmlsId, i18n?.language],
    queryFn: () =>
      ParkingService.remainSpace({
        airportid: pmlsId,
        langid: i18n?.language === LANG.TH ? LANG_ID.TH : LANG_ID.EN,
      }),
    enabled: pmlsId !== undefined,
  });

  const parkingRemainSpaceData =
    queryParkingRemainSpace?.data?.data?.data?.allParkingStat;

  // const boxZoneAvailableData = parkingRemainSpaceData?.map((item) => {
  //   const left = Number(item.totalguideplace) - Number(item.leftguideplace);
  //   const status =
  //     left < Number(item.totalguideplace) ? "Available" : "Unavailable";
  //   return {
  //     label: item.parkingname,
  //     val1: left,
  //     val2: Number(item.totalguideplace),
  //     status: status,
  //   };
  // });

  return (
    <>
      <div className="bg-[#E9F0F5] pt-5 md:md:pt-[150px]" />
      <BoxCarparkChart data={parkingRemainSpaceData} />
      {/* <BoxZoneAvailable data={boxZoneAvailableData} /> */}
    </>
  );
};

export default FindParking;
