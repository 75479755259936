import React from "react";
import { useTranslation } from "react-i18next";
import ButtonAction from "../Base/ButtonAction";
import { DATE_FORMAT_02 } from "../../constants";
import dayjs from "dayjs";

const PaymentPendingVerifyBox = ({
  onlinetransid,
  bankexpiredt,
  onClickCancelTransactionBtnHandler,
  onClickVerifyPaymentBtnHandler,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="w-full border-black border rounded-md px-10 py-4 shadow-lg">
        <div>
          <p className="text-xl">{t("Check a Parking Fee")}</p>
          <hr />
        </div>
        <div className="text-center my-4 text-base">
          <p>{t("You have a pending payment")}</p>
          <p>
            {t("Reference No")} {onlinetransid}
          </p>
          <p className="text-red-500">
            {t("Please pay before pending")}{" "}
            {bankexpiredt ? dayjs(bankexpiredt).format(DATE_FORMAT_02) : "-"}
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 mt-4">
        <ButtonAction size="small" onClick={onClickVerifyPaymentBtnHandler}>
          {t("Verify Payment")}
        </ButtonAction>
        <ButtonAction
          size="small"
          variant="orange"
          textSx={{ color: "white" }}
          onClick={onClickCancelTransactionBtnHandler}
        >
          {t("Cancel transaction")}
        </ButtonAction>
      </div>
    </div>
  );
};

export default PaymentPendingVerifyBox;
