import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const GenderRadio = styled((props) => <Radio {...props} />)(({ theme }) => ({
  color: "#3C5AAD",
  "&.Mui-checked": {
    color: "#3C5AAD",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "30px",
  },
  [theme.breakpoints.down("lg")]: {
    //display: 'none'
  },
}));

const GenderRadioAOT = ({ handleChange, value, disabled, required }) => {
  const {t} = useTranslation()
  return (
    <FormControl>
      <FormLabel>
        {t('Gender')} {required && <span className="text-red-500">*</span>}
      </FormLabel>
      <RadioGroup row name="gender" onChange={handleChange} value={value}>
        <FormControlLabel
          value="1"
          control={<GenderRadio />}
          label="ชาย"
          sx={{ marginRight: "3.5rem" }}
          disabled={disabled}
        />
        <FormControlLabel
          value="0"
          control={<GenderRadio />}
          label="หญิง"
          disabled={disabled}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default GenderRadioAOT;
