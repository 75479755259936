const Colors = {
  disabledButton: "#e7e7e7",
};

const Styles = {
  disabledButton: {
    "&.Mui-disabled": {
      background: Colors.disabledButton,
      border: "none",
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
  },
};
export { Colors, Styles };
