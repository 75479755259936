import React from "react";
import {
  formattedPriceOnlyDigit,
  formattedPriceWithTextCurrency,
} from "../../utils/format";
import { useTranslation } from "react-i18next";

//? props : isRenew = renew member
const TablePackageDetail = ({ isRenew = false, data, totalPrice }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full border px-10 py-6 my-5 border-[#3f3f3f57] border-t-[11px] border-t-[#3D9AEE] shadow-[0px_3px_6px_#4b4b4b56] rounded-b-lg">
      {isRenew && (
        <div className="flex gap-4 items-center mb-5">
          <img
            src="/images/renew/ico_price_bl.svg"
            alt="image"
            className="w-[50px] md:w-[70px] h-auto"
          />
          <span className="text-xl font-bold">{t("Member Package")}</span>
        </div>
      )}
      <table className="w-full border-collapse ">
        <thead>
          <tr className="border-b border-gray-300 text-base md:text-xl font-semibold h-12">
            <th className="w-3/4" align="left">
              {t("Detail")}
            </th>
            <th className="" align="right">
              {t("PriceMonth")}
            </th>
            <th className="w-[15%]" align="right">
              {t("Amount")}
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((i, index) => (
            <tr key={index} className="h-12 text-base md:text-xl">
              <td>{i.packagename}</td>
              <td align="right">{formattedPriceOnlyDigit(i.totalprice, 2)}</td>
              <td align="right">
                {isRenew
                  ? formattedPriceOnlyDigit(i.totalprice, 2)
                  : formattedPriceOnlyDigit(i.totalprice * i.qty, 2)}
              </td>
            </tr>
          ))}
          <tr className="text-base md:text-xl border-t border-gray-400 h-12">
            <td className=""></td>
            <td className=" " align="right">
              {t("Discount")}
            </td>
            <td className=" " align="right">
              0.00
            </td>
          </tr>
          <tr className="text-base md:text-lg lg:text-xl font-bold h-14 ">
            <td className=""></td>
            <td className="truncate border-t border-gray-400" align="right">
              {t("Total Amount")}
            </td>
            <td className="border-t border-gray-400" align="right">
              {totalPrice ? formattedPriceWithTextCurrency(totalPrice) : "0.00"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TablePackageDetail;
