import { getT } from "../utils/axiosUitl";
import axios from "axios";
import instance from "../config/apiAxios";

const registerUser = async (userData) => {
  const response = await instance.post("/mapp/account/register", userData);
  return response.data;
};

const registerMemberStep1 = async (userData) => {
  const response = await instance.post(
    "/mapp/member/register/newregister",
    userData
  );
  return response.data;
};

const registerMemberStep2 = async (userData) => {
  const response = await instance.post(
    "/mapp/member/register/vehicle/update",
    userData
  );
  return response.data;
};

const registerMemberStep3 = async (userData) => {
  const response = await instance.post(
    "/mapp/member/register/doc/update",
    userData
  );
  return response.data;
};

const getProvince = async () => {
  const response = await instance.get(`/ms/province`);
  return response.data;
};

const getDistrict = async (provinceid) => {
  const response = await instance.get(`/ms/district?provinceid=${provinceid}`);
  return response.data;
};

const getSubDistrict = async (provinceid, districtid) => {
  const response = await instance.get(
    `/ms/subdistrict?provinceid=${provinceid}&districtid=${districtid}`
  );
  return response.data;
};

const getMembertype = async () => {
  const response = await instance.get(`/ms/membertype`);
  return response.data;
};

const getNationality = async () => {
  const response = await instance.get(`/ms/nationality`);
  return response.data;
};

const getTitle = async () => {
  const response = await instance.get(`/ms/title`);
  return response.data;
};

const getLicense = async () => {
  const response = await instance.get(`/ms/license`);
  return response.data;
};

const getVehicletype = async () => {
  const response = await instance.get(`/ms/vehicletype`);
  return response.data;
};

const getOwnerVechicle = async () => {
  const response = await instance.get(`/ms/ownervechicle`);
  return response.data;
};

const getDoctype = async () => {
  const response = await instance.get(`/ms/doctype`);
  return response.data;
};
const getDoctypeGroup = async () => {
  const response = await instance.get(`/ms/doctypegroup`);
  return response.data;
};

const getProvinceLicense = async () => {
  const response = await instance.get(`/ms/provincelicense`);
  return response.data;
};

const getOfficeType = async () => {
  const response = await instance.get(`/ms/office`);
  return response.data;
};

const activateUser = async (token) => {
  const response = await getT(
    `/mapp/account/activate?token=${encodeURIComponent(token)}`,
    null,
    token
  );
  return response.data;
};

const userValidate = async (user) => {
  const response = await instance.get(`/sys/user/validate?username=${user}`);
  return response.data;
};

const requestOtp = async (payload) => {
  if (!payload) return;
  const data = {
    MobileNo: payload.mobileno,
  };
  const response = await axios.post("/mapp/account/register/otpsend", data, {
    withCredentials: false,
    crossDomain: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${payload.accesstoken}`,
    },
  });
  return response.data;
};

const verifyOtp = async (otpid, otp, _token) => {
  const data = {
    otpid,
    otp,
  };
  const response = await axios.post("/mapp/account/register/otpverify", data, {
    withCredentials: false,
    crossDomain: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Content-Type": "application/json;charset=utf-8",
      Authorization: `Bearer ${_token}`,
    },
  });
  return response.data;
};

const getParkingGroup = async (pmlsid) => {
  const response = await instance.get(
    `/subsystem/pmls/sys/parking/group/all?pmlsid=${pmlsid}`
  );
  return response.data;
};

const getParkingPackageGroup = async (pmlsid) => {
  const response = await instance.get(
    `/subsystem/pmls/sys/parking/package/all?pmlsid=${pmlsid}`
  );
  return response.data;
};

const getPackagegroup = async (pmlsid, packagegroupid) => {
  const response = await instance.get(
    `/subsystem/pmls/sys/packagegroup?pmlsid=${pmlsid}&packagegroupid=${packagegroupid}`
  );
  return response.data;
};

const getCarbrand = async () => {
  const response = await instance.get(`/ms/carbrand`, null);
  return response.data;
};

const getHistory = async (pmlsid, memberid) => {
  const response = await instance.get(
    `/mapp/member/history/process?pmlsid=${pmlsid}&memberid=${memberid}`
  );
  return response.data;
};

const validateIdentityNo = async (pmlsid, identityno) => {
  const data = {
    pmlsid,
    identityno,
  };
  const response = await instance.post("/mapp/member/validateid", data);
  return response.data;
};

// เรียก ตอน Step สุดท้ายหลังจาก สมัครเสร็จ -> requestMappingPmlsMember , confirmMappingPmlsMember
const requestMappingPmlsMember = async (data) => {
  const response = await instance.post(
    "/mapp/account/pmlsregister/request",
    data
  );
  return response.data;
};
const confirmMappingPmlsMember = async (data) => {
  const response = await instance.post(
    "/mapp/account/pmlsregister/confirm",
    data
  );
  return response.data;
};

const registerService = {
  registerUser,
  registerMemberStep1,
  registerMemberStep2,
  registerMemberStep3,
  getProvince,
  getMembertype,
  getDistrict,
  getSubDistrict,
  getNationality,
  getTitle,
  getLicense,
  getVehicletype,
  getOwnerVechicle,
  getDoctype,
  getDoctypeGroup,
  getProvinceLicense,
  getOfficeType,
  activateUser,
  userValidate,
  requestOtp,
  verifyOtp,
  getParkingGroup,
  getParkingPackageGroup,
  getPackagegroup,
  getCarbrand,
  getHistory,
  validateIdentityNo,
  requestMappingPmlsMember,
  confirmMappingPmlsMember,
};
export default registerService;
