import {
  styled,
  Menu,
  Button,
  MenuItem,
  Typography,
  alpha,
} from "@mui/material";
import { Stack } from "@mui/system";
import { LANG } from "../constants";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useState } from "react";

const StackUserIcon = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const FlagText = styled(Typography)(({ theme }) => ({
  paddingRight: "1rem",
  [theme.breakpoints.down("md")]: {
    font: "normal normal normal 16px/22px Noto Sans Thai",
  },
  [theme.breakpoints.up("lg")]: {
    font: "normal normal normal 18px/33px Noto Sans Thai",
  },
}));

const LanguagesDropdown = ({ textColor = "text-black" }) => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const lang = i18n.language || "th";
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeLang = (lng) => {
    i18n.changeLanguage(lng);
    dayjs.locale(lng);
    handleClose();
  };

  return (
    <div>
      <Button
        sx={{ textTransform: "none", color: "white" }}
        onClick={handleClick}
        className="flex items-center gap-2"
      >
        <div className="flex items-center">
          <img
            src={
              lang === LANG.TH
                ? "/images/header/ico_thaiflage.svg"
                : "/images/header/ico_engflage.svg"
            }
            alt=""
            style={{ marginLeft: "1rem", marginRight: "1rem" }}
          />
          <p className={`${textColor ? textColor : "text-black"} text-lg`}>
            {lang === LANG.TH ? "Thai" : "English"}
          </p>
        </div>
        <KeyboardArrowDownIcon />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disableRipple
          onClick={(e) => {
            handleChangeLang(LANG.TH);
          }}
        >
          <img
            src="/images/header/ico_thaiflage.svg"
            style={{ marginLeft: "1rem", marginRight: "1rem" }}
          />
          <FlagText>Thai</FlagText>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={(e) => {
            handleChangeLang(LANG.EN);
          }}
        >
          <img
            src="/images/header/ico_engflage.svg"
            style={{
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
          />
          <FlagText>English</FlagText>
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default LanguagesDropdown;
