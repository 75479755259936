import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { formattedBase64Image } from "../../utils/format";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const BoxOuter = styled(Box)(({ theme }) => ({
  boxShadow: "0px 3px 6px #00000029",
  border: "1px solid #707070",
  borderRadius: "13px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
}));

const BoxHeader = styled(Box)(({ theme }) => ({
  paddingTop: "2rem",
  marginLeft: "2rem",
  marginRight: "2rem",
  display: "flex",
  borderBottom: "3px solid #2F2E2F",
  [theme.breakpoints.down("lg")]: {
    marginLeft: "2rem",
    marginRight: "2rem",
  },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 26px/60px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 24px/50px Noto Sans Thai",
  },
}));

const BoxTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingTop: "2rem",
  marginLeft: "2rem",
  marginRight: "2rem",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    //marginLeft: '2rem',
    //marginRight: '2rem'
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 20px/40px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  paddingLeft: "2rem",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 18px/30px Noto Sans Thai",
  },
}));

const BoxDetail = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  paddingTop: "2rem",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const NormalText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/40px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    // marginBottom: "1rem",
  },
}));

const GreyText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/40px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#898A8F",
  [theme.breakpoints.down("lg")]: {
    // marginBottom: "1rem",
  },
}));

const BoxSubject = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: "2rem",
  borderBottom: "1px solid #B3BBC2",
  paddingBottom: "16px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    paddingRight: "0",
  },
}));

const FindMyCarBoxInfomation = ({ data }) => {
  const { pmlsId } = useSelector((select) => select.airport);
  const { t } = useTranslation();

  return (
    <BoxOuter>
      <BoxHeader>
        <HeaderText>{t("Search a Parking Lot")}</HeaderText>
      </BoxHeader>
      <BoxTitle>
        <img
          src="/images/calparking/ico_linegraycar.svg"
          alt=""
          style={{ height: "58px", width: "58px" }}
        />
        <TitleText>รายละเอียดที่จอดรถ</TitleText>
      </BoxTitle>
      <BoxDetail>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
        >
          <BoxSubject>
            <NormalText>{t("Airport")}</NormalText>
            {pmlsId == "1" ? (
              <GreyText>{t("Suvarnabhumi airport")}</GreyText>
            ) : (
              <GreyText>{t("Don Mueang International Airport")} </GreyText>
            )}
          </BoxSubject>
          <BoxSubject>
            <NormalText>ช่องจอด</NormalText>
            <GreyText>{data?.spaceNo || "-"}</GreyText>
          </BoxSubject>
          <BoxSubject>
            <NormalText>อาคารจอด</NormalText>
            <GreyText>{data?.parkName || "-"}</GreyText>
          </BoxSubject>
          <BoxSubject>
            <NormalText>ชั้น</NormalText>
            <GreyText>{data?.floorName || "-"}</GreyText>
          </BoxSubject>
          <div className="py-5">
            {formattedBase64Image("png", data?.spacePicBase64) && (
              <Box
                component="img"
                src={formattedBase64Image("png", data?.spacePicBase64)}
              ></Box>
            )}
          </div>
        </Box>
      </BoxDetail>
    </BoxOuter>
  );
};

export default FindMyCarBoxInfomation;
