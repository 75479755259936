import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { FirstStepper, NextStepper } from "../Controls/StepperAOT";
import { useTranslation } from "react-i18next";

const ReservedParkingStepper = ({ sxa, activeStep }) => {
  const isSMScr = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "100%", ...sxa }}>
      <Box
        sx={{
          //paddingTop: '25px',
          //paddingBottom: '25px',
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <FirstStepper
          label={isSMScr ? "1.เลือกอาคาร" : `1.${t('Select Parking Building')}`}
          sxbox={{
            background:
              activeStep >= 1
                ? "transparent linear-gradient(180deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box"
                : "#B1BCC4 0% 0% no-repeat padding-box",
            borderColor: activeStep >= 1 ? "#3D9AEE" : "#B3BBC2",
          }}
          sximg={{ display: "none" }}
        />
        <NextStepper
          label={isSMScr ? "2.ตรวจสอบ" :  `2.${t('Review Payment Information')}`}
          sxbox={{
            background:
              activeStep >= 3
                ? "transparent linear-gradient(180deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box"
                : "#B1BCC4 0% 0% no-repeat padding-box",
            borderColor: activeStep >= 2 ? "#3D9AEE" : "#B3BBC2",
          }}
          sxline={{ borderColor: activeStep >= 2 ? "#3D9AEE" : "#B3BBC2" }}
          sximg={{ display: "none" }}
        />
        <NextStepper
          label={`3.${t('Payment')}`}
          sxbox={{
            background:
              activeStep >= 4
                ? "transparent linear-gradient(180deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box"
                : "#B1BCC4 0% 0% no-repeat padding-box",
            borderColor: activeStep >= 3 ? "#3D9AEE" : "#B3BBC2",
          }}
          sxline={{ borderColor: activeStep >= 3 ? "#3D9AEE" : "#B1BCC4" }}
          sximg={{ display: "none" }}
        />
        <NextStepper
          label={`4.${t('Completed')}`}
          sxbox={{
            background:
              activeStep >= 5
                ? "transparent linear-gradient(180deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box"
                : "#B1BCC4 0% 0% no-repeat padding-box",
            borderColor: activeStep >= 4 ? "#3D9AEE" : "#B3BBC2",
          }}
          sxline={{ borderColor: activeStep >= 5 ? "#3D9AEE" : "#B1BCC4" }}
          sximg={{ display: "none" }}
        />
      </Box>
    </Box>
  );
};

export default ReservedParkingStepper;
