import { Box } from "@mui/material";
import React from "react";
import { InputAOT } from "./InputAOT";

const MultiLineAOT = ({
  label,
  width,
  val,
  handleChange,
  disabled,
  sxa,
  required,
  name,
  handleBlur,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        width,
        borderRadius: "10px",
        border: "2px solid #3D9AEE",
        ...sxa,
      }}
    >
      <InputAOT
        name={name}
        sx={{ flex: "1 1 auto" }}
        disabled={disabled}
        required={required}
        label={label}
        multiline
        rows={4}
        variant="filled"
        value={val}
        InputProps={{ disableUnderline: true }}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Box>
  );
};

export default MultiLineAOT;
