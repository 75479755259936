import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

export default function DialogPaymentConfirmCancel({
  open,
  setOpen,
  onClickYesBtn,
  onClickNoBtn,
}) {
  const { t } = useTranslation();
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {/* {"Use Google's location service?"} */}
        {t('confirm to cancel transaction')}
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickNoBtn}>{t('No_Cancel')}</Button>
        <Button onClick={onClickYesBtn} autoFocus>
          {t('Yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
