import { isNaN } from "lodash-es";
import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const REMAIN_COLOR = {
  GREEN: "#40C997",
  YELLOW: "#FAEF0F",
  ORANGE: "#F3720D",
  RED: "#FA2913",
};

const remainingColorByPercentage = (percent) => {
  let color = "";
  if (percent <= 25) {
    color = REMAIN_COLOR.GREEN;
  } else if (percent <= 50) {
    color = REMAIN_COLOR.YELLOW;
  } else if (percent <= 85) {
    color = REMAIN_COLOR.ORANGE;
  } else {
    color = REMAIN_COLOR.RED;
  }
  return color;
};

const percentageDensity = (totalGuidePlace, leftGuidePlace) => {
  return Math.ceil(
    ((totalGuidePlace - leftGuidePlace) / totalGuidePlace) * 100
  );
};

const ParkingDensityBox = ({
  parkingName,
  leftGuidePlace,
  totalGuidePlace,
  floors,
}) => {
  const { t } = useTranslation();
  const percentage = useMemo(() => {
    return percentageDensity(totalGuidePlace, leftGuidePlace);
  }, [leftGuidePlace, totalGuidePlace]);

  return (
    <div
      className="w-full shadow-2xl border-t-[15px] rounded-lg px-3 pb-4 text-center"
      style={{ borderTopColor: remainingColorByPercentage(percentage) }}
    >
      <div className="border-b border-gray-300">
        <h2 className="text-2xl font-bold border-b border-gray-300 leading-[50px]">
          {parkingName}
        </h2>
        <p className="text-lg font-semibold text-gray-500 leading-10">
          {t("Available Parking Lots")}{" "}
          {Number(leftGuidePlace).toLocaleString()} /{" "}
          {Number(totalGuidePlace).toLocaleString()} {t("cars")}
        </p>
      </div>
      <div className="space-y-4 relative py-3 px-9">
        <FloorDensity
          title={t("Total floor")}
          leftGuidePlace={leftGuidePlace}
          totalGuidePlace={totalGuidePlace}
          allLeftGuildPlace={Number(totalGuidePlace) - Number(leftGuidePlace)}
        />
        {floors?.map((floor, index) => (
          <FloorDensity
            key={index}
            title={floor?.parkingfloorname_translate}
            leftGuidePlace={floor?.leftguideplace}
            totalGuidePlace={floor?.totalguideplace}
            allLeftGuildPlace={
              Number(floor?.totalguideplace) - Number(floor?.leftguideplace)
            }
          />
        ))}
      </div>
    </div>
  );
};

const FloorDensity = ({
  title,
  leftGuidePlace,
  totalGuidePlace,
  allLeftGuildPlace,
}) => {
  const percentage = useMemo(() => {
    return percentageDensity(totalGuidePlace, leftGuidePlace);
  }, [leftGuidePlace, totalGuidePlace]);

  return (
    <div className="w-full text-left text-base">
      <span className="px-4 font-bold">{title}</span>
      <div className="relative h-5 mb-2 ">
        <div
          className="rounded-2xl h-full absolute z-10 shadow-md"
          style={{
            width: `${percentage}%`,
            backgroundColor: remainingColorByPercentage(percentage),
          }}
        />
        <div className="w-full rounded-2xl h-full bg-gray-100 absolute shadow-md" />
      </div>
      <div className="flex justify-between px-4 font-bold text-sm text-slate-700">
        <span>{!isNaN(percentage) ? percentage : 0}%</span>
        <span>
          {allLeftGuildPlace.toLocaleString()}
          <span className="text-gray-400">
            / {Number(totalGuidePlace).toLocaleString()}
          </span>
        </span>
      </div>
    </div>
  );
};

export default ParkingDensityBox;
