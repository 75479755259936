import instance from "../config/apiAxios";
import { getX } from "../utils/axiosUitl";

const BASE_PATH = `/sys/login`;

const register = async (userData) => {
  const response = await instance.post(BASE_PATH + "/register", userData);
  if (response.data) {
    localStorage.setItem("pmsuser", JSON.stringify(response.data));
  }
  return response.data;
};

const login = async (userData) => {
  const response = await instance.post(
    BASE_PATH + "/secure/authen",
    userData
  );
  if (response.data && response.data.ouIsComplete === 1) {
    const s = {
      username: userData.username,
      accesstoken: response.data.data.accesstoken,
      refreshtoken: response.data.data.refreshtoken,
    };
    localStorage.setItem("pmsuser", JSON.stringify(s));
    return s;
  } else if (response.data && response.data.ouIsComplete === 0) {
    throw new Error(response.data.ouMessage);
  }
  return response.data;
};

const logout = () => {
  localStorage.removeItem("pmsuser");
};

const resetPassword = async (username) => {
  const data = { username };
  const response = await instance.post(BASE_PATH + `/resetpassword`, data);
  return response.data;
};

const changePassword = async (data) => {
  const response = await instance.post(BASE_PATH + `/update`, data);
  return response.data;
};

const forceChangePassword = async (data) => {
  const response = await instance.post(BASE_PATH + `/forceupdate`, data);
  return response.data;
};

const forgotPassword = async (data) => {
  const response = await instance.post(BASE_PATH + `/forgot`, data);
  return response.data;
};

const getPublicKey = async (xapp) => {
  const response = await getX(BASE_PATH + `/secure/authen`, null, xapp);
  return response.headers["x-key"];
};

const LoginService = {
  register,
  logout,
  login,
  resetPassword,
  changePassword,
  forceChangePassword,
  forgotPassword,
  getPublicKey,
};

export default LoginService;
