import React from "react";
import {
  formattedDate_01,
  formattedPriceWithTextCurrency,
} from "../../utils/format";
import { useTranslation } from "react-i18next";
import ButtonAction from "../Base/ButtonAction";
import ChoosePaymentMethodStep from "../Payment/ChoosePaymentMethodStep";

const ReservedParking3 = ({
  onClickPaymentButton,
  displayInformation,
  bookingData,
  onClickCancelConfirmPaymentButton,
  formik,
  onChangePaymentMethod,
  method,
}) => {
  const { t } = useTranslation();

  const paymentDetailItems = [
    { title: t("License Plate"), value: formik?.values?.licenseplate },
    { title: t("Province"), value: formik.values.licenseprovince?.display },
    { title: t("Parking Zone"), value: displayInformation?.zone },
    {
      title: t("Please entry before"),
      value: bookingData?.slotdateend
        ? formattedDate_01(bookingData?.slotdateend)
        : "-",
    },
  ];

  return (
    <>
      <ChoosePaymentMethodStep
        details={paymentDetailItems}
        boxFooterExtra={
          <p className="text-center text-sm font-bold text-red-500 mt-4 flex-none">
            {t(
              "Please check the correctness of your license plate and parking zone"
            )}
          </p>
        }
        boxTitleText={t("Reservation Payment Summary")}
        onChangePaymentMethod={onChangePaymentMethod}
        method={method}
        amount={formattedPriceWithTextCurrency(bookingData?.feeamount)}
      />
      <div className="w-full flex flex-col md:flex-row gap-2 mt-4">
        <ButtonAction
          onClick={onClickCancelConfirmPaymentButton}
          variant="secondary"
        >
          {t("Cancel")}
        </ButtonAction>
        <ButtonAction onClick={onClickPaymentButton}>
          {t("Confirm Payment")}
        </ButtonAction>
      </div>
    </>
  );
};

export default ReservedParking3;
