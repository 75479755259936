import { Box, styled, Typography } from "@mui/material";
import React from "react";
import RenewSummary from "./RenewSummary";
import { useTranslation } from "react-i18next";
import {
  formattedPriceWithTextCurrency,
} from "../../utils/format";

const BoxReceipt = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "70%",
  border: "1px solid #B3BBC2",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
}));

const BoxGrandTotal = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "1rem",
  paddingTop: "1rem",
  borderBottom: "1px solid #B3BBC2",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));



const BoxQRTitle = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "flex",
  backgroundColor: "#113566",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "1rem",
  borderBottom: "1px solid #B3BBC2",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
}));

const QRTitleText = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 20px/60px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#FFFFFF",
  [theme.breakpoints.down("xl")]: {
    font: "normal normal 600 18px/30px Noto Sans Thai",
  },
}));

const AOTText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/30px Noto Sans Thai",
  letterSpacing: "0px",
  [theme.breakpoints.down("xl")]: {
    font: "normal normal normal 16px/30px Noto Sans Thai",
  },
}));

const BoxHint = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "70%",
  flexDirection: "column",
  paddingBottom: "3rem",
  alignItems: "flex-start",
  [theme.breakpoints.down("lg")]: {
    width: "80%",
  },
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
}));

const HintTitleText = styled(Typography)(({ theme }) => ({
  font: "normal normal bold 20px/33px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  paddingBottom: "2rem",
  paddingTop: "2rem",
  [theme.breakpoints.down("xl")]: {},
}));

const HintDescText = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 18px/20px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  paddingBottom: "0.5rem",
  [theme.breakpoints.down("xl")]: {},
}));

const RenewSummaryWait = ({
  activeStep,
  setActiveStep,
  paymentData,
  responsePaymentData,
  previewBoxData,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {activeStep === 3 && (
        <div className="mt-2">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <img
              src="/images/feeparking/img_wait_payment.svg"
              alt=""
              className="w-[190px] h-auto"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexDirection: "column",
              py: "1rem",
            }}
          >
            <Typography
              sx={{
                font: "normal normal bold 24px/54px Noto Sans Thai",
                letterSpacing: "0px",
                color: "#EF8A49",
              }}
            >
              {t("Waiting for payment")}
            </Typography>


          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <BoxReceipt>
              <BoxGrandTotal>
                <Typography
                  sx={{
                    font: "normal normal 600 18px/29px Noto Sans Thai",
                    letterSpacing: "0px",
                    color: "#2F2E2F",
                  }}
                >
                  {t("Payment Amount")}
                </Typography>
                <Typography
                  sx={{
                    font: "normal normal 600 18px/29px Noto Sans Thai",
                    letterSpacing: "0px",
                    color: "#2F2E2F",
                  }}
                >
                  {formattedPriceWithTextCurrency(paymentData?.amount)}
                </Typography>
              </BoxGrandTotal>
            
              <BoxQRTitle>
                <Box
                  component="img"
                  src="/images/feeparking/tqp.png"
                  alt=""
                  sx={{ height: "45px", marginRight: "1rem" }}
                />
                <QRTitleText>{t("Scan the QR Code to pay")}</QRTitleText>
              </BoxQRTitle>
              <Box
                id="qrcodediv"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Box
                      component="img"
                      src={`data:image/png;base64,${paymentData?.qrbase64}`}
                      alt=""
                      sx={{ width: "250px", height: "250px" }}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "310px",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "200px",
                        marginLeft: "8px",
                        fontSize: "14px",
                      }}
                    >
                      {paymentData?.merchantname}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                      }}
                    >
                      <Box
                        sx={{
                          marginRight: "8px",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {Number(paymentData?.amount).toLocaleString()} Baht
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography>{t("Please pay before")}</Typography>
                  <Typography sx={{ color: "red", marginLeft: "5px" }}>
                    {paymentData?.bankexpiredt}
                  </Typography>
                </Box>
              </Box>
            </BoxReceipt>
            <BoxHint>
              <HintTitleText>{t("the instructions")}</HintTitleText>
              <HintDescText>1. {t("stepone")}</HintDescText>
              <HintDescText>2. {t("steptwo")}</HintDescText>
              <HintDescText>3. {t("stepthree")}</HintDescText>
              <HintDescText>4. {t("stepfour")}</HintDescText>
              <HintDescText>5. {t("stepfive")}</HintDescText>
              <HintDescText>6. {t("stepsix")}</HintDescText>
            </BoxHint>
          </Box>
        </div>
      )}
      {activeStep == 4 && (
        <RenewSummary
          responseData={responsePaymentData}
          previewBoxData={previewBoxData}
        />
      )}
    </>
  );
};

export default RenewSummaryWait;
