import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import { FirstStepper, NextStepper } from "../Controls/StepperAOT";
import { useTranslation } from "react-i18next";

const RegisterStepper = ({ sxa, activeStep }) => {
  const isSMScr = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "100%", ...sxa }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <FirstStepper
          label={
            isSMScr
              ? `1.${t("Enter Personal Information")}`
              : `1.${t("Enter Personal Information")}`
          }
          sxbox={{
            background:
              activeStep >= 2
                ? "transparent linear-gradient(180deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box"
                : "#B1BCC4 0% 0% no-repeat padding-box",
            borderColor: activeStep >= 2 ? "#3D9AEE" : "#B3BBC2",
          }}
          sximg={{ display: "none" }}
        />
        <NextStepper
          label={isSMScr ? "2.ข้อมูลรถ" : `2.${t("Enter Vehicle Information")}`}
          sxbox={{
            background:
              activeStep >= 3
                ? "transparent linear-gradient(180deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box"
                : "#B1BCC4 0% 0% no-repeat padding-box",
            // borderColor: activeStep >= 3 ? '#3D9AEE' : '#B1BCC4'
          }}
          sxline={{ borderColor: activeStep >= 3 ? "#3D9AEE" : "#B1BCC4" }}
          sximg={{ display: "none" }}
        />
        <NextStepper
          label={`3.${t("Attach Documents")}`}
          sxbox={{
            background:
              activeStep >= 4
                ? "transparent linear-gradient(180deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box"
                : "#B1BCC4 0% 0% no-repeat padding-box",
            // borderColor: activeStep >= 4 ? '#3D9AEE' : '#B1BCC4'
          }}
          sxline={{ borderColor: activeStep >= 4 ? "#3D9AEE" : "#B1BCC4" }}
          sximg={{ display: "none" }}
        />
      </Box>
    </Box>
  );
};

export default RegisterStepper;
