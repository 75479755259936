import { post, get } from "../utils/axiosUitl";
const token = process.env.REACT_APP_TOKEN_PUBLIC;

const BASE_URL = "/subsystem/pmls/member";

//PMCS_SYS_0343 : Get member by card id from pmls
const getMemberByCardIdFormPmls = async (params) => {
  const response = await get(BASE_URL + "/getbycardid", params, token);
  return response.data;
};

//PMCS_SYS_0344 : Member renew by card at pmls
const updateMemberRenewByCardAtPmls = async (data) => {
  const response = await post(BASE_URL + "/renewbycard", data, token);
  return response.data;
};

const RenewByCardService = {
  getMemberByCardIdFormPmls,
  updateMemberRenewByCardAtPmls
};
export default RenewByCardService;
