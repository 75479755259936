export const decodePublicKey = (pbbase64) => {
  return decodeURIComponent(
    window
      .atob(pbbase64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};
