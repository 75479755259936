import React from "react";
import { styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import ButtonAction from "../Base/ButtonAction";
import { AIRPORT_ID, DATE_FORMAT_01, DATE_FORMAT_02 } from "../../constants";
import { formattedPriceWithTextCurrency } from "../../utils/format";
import SavePaymentAction from "../Base/SavePaymentAction";
import useScreenShot from "../../hooks/useScreenShot";
import { TitleWithValue } from "../Base/TitleWithValue";
import BoxContent from "../Box/BoxContent";
import { getPaymentTranslationKeyByPaymentMethod } from "../../utils";

const TextWelcome = styled(Typography)(() => ({
  font: "normal normal bold 24px/40px Noto Sans Thai",
  letterSpacing: 0,
  color: "#3D9AEE",
}));

const RenewSummary = ({
  previewBoxData,
  isPendingPayment,
  onClickSeeDetailBtn,
  isEffectiveDateConfig = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pmlsId } = useSelector((state) => state.airport);
  const { frameRef, handleScreenshot } = useScreenShot();

  const informationDisplay = [
    { title: t("Reference No"), value: previewBoxData?.onlinetransid },
    { title: t("Airport"), value: previewBoxData?.airport },
    { title: t("License Plate"), value: previewBoxData?.licenseplate },
    {
      title: t("Renew Duration"),
      value: `${previewBoxData?.numberOfMonths} ${t("Month")}`,
    },
    {
      title: t("Apply Start Date"),
      value: previewBoxData?.startdate ? dayjs(previewBoxData?.startdate)?.format(isEffectiveDateConfig ? DATE_FORMAT_02 : DATE_FORMAT_01) : null,
      isShowAlert: isEffectiveDateConfig,
      valueAlert: (
        <p className="text-red-500 text-center font-semibold pb-3">
          {t("Remark renew parking fee")}
        </p>
      ),
    },
    {
      title: t("Expired Date"),
      value: previewBoxData?.enddate ? dayjs(previewBoxData?.enddate)?.format(isEffectiveDateConfig ? DATE_FORMAT_02 : DATE_FORMAT_01) : null,
    },
    {
      title: t("Payment Method"),
      value: t(
        getPaymentTranslationKeyByPaymentMethod(previewBoxData?.paymentType)
      ),
    },
    {
      title: t("Transfer Amount"),
      value: formattedPriceWithTextCurrency(previewBoxData?.totalPrice),
      isShowAlert: isEffectiveDateConfig,
      valueAlert: (
        <p className="text-red-500 text-center font-semibold pt-3">
          {t("Remark renew rate and effective")}
        </p>
      ),
    },
  ];

  return (
    <div className="w-full py-4 flex items-center flex-col">
      {isPendingPayment && (
        <p className="mb-6 text-xl lg:text-2xl font-semibold">
          {t("Renewal Result")}
        </p>
      )}
      <div
        className="w-full max-w-3xl flex flex-col items-center mx-2 gap-4"
        ref={frameRef}
      >
        <img
          src="/images/payment/img_complate.png"
          className="w-[200px] h-auto"
          alt="img_complete"
        />
        <div className="text-center">
          <TextWelcome>{t("Completed")}</TextWelcome>
          <p className="text-sm">
            {previewBoxData?.updateddt
              ? dayjs(previewBoxData?.updateddt).format(DATE_FORMAT_02)
              : "-"}
          </p>
        </div>
        <BoxContent boxBorderRadius={2} boxShadow="none">
          <div className="divide-y w-full">
            {informationDisplay.map((item, index) => {
              return (
                <div key={index}>
                  <TitleWithValue
                    key={index}
                    title={item.title}
                    value={item.value}
                    boxStyles="py-4"
                  />
                  {item?.isShowAlert && item?.valueAlert
                    ? item.valueAlert
                    : null}
                </div>
              );
            })}
          </div>
        </BoxContent>
      </div>
      <div className="text-center w-full max-w-3xl  py-3 flex flex-col items-center gap-y-4">
        <p>
          {pmlsId === AIRPORT_ID.DONMUANG
            ? t("Remark receive tax invoice dmk")
            : t("Remark receive tax invoice")}
        </p>
        <SavePaymentAction
          requestReceipt={false}
          onClickSaveScreenshot={handleScreenshot}
        />
        <div className="w-full space-y-4 mt-2">
          {isPendingPayment && (
            <ButtonAction onClick={onClickSeeDetailBtn}>
              {t("Renew Membership")}
            </ButtonAction>
          )}
          <ButtonAction
            onClick={() => {
              navigate(`/`);
            }}
          >
            {t("Back to Home")}
          </ButtonAction>
        </div>
      </div>
    </div>
  );
};

export default RenewSummary;
