import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#245AB1",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PaymentTable({ data, loading }) {
  const {t} = useTranslation()
  return (
    <TableContainer component={Paper} className="mt-5">
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="start">{t('Date')}</StyledTableCell>
            <StyledTableCell align="center">{t('Transaction Type')}</StyledTableCell>
            <StyledTableCell align="center">{t('Receipt No')}</StyledTableCell>
            <StyledTableCell align="center">{t('Status')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={6}>
                Loading...
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            data?.map((item, index) => (
              <StyledTableRow key={item.processid}>
                <StyledTableCell align="start">
                  {item.createdt ? dayjs(item.createdt).format('DD-MM-YYYY HH:mm:ss') : '-'}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.transtypename}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.receiptno}
                </StyledTableCell>
                <StyledTableCell align="center">{item.status}</StyledTableCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
