import React from 'react'
import { Box, Button, styled, Typography } from '@mui/material'
import InputTextFieldAOT from '../Controls/InputTextFieldAOT'
import CheckBoxAOT from '../Controls/CheckBoxAOT'
import { useNavigate } from 'react-router-dom'
import PaymentSummary from './PaymentSummary'
import { useTranslation } from 'react-i18next'

const TaxInvoiceHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '3.991rem 6.25rem 4.466rem 6.25rem',
    [theme.breakpoints.down('lg')]: {
        padding: '3.991rem 5.25rem 1.322rem 5.25rem'
    },
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        padding: '3.991rem 2.25rem 1.322rem 2.25rem'
    }
}))

const BoxTitleHeader = styled(Box)(() => ({
    marginLeft: '4.5rem',
    paddingTop: '2.571rem',
    paddingLeft: '1rem'
}))

const BoxHeaderRight = styled(Box)(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        margin: '1rem 0'
    }
}))

const BoxInfoRequesting = styled(Box)(({ theme }) => ({
    padding: '0 15.563rem 0 15.563rem',
    marginBottom: '2.231rem',
    [theme.breakpoints.down('lg')]: {
        padding: '0 7rem 0 7rem'
    },
    [theme.breakpoints.down('md')]: {
        padding: '0 5rem 0 5rem'
    }
}))

const BoxButton = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '0 15.563rem 0 15.563rem',
    marginBottom: '9.375rem',
    [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap',
        padding: '0 7rem 0 7rem'
    },
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        padding: '0 5rem 0 5rem'
    }
}))

const BoxOuter = styled(Box)(({ theme }) => ({
    border: '1px solid #B3BBC2',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    opacity: 1
}))

const BoxCheck = styled(Box)(() => ({
    display: 'flex',
    paddingLeft: '1rem',
    marginBottom: '1rem',
    alignItems: 'center'
}))

const TextTaxInvoiceHeader = styled(Typography)(({ theme }) => ({
    font: 'normal normal 600 36px/54px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#2F2E2F',
    [theme.breakpoints.down('md')]: {
        fontSize: '30px'
    }
}))

const TextRight = styled(Typography)(() => ({
    font: 'normal normal normal 18px/27px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#2F2E2F',
    marginLeft: '0.2rem'
}))

const TextRightBlue = styled(Typography)(() => ({
    font: 'normal normal 600 18px/27px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#3C5AAD',
    marginLeft: '0.2rem'
}))

const TitleText = styled(Typography)(({ theme }) => ({
    font: 'normal normal 600 22px/53px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#2F2E2F',
    [theme.breakpoints.down('lg')]: {
        //marginLeft: '2rem',
        //marginRight: '2rem'
    }
}))

const TextTaxInvoice = styled(Typography)(() => ({
    font: 'normal normal normal 22px/33px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#201F20',
    marginLeft: '1.289rem'
}))

const ImgRenew = styled((props) => <Box component='img' {...props} />)(({ theme }) => ({
    width: '3.063rem',
    marginRight: '2.108rem',
    [theme.breakpoints.down('lg')]: {
        //display: 'none'
    }
}))

const ButtonCancel = styled(Button)(({ theme }) => ({
    width: '20.813rem',
    height: '5.375rem',
    background: '#E9F0F5 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    font: 'normal normal normal 22px/33px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#2F2E2F',
    marginRight: '1.625rem',
    '&:hover': {
        background: '#E9F0F5 0% 0% no-repeat padding-box'
    },
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginBottom: '1rem',
        marginRight: '0'
    }
}))

const ButtonSend = styled(Button)(({ theme }) => ({
    width: '33.188rem',
    height: '5.375rem',
    font: 'normal normal 600 22px/33px Noto Sans Thai',
    letterSpacing: '0px',
    color: '#FFFFFF',
    background: 'transparent linear-gradient(90deg, #3D9AEE 0%, #3859CB 100%) 0% 0% no-repeat padding-box',
    border: '2px solid #3D9AEE',
    borderRadius: '10px',
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    }
}))

const PaymentTaxInvoice = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [activeStep, setActiveStep] = React.useState(1)
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                {activeStep === 1 ? (
                    <Box>
                        <TaxInvoiceHeader>
                            <Box sx={{ display: 'flex' }}>
                                <ImgRenew src='/images/payment/ico_payment_head.svg' alt='' />
                                <TextTaxInvoiceHeader>คำขอรับใบกำกับภาษีแบบเต็มรูป</TextTaxInvoiceHeader>
                            </Box>
                            <BoxHeaderRight>
                                <TextRight>{t('Home')} /</TextRight>
                                <TextRight>{t('Payment')} /</TextRight>
                                <TextRightBlue>ขอรับใบกำกับภาษีแบบเต็มรูป</TextRightBlue>
                            </BoxHeaderRight>
                        </TaxInvoiceHeader>
                        <BoxInfoRequesting>
                            <BoxOuter>
                                <BoxTitleHeader>
                                    <TitleText>กรอกข้อมูลคำขอรับเอกสาร</TitleText>
                                </BoxTitleHeader>
                                <Box sx={{ width: '90%', paddingLeft: '7.168rem' }}>
                                    <Box sx={{ borderBottom: '1px solid #B3BBC2' }}>
                                        <TitleText>เอกสารที่ต้องการขอรับ</TitleText>
                                        <BoxCheck>
                                            <CheckBoxAOT></CheckBoxAOT>
                                            <TextTaxInvoice>ใบกำกับภาษีแบบเต็มรูป</TextTaxInvoice>
                                        </BoxCheck>
                                    </Box>
                                    <Box sx={{ width: '90%', marginTop: '0.5rem' }}>
                                        <TitleText>ข้อมูลในการออกใบกำกับภาษีแบบเต็มรูป</TitleText>
                                        <InputTextFieldAOT
                                            label='ชื่อ-สกุล/ ชื่อบริษัท *'
                                            name='xxxx'
                                            sxa={{ height: '88px', marginBottom: '1rem' }}
                                        />
                                        <InputTextFieldAOT
                                            label='เลขที่ประจำตัวผู้เสียภาษี *'
                                            name='xxxx'
                                            sxa={{ height: '88px', marginBottom: '1rem' }}
                                        />
                                        <InputTextFieldAOT
                                            label={t('Address')}
                                            name='xxxx'
                                            sxa={{ height: '164px', marginBottom: '2.149rem' }}
                                            required
                                        />
                                    </Box>
                                </Box>
                            </BoxOuter>
                        </BoxInfoRequesting>
                        <BoxButton>
                            <ButtonCancel onClick={() => navigate('/members/payment')}>ยกเลิก</ButtonCancel>
                            <ButtonSend onClick={handleNext}>ส่งคำขอ</ButtonSend>
                        </BoxButton>
                    </Box>
                ) : (
                    <PaymentSummary />
                )}
            </Box>
        </>
    )
}

export default PaymentTaxInvoice
