import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import RenewByLicensePlate from "../components/Renew/RenewByLicensePlate/index.jsx";
import RenewMember from "../components/Renew/RenewMember/index.jsx";

const RENEW_MODE = {
  BY_LICENSE_PLATE: 1,
  BY_USER: 2,
};

const Renew = () => {
  const { user, isMemberParking } = useSelector((state) => state.auth);
  const [renewMode, setRenewMode] = useState(RENEW_MODE.BY_USER);

  //? set render mode
  useEffect(() => {
    if (isMemberParking) {
      setRenewMode(RENEW_MODE.BY_USER);
    } else {
      setRenewMode(RENEW_MODE.BY_LICENSE_PLATE);
    }
  }, [user, isMemberParking]);

  let elementModeRender = null;

  if (renewMode === RENEW_MODE.BY_LICENSE_PLATE) {
    elementModeRender = <RenewByLicensePlate />;
  } else if (renewMode === RENEW_MODE.BY_USER) {
    elementModeRender = <RenewMember />;
  } else {
    elementModeRender = null;
  }

  return <>{elementModeRender}</>;
};

export default Renew;
