import React, { useMemo, useRef, useState } from "react";
// import { Box, Button, styled, Typography, useMediaQuery } from "@mui/material";
import InputTextFieldAOT from "../components/Controls/InputTextFieldAOT";
import BoxPageContent from "../components/BoxPageContent";
import { useMutation, useQuery } from "@tanstack/react-query";
import ParkingService from "../services/parking-service";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ConfigService from "../services/config-service";
import { useEffect } from "react";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { BrowserQRCodeReader } from "@zxing/library";
import Webcam from "react-webcam";
import { isMobile } from "react-device-detect";
import FindMyCarBoxInfomation from "../components/FindMyCar/FindMyCarBoxInfomation";
import ContainerCenter from "../layouts/ContainerCenter";
import ButtonAction from "../components/Base/ButtonAction";
import { toast } from "react-toastify";

const FindMyCarPage = () => {
  const { t } = useTranslation();
  const { pmlsId } = useSelector((state) => state.airport);
  const { currentPmls, memberVehicles } = useSelector((state) => state.auth);
  const [searchValue, setSearchValue] = useState({
    licenseplate: "",
    ticket: "",
  });
  const [mode, setMode] = useState(1);

  //qr code
  const webcamRef = useRef(null);
  const [showScanner, setShowScanner] = useState(false);
  const [facingMode, setFacingMode] = useState(
    isMobile ? "environment" : "user"
  );

  const handleSwitchCamera = () => {
    if (facingMode === "user") {
      setFacingMode("environment");
    } else {
      setFacingMode("user");
    }
  };

  const videoConstraints = {
    facingMode: facingMode,
  };

  useEffect(() => {
    const codeReader = new BrowserQRCodeReader();
    const scanQRCode = async () => {
      try {
        if (showScanner) {
          const videoElement = webcamRef.current.video;
          const result = await codeReader.decodeFromVideoElement(videoElement);

          // Handle the QR code result
          if (result?.text !== "") {
            setTimeout(() => {
              const data = result?.text;
              const sections = data.split("|");
              const desiredValue = sections[sections.length - 2];
              setSearchValue((prev) => {
                return { ...prev, ticket: desiredValue };
              });
              setShowScanner(false);
            }, 1000);
          }
        }
      } catch (error) {
        // Handle error
        console.error("QR code scan error:", error);
      }
    };

    scanQRCode();

    return () => {
      codeReader.reset();
    };
  }, [showScanner]);

  const handleScannerClose = () => {
    setShowScanner(false);
  };

  const handleIconQrCodeClick = () => {
    setShowScanner(true);
  };

  const TEXT_MODE_MAPPING = (mode) => {
    if (mode === 1) {
      return "สามารถค้นหาจากทะเบียนรถสมาชิกของท่าน หรือ ระบุทะเบียนรถ";
    } else if (mode === 2) {
      return "กรุณาระบุทะเบียนรถที่ต้องการค้นหา";
    } else if (mode === 3) {
      return "สามารถค้นหาได้จากทะเบียนรถสมาชิกของท่าน หรือ ระบุหมายเลขบัตรจอดรถหรือสแกนคิวอาร์โค้ด";
    } else if (mode === 4) {
      return "กรุณาระบุทะเบียนรถที่ต้องการค้นหา";
    }
  };

  const activeMemberVehicle = useMemo(() => {
    return memberVehicles?.find((item) => item.status === "Active");
  }, [memberVehicles]);

  const { data: configTicketLessData } = useQuery({
    queryKey: ["GET_CONFIG_TICKET_LESS_PMLS", pmlsId],
    queryFn: () =>
      ConfigService.getEntryExitGlobalConfigFormPMLS({
        pmlsid: pmlsId,
        conf: "ticketless",
        confgroup: "ticket",
      }),
    select: (data) => data?.data?.config[0]?.ticketless,
  });

  useEffect(() => {
    const isPmls = !!currentPmls;
    let mode = 1;
    if (configTicketLessData === "TRUE") {
      if (isPmls) {
        mode = 1;
      } else if (!isPmls || currentPmls?.status !== "Active") {
        mode = 2;
      }
    } else {
      if (isPmls) {
        mode = 3;
      } else if (!isPmls || currentPmls?.status !== "Active") {
        mode = 4;
      }
    }

    setMode(mode);
  }, [configTicketLessData, currentPmls]);

  const searchVehicleMutate = useMutation({
    mutationFn: (payload) => ParkingService.searchVehicle(payload),
    onError: () => {
      toast.error("มีบางอย่างผิดพลาด โปรดติดต่อเจ้าหน้าที่", {
        position: "top-center",
        autoClose: 2000,
      });
    },
    onSuccess: (res) => {
      if (res.data?.data?.serchVehicle?.length === 0) {
        toast.error("ไม่พบการค้นหา", {
          position: "top-center",
          autoClose: 1000,
        });
      } else if (res?.data?.ouIsComplete === 0) {
        toast.error(res?.data?.ouMessage, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    },
  });

  const onClickSearchVehicleByLicensePlate = () => {
    if (!activeMemberVehicle?.licenseplate) {
      return toast.error("ไม่พบทะเบียนรถสมาชิก", {
        position: "top-center",
        autoClose: 1000,
      });
    }
    const payload = {
      airportid: pmlsId,
      licenseplate: activeMemberVehicle?.licenseplate,
      ticket: "",
    };
    searchVehicleMutate.mutate(payload);
  };

  const onClickSearchVehicleByLicensePlateInput = () => {
    if (searchValue?.licenseplate === "") {
      return toast.error("กรุณากรอกทะเบียนรถ", {
        position: "top-center",
        autoClose: 1000,
      });
    }
    const payload = {
      airportid: pmlsId,
      licenseplate: searchValue?.licenseplate,
      ticket: "",
    };
    searchVehicleMutate.mutate(payload);
  };

  const onClickSearchVehicleByTicket = () => {
    if (searchValue.ticket === "") {
      return toast.error("กรุณากรอกรหัสบัตรจอดรถ", {
        position: "top-center",
        autoClose: 1000,
      });
    }
    const payload = {
      airportid: pmlsId,
      licenseplate: "",
      ticket: searchValue?.ticket,
    };
    searchVehicleMutate.mutate(payload);
  };

  //const responseData = searchVehicleMutate?.data?.serchVehicle ? searchVehicleMutate?.data?.serchVehicle[0] : ''
  const responseData = searchVehicleMutate?.data?.data?.data?.serchVehicle
    ? searchVehicleMutate?.data?.data?.data?.serchVehicle[0]
    : "";

  return (
    <>
      <div className="bg-[#E9F0F5]  pt-5 md:md:pt-[150px]">
        <ContainerCenter>
          <div className="pb-5">
            <span className="text-lg font-bold">ค้นหาที่จอดรถ - </span>
            <span>{TEXT_MODE_MAPPING(mode)}</span>
          </div>
          <div className="pb-5 w-full">
            {mode === 1 ? (
              <div className="w-full">
                <div className="flex flex-col md:flex-row items-center gap-5">
                  <div className="flex items-center gap-4">
                    <div className="text-center flex flex-col md:flex-row  gap-4 items-center justify-center">
                      <span className="font-bold  whitespace-nowrap line-clamp-1">
                        ทะเบียนรถสมาชิก :
                      </span>
                      <span className="whitespace-nowrap">
                        {activeMemberVehicle?.licenseplate}{" "}
                        {activeMemberVehicle?.licenseprovince}
                      </span>
                    </div>

                    <div className="">
                      <ButtonAction
                        onClick={onClickSearchVehicleByLicensePlate}
                      >
                        {t("ค้นหา")}
                      </ButtonAction>
                    </div>
                  </div>

                  <span className="font-bold ">หรือ</span>

                  <div className="w-full flex items-center gap-x-2">
                    <div className="w-[80%]">
                      <InputTextFieldAOT
                        label={"ทะเบียนรถ"}
                        name="licenseplate"
                        val={searchValue.licenseplate}
                        handleChange={(e) =>
                          setSearchValue({
                            ...searchValue,
                            licenseplate: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <ButtonAction
                        onClick={onClickSearchVehicleByLicensePlateInput}
                      >
                        {t("ค้นหา")}
                      </ButtonAction>
                    </div>
                  </div>
                </div>
              </div>
            ) : mode === 2 ? (
              <div>
                <div className="w-full  flex items-center gap-x-2">
                  <InputTextFieldAOT
                    label={"ทะเบียนรถ"}
                    sxa={{
                      width: "100%",
                    }}
                    name="ticket"
                    val={searchValue.licenseplate}
                    handleChange={(e) =>
                      setSearchValue({
                        ...searchValue,
                        licenseplate: e.target.value,
                      })
                    }
                  />
                  <div>
                    <ButtonAction
                      onClick={onClickSearchVehicleByLicensePlateInput}
                    >
                      {t("ค้นหา")}
                    </ButtonAction>
                  </div>
                </div>
              </div>
            ) : mode === 3 ? (
              <div className="w-full">
                <div className="flex flex-col md:flex-row items-center gap-5">
                  <div className="flex items-center gap-4">
                    <div className="text-center flex flex-col md:flex-row  gap-4 items-center justify-center whitespace-nowrap">
                      <span className="font-bold">ทะเบียนรถสมาชิก :</span>
                      <span>
                        {activeMemberVehicle?.licenseplate}{" "}
                        {activeMemberVehicle?.licenseprovince}
                      </span>
                    </div>
                    <ButtonAction onClick={onClickSearchVehicleByLicensePlate}>
                      {t("ค้นหา")}
                    </ButtonAction>
                  </div>
                  <div>
                    <span className="font-bold mr-4">หรือ</span>
                  </div>
                  <div className="w-full flex items-center gap-x-2">
                    <InputTextFieldAOT
                      label={"หมายเลขบัตรจอดรถ"}
                      sxa={{
                        width: "80%",
                      }}
                      name="ticket"
                      val={searchValue.ticket}
                      handleChange={(e) =>
                        setSearchValue({
                          ...searchValue,
                          ticket: e.target.value,
                        })
                      }
                      sxb={{
                        endAdornment: (
                          <QrCodeScannerIcon
                            onClick={handleIconQrCodeClick}
                            edge="end"
                            className="cursor-pointer"
                          />
                        ),
                      }}
                    />
                    <div className="w-11">
                      <ButtonAction
                        onClick={onClickSearchVehicleByTicket}
                        disabled={searchValue?.ticket === ""}
                      >
                        {t("ค้นหา")}
                      </ButtonAction>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full flex items-center gap-x-2 ">
                <div className="w-[90%]">
                  <InputTextFieldAOT
                    label={"หมายเลขบัตรจอดรถ"}
                    name="ticket"
                    val={searchValue.ticket}
                    handleChange={(e) =>
                      setSearchValue({
                        ...searchValue,
                        ticket: e.target.value,
                      })
                    }
                    sxb={{
                      endAdornment: (
                        <QrCodeScannerIcon
                          onClick={handleIconQrCodeClick}
                          edge="end"
                          className="cursor-pointer"
                        />
                      ),
                    }}
                  />
                </div>

                <div>
                  <ButtonAction onClick={onClickSearchVehicleByTicket}>
                    {t("ค้นหา")}
                  </ButtonAction>
                </div>
              </div>
            )}
          </div>
        </ContainerCenter>
      </div>

      <BoxPageContent
        headerBlack="รายละเอียดค้นหาที่จอดรถ"
        breadcrumbRoot={t("Home")}
        breadcrumbBranch="ค้นหาที่จอดรถ"
        align="flex-start"
      >
        <div className="w-full flex items-center">
          <FindMyCarBoxInfomation data={responseData} />
        </div>
      </BoxPageContent>
      {showScanner && (
        <div className="fixed top-0 left-0 z-[3000] flex justify-center items-center w-full h-full">
          <div>
            <Webcam
              audio={false}
              videoConstraints={videoConstraints}
              ref={webcamRef}
              className="mx-auto"
              style={{
                maxWidth: "100%",
                maxHeight: "calc(100vh - 4rem)", // Adjust as needed
                width: "auto",
                height: "auto",
              }}
            />
            <div className="flex flex-col mt-2 items-center gap-2">
              <button
                onClick={handleScannerClose}
                className="w-full bg-slate-400 font-bold text-white"
              >
                Close Camera
              </button>
              {isMobile && (
                <button
                  onClick={handleSwitchCamera}
                  className="w-full bg-slate-400 font-bold text-white"
                >
                  Switch Camera
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FindMyCarPage;
