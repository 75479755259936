import { Box, styled } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ContentService from "../services/content-service";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { formattedDateByLocale } from "../utils/format";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/LoadingSpinner";
import { CONTENT_TYPE_CODE } from "../constants";

const MenuButton = styled((props) => <Box component="img" {...props} />)(
  ({ theme }) => ({
    width: "100%",
    maxHeight: "250px",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      height: "200px",
    },
  })
);

const getTitleTranslationKeyByContentTypeCode = (code) => {
  let title = "";
  if (code === CONTENT_TYPE_CODE.INFO) {
    title = "Press Release";
  } else if (code === CONTENT_TYPE_CODE.PROMO) {
    title = "Campaign and Promotion";
  } else if (code === CONTENT_TYPE_CODE.NEWS) {
    title = "AOT News";
  }
  return title;
};

const ContentDetail = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const { pmlsId } = useSelector((state) => state.airport);

  const contentFullQuery = useQuery({
    queryKey: ["GET_CONTENT_DETAIL", params?.id, i18n?.language],
    queryFn: () => {
      const param = {
        pmlsid: pmlsId,
        contentid: params?.id,
        lang: i18n?.language,
      };
      return ContentService.getContentFull(param);
    },
    enabled: pmlsId !== "0" && params?.id !== undefined,
    select: (data) => data?.data?.TbContentHdr,
  });

  const contentFullData = contentFullQuery?.data;
  const contentDetailData = contentFullData?.detail;

  const mapData = contentDetailData?.map((item, index) => (
    <div key={index} className="flex flex-col items-center">
      <img
        src={`data:image/${item?.previewimage_data?.imageext};base64,${item?.previewimage_data?.data_base64}`}
        className="w-[70%] h-auto"
        alt="content"
      />
      <div className="w-[80%]">
        <div className="mt-6 text-left">
          <p className="text-lg font-semibold line-clamp-2">{item?.headline}</p>
        </div>
        <div className="bg-[#E9F0F5] h-14 w-full my-5 flex item-center px-4">
          <div className="flex items-center gap-4 text-sm font-normal text-[#707070]">
            <AccessTimeIcon htmlColor="#245AB1" />
            {formattedDateByLocale(contentFullData?.startpublishdt, "")} -{" "}
            {formattedDateByLocale(contentFullData?.endpublishdt, "")}
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: item.fullcontenttext }}
          className="w-full text-left"
        />
      </div>
    </div>
  ));

  return (
    <div className="w-full">
      <MenuButton src="/images/allnews/img_bgnews.png" />
      <div className="px-6 md:px-32 py-20 text-[#2F2E2F]">
        <div className="flex justify-between gap-3 items-center ">
          <span className="text-xl md:text-3xl font-bold">
            {t(
              getTitleTranslationKeyByContentTypeCode(
                contentFullData?.contenttypecode
              )
            )}
          </span>
          <span className="text-xs md:text-base">
            {t("Home")} / {t("News and Promotion")} /{" "}
            {t(
              getTitleTranslationKeyByContentTypeCode(
                contentFullData?.contenttypecode
              )
            )}{" "}
            /{" "}
            <span className="text-[#245AB1] font-semibold">{t("Detail")}</span>
          </span>
        </div>
        <div className="py-10">
          {contentFullQuery.isLoading ? <LoadingSpinner /> : mapData}
        </div>
      </div>
    </div>
  );
};

export default ContentDetail;
