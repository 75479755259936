import { Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InputTextFieldAOT } from "../Controls";

const ReceivePaymentConfirmCheckbox = ({
  emailIsValid,
  checkedSendEmail,
  sendEmailValue,
  setCheckedSendEmail,
  setSendEmailValue,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mt-6 flex items-center gap-2">
        <Checkbox
          checked={checkedSendEmail}
          onChange={(event) => {
            setCheckedSendEmail(event.target.checked);
          }}
          inputProps={{ "aria-label": "controlled" }}
          label="Label"
          sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
        />
        <span>{t("Receive payment confirmation via email")}</span>
      </div>
      {checkedSendEmail && (
        <div className="mb-4">
          <InputTextFieldAOT
            label={t("Enter your email address")}
            required
            handleChange={(e) => {
              setSendEmailValue(e.target.value);
            }}
            val={sendEmailValue}
          />
          {!emailIsValid && (
            <p className="text-red-500 text-xs h-6 mt-2">
              Email format is not valid
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default ReceivePaymentConfirmCheckbox;
