import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import DatePickerAOT from "../../Controls/DatePickerAOT";
import InputTextFieldAOT from "../../Controls/InputTextFieldAOT";
import { useNavigate } from "react-router-dom";
import { SelectAOT } from "../../Controls/index";
import { useMemo } from "react";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../constants";
import TablePackageDetail from "../../Base/TablePackageDetail";
import { useTranslation } from "react-i18next";
import ButtonAction from "../../Base/ButtonAction";
import { useSelector } from "react-redux";
import { generateVehicleTypeOptions } from "../../../utils/optionsUtils";

const BoxDataRenew = styled(Box)(() => ({
  width: "100%",
}));

const BoxInputData = styled(Box)(() => ({
  padding: "2rem 0",
}));

const TextTitleDataRenew = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 22px/45px Noto Sans Thai",
  letterSpacing: "0px",
  color: "#2F2E2F",
  [theme.breakpoints.down("lg")]: {
    font: "normal normal 600 18px/40px Noto Sans Thai",
  },
}));

const GridContainer = styled((props) => (
  <Grid container spacing={3} {...props} />
))(() => ({
  padding: "0",
}));

const GridItem = styled((props) => <Grid item lg={6} xs={12} {...props} />)(
  () => ({
    padding: "0",
  })
);

const GridItemFull = styled((props) => <Grid item xs={12} {...props} />)(
  () => ({
    padding: "0",
  })
);

const monthsAmount = [
  {
    id: "1",
    display: 1,
  },
  {
    id: "2",
    display: 2,
  },
  {
    id: "3",
    display: 3,
  },
];

const Form = ({
  titleName,
  data,
  memberVehicles,
  selectedVehicle,
  packageData,
  dateDuration,
  setDataDuration,
  onChangeVehicle,
  getTotalPackagePrice,
  disabled,
  numberOfMonths,
  setNumberOfMonths,
  onClickUpdateButtonHandler,
}) => {
  const navigate = useNavigate();
  const { vehicletype } = useSelector((state) => state.masterData);
  const { t } = useTranslation();

  const msVehicleTypeOptions = useMemo(() => {
    let array = [];
    const masterVehicle = generateVehicleTypeOptions(vehicletype);
    if (masterVehicle?.length > 0 && memberVehicles?.length > 0) {
      array = masterVehicle.filter((item) =>
        memberVehicles?.find(
          (memberVehicle) => memberVehicle?.vehicletypeid === item?.id
        )
      );
    }

    return array;
  }, [vehicletype, memberVehicles]);

  return (
    <>
      <BoxDataRenew>
        <Box sx={{ display: "flex" }}>
          <TextTitleDataRenew>
            {t("Renew Membership Information")}
          </TextTitleDataRenew>
        </Box>
        <BoxInputData>
          <GridContainer>
            <GridItem>
              <InputTextFieldAOT
                label={t("Member Type")}
                name="membertype"
                val="Member"
                disabled
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Member Number")}
                name="xxxx"
                val={data?.memberno || ""}
                disabled
              />
            </GridItem>

            <GridItem>
              <InputTextFieldAOT
                label={t("Title")}
                name="title"
                val={titleName || ""}
                disabled
              />
            </GridItem>

            <GridItem>
              <InputTextFieldAOT
                label={t("Name")}
                name="firstname"
                val={data?.fname || ""}
                disabled
              />
            </GridItem>

            <GridItem>
              <InputTextFieldAOT
                label={t("Surname")}
                name="lastname"
                val={data?.lname || ""}
                disabled
              />
            </GridItem>

            <GridItem>
              <SelectAOT
                label={t("Vehicle Type")}
                options={msVehicleTypeOptions}
                fullWidth
                value={selectedVehicle?.vehicletypeid}
                onChange={(e) => {
                  onChangeVehicle(e.target.value);
                }}
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("License Plate")}
                name="xxxx"
                disabled
                val={selectedVehicle?.licenseplate || ""}
              />
            </GridItem>
            <GridItem>
              <InputTextFieldAOT
                label={t("Province")}
                name="province"
                val={selectedVehicle?.licenseprovince}
                disabled
              />
            </GridItem>
            <GridItem>
              <DatePickerAOT
                label={t("Expired Date")}
                name="xxxx"
                val={data?.memberpackage?.enddate}
                disabled
              />
            </GridItem>
            <GridItemFull>
              <InputTextFieldAOT
                label={t("Member Package")}
                name="xxxx"
                disabled
                val={packageData?.name || ""}
              />
            </GridItemFull>
          </GridContainer>
        </BoxInputData>
        <Box sx={{ paddingBottom: "3.176rem" }}>
          <TextTitleDataRenew>{t("Renew Duration")}</TextTitleDataRenew>
          <GridContainer>
            <GridItem>
              <DatePickerAOT
                required={true}
                label={t("Apply Start Date")}
                handleChange={(e) => {
                  var currentMonth = dayjs().startOf("month");
                  var selectedMonth = dayjs(e).startOf("month");
                  if (
                    selectedMonth.isBefore(currentMonth, "month") ||
                    selectedMonth.isSame(currentMonth, "month")
                  ) {
                    alert(
                      "Please select a month that is not the current month or earlier."
                    );
                    return;
                  }
                  const startOfMonth = dayjs(e)
                    .startOf("month")
                    .format(DATE_FORMAT);
                  const endDateWithMonthDuration = dayjs(startOfMonth)
                    .add(Number(numberOfMonths - 1), "month")
                    .endOf("month")
                    .format(DATE_FORMAT);
                  setDataDuration({
                    endDate: endDateWithMonthDuration,
                    startDate: startOfMonth,
                  });
                }}
                val={dateDuration?.startDate}
              />
            </GridItem>
            <GridItem>
              <SelectAOT
                label={t("Month Amount")}
                options={monthsAmount}
                fullWidth
                value={numberOfMonths}
                onChange={(e) => {
                  const _numberOfMonth = Number(e.target.value);
                  setNumberOfMonths(_numberOfMonth);
                  let endDateWithMonthDuration = null;
                  if (_numberOfMonth === 1) {
                    endDateWithMonthDuration = dayjs(dateDuration?.startDate)
                      .endOf("month")
                      .format(DATE_FORMAT);
                  } else {
                    endDateWithMonthDuration = dayjs(dateDuration?.startDate)
                      .add(Number(_numberOfMonth - 1), "month")
                      .endOf("month")
                      .format(DATE_FORMAT);
                  }
                  setDataDuration({
                    ...dateDuration,
                    endDate: endDateWithMonthDuration,
                  });
                }}
              />
            </GridItem>
            <GridItem>
              <DatePickerAOT
                label={t("Expired Date")}
                handleChange={() => {}}
                val={dateDuration?.endDate}
                disabled
              />
            </GridItem>
          </GridContainer>
        </Box>
        <TablePackageDetail
          isRenew
          data={packageData?.items?.filter((item) => item.chargetype === "002")}
          totalPrice={getTotalPackagePrice()}
        />
      </BoxDataRenew>
      <div className="w-full flex flex-col md:flex-row gap-2 mt-10">
        <ButtonAction
          onClick={() => {
            navigate("/home");
          }}
          variant="secondary"
        >
          {t("Cancel")}
        </ButtonAction>
        <ButtonAction onClick={onClickUpdateButtonHandler} disabled={disabled}>
          {t("Next")}
        </ButtonAction>
      </div>
    </>
  );
};

export default Form;
